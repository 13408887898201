<template>
  <div class="participants-full">
    <div class="participants-full__list">
      <ParticipantItem
        v-for="(item, index) in formattedParticipantsList"
        :key="item.suid"
        :name="item.name"
        :type="item.type"
        :uuid="item.uuid"
        :suid="item.suid"
        :active="item.talking"
        :host="item.host"
        :muted="item.muted"
        :seporatorLineClass="getSeporatorLine(index)"
      />
    </div>
  </div>
</template>

<script>
import ParticipantItem from '../participant-item/ParticipantItem'

export default {
  name: 'ParticipantsFull',
  data () {
    return {}
  },
  props: ['sessionParticipants'],
  components: {
    ParticipantItem
  },
  computed: {
    formattedParticipantsList: function () {
      const formattedList = this.sessionParticipants.map((b, idx) => Object.assign({ index: idx }, b))

      formattedList.sort((a, b) => {
        if (a.type !== b.type) {
          return a.type === 'CAM_UNIT' || a.type === 'CAM_MOBILE' ? -1 : 1
        }
        if (a.host !== b.host) {
          return a.host ? -1 : 1
        }
        return 1
      })

      return formattedList
    }
  },
  methods: {
    getSeporatorLine (index) {
      return this.formattedParticipantsList.length &&
        (this.formattedParticipantsList[index].type === 'CAM_UNIT' ||
          this.formattedParticipantsList[index].type === 'CAM_MOBILE'
        ) && this.formattedParticipantsList[index + 1]?.type === 'VEST_CLIENT'
        ? 'last-tech'
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.participants-full {
  width: 320px;
  background: #ffffff;
  box-shadow: 0px 16px 24px 0px rgba(0,0,0,0.14);
  position: absolute;
  z-index: 22;
  padding: 0 0 16px 0;
  margin-top: 22px;
  transform: translateX(-50%);
  left: 50%;
}
.last-tech {
  border-bottom: 1px solid rgba(17,17,17,.1);
}
</style>
