<template>
  <div class="home-page">
    <template v-if="loggedIn">
      <HeaderView headerType="vest"/>
      <UnitsTable v-if="units"/>
      <div v-else class="notification">There are no units in organization</div>
      <ModalRule v-if="!acceptedRule"/>
    </template>
    <Loading v-else/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import UnitsTable from '@/components/units-table/UnitsTable'
import HeaderView from '@/components/header-view/HeaderView'
import Loading from '@/common/loading/Loading'
import ModalRule from '@/components/modal-rule/ModalRule'
import { clearToast } from '@/helpers/toastification'

export default {
  name: 'HomePage',
  components: {
    Loading,
    UnitsTable,
    HeaderView,
    ModalRule
  },
  computed: {
    ...mapGetters([
      'units',
      'loggedIn',
      'acceptedRule',
      'topics',
      'connectedStatus',
      'currentUnit',
      'defaultTitle',
      'user'
    ]),
    sessionStatus () {
      return this.currentUnit && this.currentUnit.session && this.currentUnit.session.status
    }
  },
  methods: {
    ...mapActions([
      'subscribeToTopic',
      'getUnits',
      'deleteSessionData',
      'disconnectFromSocket'
    ]),
    ...mapMutations(['setUnits'])
  },
  mounted () {
    const that = this
    if (this.connectedStatus) {
      this.subscribeToTopic(this.topics.camUnit)
      this.subscribeToTopic(this.topics.mobile)
      document.title = this.defaultTitle
    }
    // This event fires twice. Chrome bug. because of it we need to manage disconnectFromSocket
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden && (!that.sessionStatus || that.sessionStatus === 'NO_SESSION')) {
        clearToast()
        this.disconnectFromSocket()
          .then(() => {
            this.setUnits([])
            this.getUnits(this.user.user_metadata.organization_id)
          })
          .catch(console.warn)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .notification {
    font-size: 26px;
    color: #b71c1c;
  }
  .home-page {
    height: 100%;
  }
</style>
