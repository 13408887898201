const ERRORS = {
  1: 'Unable to connect. Please check your internet connection.',
  2: 'Problem with getting information about device. Please contact Support team.',
  3: 'Authentication error occurred. Please check your internet connection. If your connection is stable, then contact Support team.',
  4: 'Signal from AXIS camera is lost. You may ask field technician to check Ethernet cable connection.',
  5: 'Your microphone is not working.',
  6: 'Unable to connect to media server. Please check your internet connection. If your connection is stable, then contact Support team. ',
  7: 'Video streaming error occurred. Please check your internet connection. If your connection is stable, then contact Support team.',
  8: 'Media server error. Trying to reconnect… ',
  9: 'Problem with session initialization. Please check your internet connection. If your connection is stable, then contact Support team. ',
  10: 'Connection to session initialization service is lost. Reconnecting... ',
  11: 'An unexpected error occurred. Please contact Support team.'
}

export default ERRORS
