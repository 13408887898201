import Vue from 'vue'

export default {
  namespaced: false,
  state: {
    organizations: [],
    isSearching: false,
    searchingPlaceHolder: 'No organizations found',
    recentOrganizations: [],
    selectedOrganization: {},
    currentOrganization: {}
  },
  getters: {
    organizations (state) {
      return state.organizations
    },
    recentOrganizations (state) {
      return state.recentOrganizations
    },
    isSearching (state) {
      return state.isSearching
    },
    searchingPlaceHolder (state) {
      return state.searchingPlaceHolder
    },
    selectedOrganization (state) {
      return state.selectedOrganization
    },
    currentOrganization (state) {
      return state.currentOrganization
    }
  },
  mutations: {
    setOrganizations (state, organizations) {
      state.organizations = organizations
    },
    setRecentOrganizations (state, organizations) {
      state.recentOrganizations = organizations
    },
    setRecentOrganization (state, newOrganization) {
      const isnotUnique = state.recentOrganizations.some((org) => org.guid === newOrganization.guid)
      if (!isnotUnique) {
        state.recentOrganizations.push(newOrganization)
        while (state.recentOrganizations.length > 5) state.recentOrganizations.shift()
      }
    },
    setselectedOrganization (state, organizationId) {
      let selected = state.organizations.find((item) => item.guid === organizationId)
      if (!selected) selected = state.currentOrganization
      state.selectedOrganization = selected
    },
    setSearchingPlaceHolder (state, payload) {
      state.searchingPlaceHolder = payload
    },
    setCurrentOrganization (state, organizationId) {
      state.currentOrganization = state.organizations.find((item) => item.guid === organizationId)
    },
    setIsSearching (state, payload) {
      state.isSearching = payload
    },
    setCurrentOrganizationDetails (state, item) {
      state.organizations.push(item)
    }
  },
  actions: {
    setselectedOrganization ({ commit, state }, organizationId) {
      commit('setselectedOrganization', organizationId)
    },
    async getOrganizationsList ({ commit, state, dispatch, rootState }) {
      const getRecentOrganizations = (recent) => {
        return recent.map(async (guit) => {
          try {
            const org = await Vue.prototype.$axios.get(`${window.VEST_CONFIG.switchAPI}/identity/auth/v1/organizations/${guit}`)
            return org.data
          } catch (err) {
            console.log(err)
          }
        })
      }
      const resentList = localStorage.getItem('recent')
      if (resentList !== null) {
        const recent = JSON.parse(resentList)
        const organizationsList = await Promise.all(getRecentOrganizations(recent))
        commit('setOrganizations', organizationsList)
        commit('setRecentOrganizations', organizationsList)
        if (!organizationsList.some((i) => i.guid === rootState.User.user.user_metadata.organization_id)) {
          await dispatch('getCurrentOrganizationDetails')
        }
      } else {
        await dispatch('getCurrentOrganizationDetails')
      }
      commit('setselectedOrganization', rootState.User.user.user_metadata.organization_id)
      commit('setCurrentOrganization', rootState.User.user.user_metadata.organization_id)
      dispatch('setRecentOrganizationLocal')
      commit('setRecentOrganizations', state.organizations)
    },
    searchOrganization ({ commit, state, dispatch }, name) {
      commit('setIsSearching', true)
      commit('setSearchingPlaceHolder', 'Searching...')
      Vue.prototype.$axios.get(`${window.VEST_CONFIG.switchAPI}/identity/auth/v1/organizations?name=${name}`)
        .then((data) => {
          if (data.data.totalItems) {
            const results = data.data._embedded.item
            commit('setOrganizations', results)
          }
        }).then(() => {
          if (name === '') {
            commit('setOrganizations', state.recentOrganizations)
            commit('setIsSearching', false)
          }
          commit('setSearchingPlaceHolder', 'No organizations found')
        }).catch(err => {
          commit('setIsSearching', false)
          commit('setSearchingPlaceHolder', 'No organizations found')
          console.log(err)
        })
    },
    setIsSearching ({ commit }, payload) {
      commit('setIsSearching', payload)
    },
    setRecentOrganizationLocal ({ state }) {
      const resentList = localStorage.getItem('recent')
      if (resentList !== null) {
        let recent = JSON.parse(resentList)
        recent.push(state.selectedOrganization.guid)
        const uniqGuid = new Set(recent)
        recent = [...uniqGuid]
        while (recent.length > 5) {
          recent.shift()
        }
        localStorage.setItem('recent', JSON.stringify(recent))
      } else {
        const recent = [state.selectedOrganization.guid]
        localStorage.setItem('recent', JSON.stringify(recent))
      }
    },
    getCurrentOrganizationDetails ({ commit, state, rootState }) {
      return Vue.prototype.$axios.get(`${window.VEST_CONFIG.switchAPI}/identity/auth/v1/organizations/${rootState.User.user.user_metadata.organization_id}`)
        .then((data) => {
          commit('setCurrentOrganizationDetails', data.data)
        })
    },
    changeOrganization ({ commit, state, dispatch }, guid) {
      dispatch('disconnectFromSocket')
      dispatch('getUnits', guid)
      dispatch('setRecentOrganizationLocal')
      commit('setRecentOrganization', state.selectedOrganization)
      commit('setOrganizations', state.recentOrganizations)
      commit('setCurrentOrganization', state.selectedOrganization.guid)
    }
  }
}
