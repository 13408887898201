<template>
  <div :class="listClasses">
    <ParticipantIcon
      :name="name"
      :type="type"
      :active="active"
      :red="host"
    />
    <div class="participant-item__info">
      <div class="participant-item__name">{{formattedName}}</div>
      <div class="participant-item__label" v-if="host">HOST</div>
    </div>
    <div v-if="muted !== undefined" class="participant-item__control">
      <span class="participant-item__mute">
        <Icon v-tooltip="`mute participant`" v-if="!muted" class="icon--unmute" :handleClick="changeMute">
          <svg viewBox="0 0 14 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0C7.79565 0 8.55871 0.316071 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3V9C10 9.79565 9.68393 10.5587 9.12132 11.1213C8.55871 11.6839 7.79565 12 7 12C6.20435 12 5.44129 11.6839 4.87868 11.1213C4.31607 10.5587 4 9.79565 4 9V3C4 2.20435 4.31607 1.44129 4.87868 0.87868C5.44129 0.316071 6.20435 0 7 0ZM14 9C14 12.53 11.39 15.44 8 15.93V19H6V15.93C2.61 15.44 0 12.53 0 9H2C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9H14Z"/>
          </svg>
        </Icon>
        <Icon v-tooltip="`unmute participant`" v-else class="icon--mute" :handleClick="changeMute">
          <svg viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9C16 10.19 15.66 11.3 15.1 12.28L13.87 11.05C14.14 10.43 14.3 9.74 14.3 9H16ZM12 9.16L6 3.18V3C6 2.20435 6.31607 1.44129 6.87868 0.87868C7.44129 0.316071 8.20435 0 9 0C9.79565 0 10.5587 0.316071 11.1213 0.87868C11.6839 1.44129 12 2.20435 12 3V9.16ZM1.27 1L18 17.73L16.73 19L12.54 14.81C11.77 15.27 10.91 15.58 10 15.72V19H8V15.72C4.72 15.23 2 12.41 2 9H3.7C3.7 12 6.24 14.1 9 14.1C9.81 14.1 10.6 13.91 11.31 13.58L9.65 11.92L9 12C8.20435 12 7.44129 11.6839 6.87868 11.1213C6.31607 10.5587 6 9.79565 6 9V8.28L0 2.27L1.27 1Z"/>
          </svg>
        </Icon>
      </span>
    </div>
    <div v-if="microphoneAvailability" class="participant-item__control">
      <div class="participant-item__mute">
        <Icon v-tooltip="`Your microphone is not working`" class="icon--no-microphone">
          <svg viewBox="0 0 14 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0C7.79565 0 8.55871 0.316071 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3V9C10 9.79565 9.68393 10.5587 9.12132 11.1213C8.55871 11.6839 7.79565 12 7 12C6.20435 12 5.44129 11.6839 4.87868 11.1213C4.31607 10.5587 4 9.79565 4 9V3C4 2.20435 4.31607 1.44129 4.87868 0.87868C5.44129 0.316071 6.20435 0 7 0ZM14 9C14 12.53 11.39 15.44 8 15.93V19H6V15.93C2.61 15.44 0 12.53 0 9H2C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9H14Z"/>
          </svg>
        </Icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Icon from '@/common/icon/Icon'
import ParticipantIcon from '../participant-icon/ParticipantIcon'

export default {
  name: 'ParticipantItem',
  props: ['name', 'type', 'uuid', 'suid', 'active', 'host', 'muted', 'seporatorLineClass'],
  components: {
    ParticipantIcon,
    Icon
  },
  data () {
    return {
      unavailableMicrophone: false
    }
  },
  computed: {
    listClasses: function () {
      return [{
        'participant-item': true,
        'participant-item--big': this.type === 'CAM_UNIT' || this.type === 'CAM_MOBILE'
      }, this.seporatorLineClass]
    },
    formattedName: function () {
      switch (this.type) {
        case 'CAM_MOBILE':
          return 'Field technician Mobile'
        case 'VEST_CLIENT':
          return this.name
        default:
          return 'Field technician'
      }
    },
    microphoneAvailability () {
      return this.unavailableMicrophone && this.muted === undefined
    }
  },
  methods: {
    ...mapActions([
      'sendMute'
    ]),
    changeMute () {
      this.sendMute({ userId: this.uuid, suid: this.suid, mute: !this.muted, recipient: this.type })
    },
    getMicrophonePermissions () {
      return navigator.permissions.query({ name: 'microphone' })
        .then(result => {
          if (result.state === 'denied') {
            this.unavailableMicrophone = true
          }
        })
    }
  },
  mounted () {
    this.getMicrophonePermissions()
  }
}
</script>

<style lang="scss" scoped>
  .participant-item {
    padding: 15px 16px 0;
    display: flex;
    cursor: default;

    .participant-icon {
      margin: 0;
    }

    &__info {
      display: flex;
      margin-left: 16px;
      padding-right: 15px;
      justify-content: center;
      flex-direction: column;
      flex: 1 1 100%;
    }

    &__name {
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
    }

    &__label {
      text-transform: uppercase;
      color: #D31920;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1px;
    }

    &__control {
      align-self: center;
    }
    &__mute,
    &__unmute {
      cursor: pointer;
    }
    &__mute {
      margin-right: 5px;
      display: block;
    }

    &--big {
      padding: 20px 16px 16px;
    }
  }
</style>
