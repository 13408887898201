<template>
  <div class="unit">
    <HeaderVestUnit @clickAction="saveUnit" headerType="Edit unit"/>
    <div class="unit__form">
      <div class="unit__itm unit__itm--inputs">
        <Field v-for="u in unitFields" v-model.trim="u.value" @change="addNameForDevices" @blur="checkValid(u)"
         :key="u.id"
         :value="u.value"
         :label="u.label"
         :placeholder="u.placeholder"
         :isRequired="u.isRequired"
         :pattern="u.pattern"
         :isDisabled="u.isDisabled"
         :type="u.type"
         :styleTextBold="u.styleTextBold"
         :textarea="u.textarea"
         :valid="u.valid"
        />
        <HelpSection @helpSectionClicked="handleHelpPopup"/>
      </div>
      <div class="unit__itm unit__itm--device">
        <DeviceItem
          @addDevice="addDevice('SmartCart')"
          @deleteDevice="deleteDevice('SmartCart')"
          :name="`SmartCart`"
          :fields="smartCartFields"
          :hasDevice="hasSmartCart"
          @blur="checkValid"
        />
        <DeviceItem
          @addDevice="addDevice('Mobile')"
          @deleteDevice="deleteDevice('Mobile')"
          :name="`Mobile`"
          :fields="mobileFields"
          :hasDevice="hasMobile"
          @blur="checkValid"
        />
        <Warning v-if="showValidationMsg" @close="closeValidationMsg" text="You need to add at least 1 device"/>
      </div>
    </div>
    <addUnitHelpPopup v-if="showHelpPopup" @handleHelpPopup="handleHelpPopup"/>
  </div>
</template>

<script>
import Field from '@/common/field/Field'
import Warning from '@/common/warning/Warning'
import DeviceItem from '@/components/device-item/DeviceItem'
import HeaderVestUnit from '@/components/header-vest-unit/HeaderVestUnit'
import HelpSection from '@/components/help-section/HelpSection'
import addUnitHelpPopup from '@/components/add-unit-help-popup/addUnitHelpPopup'
import { createUnitFields, createSmartCartFields, createMobileFields } from '@/helpers/createUnitFields'
import { createContainer, createDevice } from '@/helpers/createNewUnitStructure'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditVestUnit',
  data () {
    return {
      unitFields: null,
      smartCartFields: null,
      mobileFields: null,
      devices: [],
      showValidationMsg: false,
      deletedDevices: [],
      showHelpPopup: false
    }
  },
  components: {
    Field,
    DeviceItem,
    Warning,
    HeaderVestUnit,
    HelpSection,
    addUnitHelpPopup
  },
  mounted () {
    this.unitFields = createUnitFields()
    this.organization.value = this.selectedOrganization.name

    this.unitName.value = this.currentEditUnit.name
    this.location.value = this.currentEditUnit.meta.location
    this.unitFields.find(u => u.field === 'description').value = this.currentEditUnit.description

    if (this.hasMobile) {
      this.addDevice('Mobile')
      this.mobileFields.find(u => u.field === 'id').value = this.hasMobile.id
      this.mobileFields.find(u => u.field === 'description').value = this.hasMobile.description
    }
    if (this.hasSmartCart) {
      this.addDevice('SmartCart')
      const smartCartId = this.smartCartFields.find(u => u.field === 'id')
      smartCartId.isDisabled = true
      smartCartId.isDisabledClass = true
      smartCartId.value = this.hasSmartCart.id
      this.smartCartFields.find(u => u.field === 'description').value = this.hasSmartCart.description
    }
  },
  destroyed () {
    this.setEditUnitPage(false)
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'currentEditUnit'
    ]),
    unitName () {
      return this.unitFields ? this.unitFields.find(u => u.field === 'name') : null
    },
    location () {
      return this.unitFields ? this.unitFields.find(u => u.field === 'location') : null
    },
    organization () {
      return this.unitFields ? this.unitFields.find(u => u.field === 'organization') : null
    },
    isSmartCart () {
      return this.devices.includes('SmartCart')
    },
    isMobile () {
      return this.devices.includes('Mobile')
    },
    hasSmartCart () {
      return this.currentEditUnit?.devices?.find(device => device.type === 'CAM_UNIT')
    },
    hasMobile () {
      return this.currentEditUnit?.devices?.find(device => device.type === 'CAM_MOBILE')
    }
  },
  methods: {
    ...mapActions([
      'updateUnitContainer',
      'deleteDeviceFromUnit',
      'setEditUnitPage',
      'createDeviceInUnit',
      'updateDeviceInUnit'
    ]),
    addNameForDevices () {
      const smartCartName = this.smartCartFields?.find(u => u.field === 'name')
      const mobileName = this.mobileFields?.find(u => u.field === 'name')

      if (this.unitName?.value?.length) {
        if (smartCartName) {
          smartCartName.value = `${this.unitName.value} - SmartCart`
        }
        if (mobileName) {
          mobileName.value = `${this.unitName.value} - Mobile`
        }
      }
    },
    closeValidationMsg () {
      this.showValidationMsg = false
    },
    addDevice (device) {
      if (this.devices.includes(device)) return
      this.devices.push(device)
      if (device === 'SmartCart') this.smartCartFields = createSmartCartFields()
      if (device === 'Mobile') this.mobileFields = createMobileFields()
      this.addNameForDevices()
    },
    deleteDevice (device) {
      this.devices.splice(this.devices.indexOf(device), 1)

      if (device === 'SmartCart') this.smartCartFields = null
      if (device === 'Mobile') this.mobileFields = null

      if (this.deletedDevices.includes(device)) return
      if (device === 'SmartCart' && this.hasSmartCart) this.deletedDevices.push(device)
      if (device === 'Mobile' && this.hasMobile) this.deletedDevices.push(device)
    },
    checkValidBySave () {
      const error = []

      const validation = (fields) => {
        fields.forEach((item, index) => {
          if (item.isRequired && !item.isDisabled) {
            if (item.value?.length && item.pattern.test(item.value)) {
              item.valid = true
              if (error.includes(item.field)) {
                error.splice(index, 1)
              }
            } else {
              item.valid = false
              error.push(index)
            }
          }
        })
      }

      validation(this.unitFields)

      if (this.isSmartCart) {
        validation(this.smartCartFields)
      }

      if (!this.devices.length) {
        this.showValidationMsg = true
      }

      return error.length || !this.devices.length
    },
    checkValid (field) {
      field.value?.length
        ? field.valid = true
        : field.valid = false
    },
    saveUnit () {
      if (this.checkValidBySave()) return

      const containerId = this.currentEditUnit.id
      let mobile = null
      let smartCart = null

      const createSmartCart = () => {
        smartCart = createDevice({
          id: this.smartCartFields.find(u => u.field === 'id').value,
          containerId: containerId,
          type: 'CAM_UNIT',
          name: this.unitName.value,
          description: this.smartCartFields.find(u => u.field === 'description').value,
          location: this.location.value
        })
        this.createDeviceInUnit([smartCart])
      }

      const createMobile = () => {
        mobile = createDevice({
          id: this.mobileFields.find(u => u.field === 'id').value,
          containerId: containerId,
          type: 'CAM_MOBILE',
          name: this.unitName.value,
          description: this.mobileFields.find(u => u.field === 'description').value,
          location: this.location.value
        })
        this.createDeviceInUnit([mobile])
      }

      const container = createContainer({
        name: this.unitName.value,
        description: this.unitFields.find(u => u.field === 'description').value,
        location: this.location.value
      })

      if (this.deletedDevices.includes('SmartCart')) {
        this.deleteDeviceFromUnit(this.hasSmartCart?.id)
        if (this.isSmartCart) createSmartCart()
      }

      if (this.deletedDevices.includes('Mobile')) {
        this.deleteDeviceFromUnit(this.hasMobile?.id)
        if (this.isMobile) createMobile()
      }

      if (this.isSmartCart && !this.hasSmartCart) createSmartCart()
      if (this.isMobile && !this.hasMobile) createMobile()

      if (!this.deletedDevices.length) {
        if (this.isMobile && this.hasMobile) {
          mobile = createDevice({
            name: this.unitName.value,
            description: this.mobileFields.find(u => u.field === 'description').value,
            location: this.location.value
          })
          const mobileId = this.mobileFields.find(u => u.field === 'id').value
          this.updateDeviceInUnit({ deviceID: mobileId, device: mobile })
        }

        if (this.isSmartCart && this.hasSmartCart) {
          smartCart = createDevice({
            name: this.unitName.value,
            description: this.smartCartFields.find(u => u.field === 'description').value,
            location: this.location.value
          })
          const smartCartId = this.smartCartFields.find(u => u.field === 'id').value
          this.updateDeviceInUnit({ deviceID: smartCartId, device: smartCart })
        }
      }

      this.updateUnitContainer({ containerId, container })
      this.$router.push({ name: 'DeviceInventory' })
    },
    handleHelpPopup () {
      this.showHelpPopup = !this.showHelpPopup
    }
  },
  watch: {
    selectedOrganization: {
      handler (val) {
        if (!val.name) {
          this.$router.push({ name: 'DeviceInventory' })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .unit {
    &__form {
      padding-left: 125px;
      display: flex;
      margin: 0 -20px;
      justify-content: space-between;
      max-width: 1025px;
    }
    &__itm {
      padding: 0 20px;
    }
    &__itm--inputs {
      min-width: 350px;
    }
    &__itm--device {
      min-width: 450px;
    }
  }
</style>
