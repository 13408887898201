<template>
  <div class="control">
    <a @click="sendReset()" class="control__reset">
      <Icon class="icon--reset">
        <svg viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.39 18 13.68 17.06 15.4 15.4L13.9 13.9C13.2702 14.5664 12.5104 15.0966 11.6676 15.4578C10.8248 15.819 9.91693 16.0036 9 16C2.76 16 -0.36 8.46 4.05 4.05C8.46 -0.36 16 2.77 16 9H13L17 13H17.1L21 9H18C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9Z"/>
        </svg>
      </Icon>
      <span class="control__text">Reset</span>
    </a>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'
import { mapActions } from 'vuex'

export default {
  name: 'ResetBtn',
  props: ['currentUnit', 'device', 'type'],
  components: {
    Icon
  },
  methods: {
    ...mapActions([
      'sendPTZCommand'
    ]),
    sendReset () {
      this.sendPTZCommand({ currentContainerId: this.currentUnit.id, currentUnitId: this.device.id, destination: 'unit', command: 'HOME', type: this.type })
    }
  }
}
</script>

<style lang="scss" scoped></style>
