<template>
  <transition name="fade">
    <div>
      <div class="popup popup--details">
        <div class="popup__outer">
          <div class="popup__itm">
            <a href="#" class="popup__close" @click.prevent="close">
              <Icon class="icon--close">
                <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z"/>
                </svg>
              </Icon>
            </a>
            <p class="popup__title">{{ unit.name }}</p>
            <p class="popup__location">{{ unit.meta.location }}</p>
            <div class="popup__devices">
              <div class="popup__devices--col">
                <div class="popup__info">
                  <TelemetryIcon name="Cam Unit" :status="camUnit && camUnit.status ? camUnit.status : `OFFLINE`" :hasBatteryIcon="true">
                    <svg viewBox="0 0 18 11" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M.667 2a2 2 0 012-2h12.666a2 2 0 012 2v6a2 2 0 01-1.666 1.972v.861h-2.5V10H4.833v.833h-2.5v-.86A2 2 0 01.667 8V2zm1.666.5h13.334v5H2.333v-5zM6.5 5H4v.833h2.5V5zm1.667 0h2.5v.833h-2.5V5zM14 4.167h-1.667v1.666H14V4.167z"/>
                    </svg>
                  </TelemetryIcon>
                </div>
                <div class="popup__info">
                  <span :class="!camUnit || !camUnit.status ? 'popup__unit--off' : camUnit.status === 'OFFLINE' ? 'popup__unit--off' : null" class="popup__unit">{{ !camUnit || !camUnit.status ? 'OFF' : camUnit.status === 'OFFLINE' ? 'OFF' : 'ON' }}</span>
                </div>
              </div>
              <div class="popup__devices--col">
                <div class="popup__info">
                  <TelemetryIcon name="Camera" :status="camUnit && camUnit.telemetry && camUnit.telemetry.camera ? camUnit.telemetry.camera.status : `DISCONNECTED`" :hasBatteryIcon="true">
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 1.667a5.833 5.833 0 110 11.666 5.833 5.833 0 010-11.666zm0 1.666a4.167 4.167 0 100 8.334 4.167 4.167 0 000-8.334zM10 5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zM5 18.333a1.667 1.667 0 01-1.667-1.666c0-.317.084-.609.242-.859l1.517-2.633A7.506 7.506 0 0010 15a7.506 7.506 0 004.908-1.825l1.517 2.633c.158.25.242.542.242.859A1.667 1.667 0 0115 18.333H5z"/>
                    </svg>
                  </TelemetryIcon>
                </div>
                <div class="popup__info">
                  <span :class="(camUnit && camUnit.telemetry && camUnit.telemetry.camera && camUnit.telemetry.camera.status !== 'CONNECTED') || (!camUnit || !camUnit.telemetry) ? 'popup__unit--off' : null" class="popup__unit">{{ camUnit && camUnit.telemetry && camUnit.telemetry.camera && camUnit.telemetry.camera.status === 'CONNECTED' ? 'ON' : 'OFF' }}</span>
                </div>
              </div>
              <div class="popup__devices--col">
                <div class="popup__info">
                  <TelemetryIcon name="Mobile" :status="camMobile && camMobile.status ? camMobile.status : `OFFLINE`" :hasBatteryIcon="true">
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.167 15.833H5.833V4.167h8.334v11.666zm0-15H5.833A1.66 1.66 0 004.167 2.5v15a1.667 1.667 0 001.666 1.667h8.334a1.666 1.666 0 001.666-1.667v-15A1.667 1.667 0 0014.167.833z"/>
                    </svg>
                  </TelemetryIcon>
                </div>
                <div class="popup__info">
                  <Battery v-if="camMobile && camMobile.telemetry && (camMobile.status === 'ONLINE' || camMobile.status === 'IN_SESSION')" :status="camMobile.status" :battery="camMobile.telemetry.batteryLvl" :showBatteryLevel="true"/>
                  <span v-else class="popup__unit popup__unit--off">OFF</span>
                </div>
              </div>
              <div class="popup__devices--col">
                <div class="popup__info">
                  <TelemetryIcon name="Gimbal" :status="camMobile && camMobile.telemetry && camMobile.status !== 'OFFLINE' ? camMobile.telemetry.gimbal.status : `DISCONNECTED`" :hasBatteryIcon="true">
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.833.833A.833.833 0 005 1.667v2.5H3.333v-.834h-2.5v3.334h.834v2.028c0 .585.307 1.128.809 1.429l3.564 2.138c.26.156.555.238.858.238h1.435v5.833c0 .46.373.834.834.834h1.666c.46 0 .834-.373.834-.834v-6.666a.833.833 0 00-.834-.834H6.898L3.333 8.695V5.833H5V7.5c0 .46.373.833.833.833h10c.46 0 .834-.373.834-.833V1.667a.833.833 0 00-.834-.834h-10zm.834 2.5a.833.833 0 100-1.666.833.833 0 000 1.666z"/>
                    </svg>
                  </TelemetryIcon>
                </div>
                <div class="popup__info">
                  <Battery v-if="camMobile && camMobile.telemetry && camMobile.status !== 'OFFLINE' && camMobile.telemetry.gimbal.status === 'CONNECTED'" :status="camMobile.telemetry.gimbal.status" :battery="camMobile.telemetry.gimbal.batteryLvl" :showBatteryLevel="true"/>
                  <span v-else class="popup__unit popup__unit--off">OFF</span>
                </div>
              </div>
              <div class="popup__devices--col">
                <div class="popup__info">
                  <TelemetryIcon name="Headset" :status="camUnit && camUnit.telemetry && camUnit.telemetry.headset ? camUnit.telemetry.headset.status : `DISCONNECTED`" :hasBatteryIcon="true" >
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 .833a7.469 7.469 0 00-7.5 7.5v5.834a2.5 2.5 0 002.5 2.5h2.5V10H4.167V8.333a5.833 5.833 0 1111.666 0V10H12.5v6.667h3.333v.833H10v1.667h5a2.5 2.5 0 002.5-2.5V8.333c0-4.166-3.358-7.5-7.5-7.5z"/>
                    </svg>
                  </TelemetryIcon>
                </div>
                <div class="popup__info">
                  <Battery v-if="camUnit && camUnit.telemetry && camUnit.telemetry.headset && camUnit.telemetry.headset.status === 'CONNECTED'" :status="camUnit.telemetry.headset.status" :battery="camUnit.telemetry.headset.batteryLvl" :showBatteryLevel="true"/>
                  <span v-else class="popup__unit popup__unit--off">OFF</span>
                </div>
              </div>
            </div>
            <template v-if="camUnit && camUnit.telemetry && camUnit.telemetry.wifi">
              <div class="popup__details">
                <p class="popup__caption">IP:</p>
                <p  class="popup__text">{{ camUnit.telemetry.wifi.ip }}</p>
              </div>
              <div class="popup__details">
                <p class="popup__caption">WIFI network:</p>
                <p  class="popup__text">{{ camUnit.telemetry.wifi.ssid }}</p>
              </div>
            </template>
            <div class="popup__details">
              <p class="popup__caption">Unit ID:</p>
              <p class="popup__text">{{ camUnit ? camUnit.id: ''}}</p>
            </div>
            <div class="popup__details">
              <p class="popup__caption">Mobile ID:</p>
              <p class="popup__text">{{ camMobile ? camMobile.id: ''}}</p>
            </div>
          </div>
          <div class="popup__security">
            <Icon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5L12 1L21 5V11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5ZM15.5 8L17 9.5L10.5 16L7 12.5L8.5 11L10.5 13L15.5 8Z" fill="#111111"/>
              </svg>
            </Icon>
            <div class="popup__security--info">
              <h6>Connection is secure</h6>
              <p>Secure WebRTC protocol.<br>
                128-bit AES cipher encryption.<br>
                TLS encryption.
              </p>
            </div>
          </div>
          <div class="popup__itm">
            <slot name="buttons"/>
          </div>
        </div>
      </div>
      <div class="popup__overlay" @click="close"></div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/common/icon/Icon'
import TelemetryIcon from '@/common/telemetry-icon/TelemetryIcon'
import Battery from '@/common/battery/Battery'

export default {
  name: 'PopupUnit',
  components: {
    Icon,
    TelemetryIcon,
    Battery
  },
  props: ['unit', 'camMobile', 'camUnit', 'close']
}
</script>

<style lang="scss">
  .popup {
    width: 360px;
    background: #FFFFFF;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12);
    padding: 25px 15px 15px;
    color: #111111;
    margin: auto;
    position: fixed;
    z-index: 9999;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    &__outer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &--info {
      padding: 25px;
    }
    &--details {
      .popup__outer {
        min-height: 450px;
      }
    }
    &__overlay {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
    }
    &__close {
      position: absolute;
      top: 30px;
      right: 30px;
      display: block;
    }
    &__title {
      font-family: "CenturyGothic-Bold";
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 1px;
    }
    &__location {
      font-size: 16px;
      margin-bottom: 35px;
    }
    &__text {
      font-size: 16px;
    }
    &__caption {
      font-size: 12px;
      color: rgba(17, 17, 17, 0.6);
    }
    &__details {
      margin-bottom: 15px;
    }
    &__btn {
      .btn {
        margin-top: 15px!important;
      }
    }
    &__devices {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -10px 15px;
    }
    &__devices--col {
      padding: 0 10px;
      width: 25%;
      text-align: center;
    }
    &__unit {
      font-size: 12px;
    }
    &__unit--off {
      color: rgba(17, 17, 17, 0.4);
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      margin-bottom: 3px;
    }
    &__row {
      display: flex;
      align-items: center;
      margin: 0 -15px;
      padding: 15px 20px;
    }
    &__row--right {
      justify-content: flex-end;
    }
    &__col {
      padding: 0 15px;
    }
    &__link {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #111111;
      text-decoration: none;
    }
    &__link--red {
      color: #D31920;
    }
    &__security {
      display: flex;
      padding: 16px;
      background: #F5F5F5;
      .icon {
        margin: 0;
      }
      &--info {
        margin-left: 16px;
        font-size: 14px;
        h6 {
          font-weight: 800;
          margin-bottom: 8px;
        }
      }
    }
  }
</style>
