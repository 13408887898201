<template>
  <transition name="fade">
    <div>
      <div class="popup popup--edge">
        <div class="popup__outer">
          <div class="popup__itm">
            <a href="#" class="popup__close" @click.prevent="close">
              <Icon class="icon--close">
                <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z"/>
                </svg>
              </Icon>
            </a>
            <div class="popup__edge">
              <div class="popup__edge--itm">
                <Icon class="icon--edge">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 22H4a2 2 0 01-2-2V4a2 2 0 012-2h11.172a2 2 0 011.414.586l4.828 4.828A2 2 0 0122 8.828V20a2 2 0 01-2 2zM8 6h7v2h-5v3h5v2h-5v3h5v2H8V6z"/>
                  </svg>
                </Icon>
              </div>
              <div class="popup__edge--itm">
                <p class="popup__title">{{ currentMod.name }}</p>
                <p class="popup__location">{{ currentMod.meta.location }}</p>
              </div>
            </div>
            <div class="popup__devices">
              <div class="popup__devices--col">
                <div class="popup__info">
                  <TelemetryIcon name="Camera" :status="`CONNECTED`" :hasBatteryIcon="true">
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 1.667a5.833 5.833 0 110 11.666 5.833 5.833 0 010-11.666zm0 1.666a4.167 4.167 0 100 8.334 4.167 4.167 0 000-8.334zM10 5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zM5 18.333a1.667 1.667 0 01-1.667-1.666c0-.317.084-.609.242-.859l1.517-2.633A7.506 7.506 0 0010 15a7.506 7.506 0 004.908-1.825l1.517 2.633c.158.25.242.542.242.859A1.667 1.667 0 0115 18.333H5z"/>
                    </svg>
                  </TelemetryIcon>
                </div>
                <div class="popup__info">
                  <span class="popup__unit">{{ ipCameraAvailable.length}} / {{ ipCamera.length}}</span>
                </div>
              </div>
              <div class="popup__devices--col">
                <div class="popup__info">
                  <TelemetryIcon name="Headset" :status="audioDevice.status ? audioDevice.status : `OFFLINE`" :hasBatteryIcon="true">
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 .833a7.469 7.469 0 00-7.5 7.5v5.834a2.5 2.5 0 002.5 2.5h2.5V10H4.167V8.333a5.833 5.833 0 1111.666 0V10H12.5v6.667h3.333v.833H10v1.667h5a2.5 2.5 0 002.5-2.5V8.333c0-4.166-3.358-7.5-7.5-7.5z"/>
                    </svg>
                  </TelemetryIcon>
                </div>
                <div class="popup__info">
                  <span :class="!audioDevice.status ? 'popup__unit--off' : audioDevice.status === 'OFFLINE' ? 'popup__unit--off' : null" class="popup__unit">{{ !audioDevice.status ? 'OFF' : audioDevice.status === 'OFFLINE' ? 'OFF' : 'ON' }}</span>
                </div>
              </div>
            </div>
            <div class="popup__details">
              <p class="popup__caption">Pod ID:</p>
              <p class="popup__text">{{ currentPod.id }}</p>
            </div>
            <div class="popup__details">
              <p class="popup__caption">Mod ID:</p>
              <p class="popup__text">{{ currentMod.id}}</p>
            </div>
          </div>
          <div class="popup__itm">
            <slot name="buttons"/>
          </div>
        </div>
      </div>
      <div class="popup__overlay" @click="close"></div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/common/icon/Icon'
import TelemetryIcon from '@/common/telemetry-icon/TelemetryIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'PopupPodDetails',
  components: {
    Icon,
    TelemetryIcon
  },
  props: ['close'],
  computed: {
    ...mapGetters([
      'currentPod',
      'currentMod'
    ]),
    ipCamera () {
      return this.currentPod.devices.filter(device => device.type === 'IP_CAMERA')
    },
    ipCameraAvailable () {
      return this.ipCamera.filter(device => device.status === 'ONLINE')
    },
    audioDevice () {
      return this.currentPod.devices.filter(device => device.type === 'AUDIO_STREAM_DEVICE')
    }
  }
}
</script>

<style lang="scss" scoped>
  .popup {
    &.popup--edge {
      min-height: 300px;
    }
    &__location {
      margin-bottom: 0;
    }
    &__devices--col {
      width: 20%;
    }
    &__devices {
      justify-content: flex-start;
    }
    &__edge {
      display: flex;
      align-items: center;
      margin: 0 -10px 35px;
    }
    &__edge--itm {
      padding: 0 10px;
    }
  }
</style>
