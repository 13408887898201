<template>
  <div class="notification">
    <div class="notification__outer">
      <div class="notification__itm">
        <Icon class="icon--warning">
          <svg viewBox="0 0 23 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 12H10.5V7H12.5V12ZM12.5 16H10.5V14H12.5V16ZM0.5 19H22.5L11.5 0L0.5 19Z"/>
          </svg>
        </Icon>
      </div>
      <div class="notification__itm">
        <p class="notification__ttl">{{ title }}</p>
        <p class="notification__text">{{ text }}</p>
      </div>
      <a class="notification__close" @click.prevent="close">
        <Icon class="icon--close">
          <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z"/>
          </svg>
        </Icon>
      </a>
    </div>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'

export default {
  name: 'Warning',
  props: ['title', 'text'],
  components: {
    Icon
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .notification {
    position: relative;
    padding: 15px 40px 15px 17px;
    background: #D31920;
    color: #fff;
    font-size: 14px;
    &__ttl {
      font-weight: 700;
    }
    &__outer {
      display: flex;
      margin: 0 -10px;
      align-items: center;
    }
    &__itm {
      padding: 0 10px;
    }
    &__close {
      cursor: pointer;
      position: absolute;
      right: 17px;
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }
    .icon--close {
      fill: #fff;
    }
    .icon--warning {
      fill: #fff;
      width: 22px;
    }
  }
</style>
