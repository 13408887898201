<template>
  <div class="table__wrapper">
    <div class="tabs">
      <v-table
        :hideSortIcons="true"
        :data="recordingList"
        class="table table--without-hover">
        <thead slot="head">
          <tr>
            <th>
              <span class="table__caption">Unit</span>
            </th>
            <th>
              <span class="table__caption">Location</span>
            </th>
            <th>
              <span class="table__caption">Type</span>
            </th>
            <th>
              <span class="table__caption">Date</span>
            </th>
            <th>
              <span class="table__caption">Duration</span>
            </th>
            <th>
              <span class="table__caption">Note</span>
            </th>
            <th></th>
          </tr>
          <tr class="row__default row__filters">
            <td><input type="text" placeholder="All" @change="filterBy($event,'unitName')"></td>
            <td><input type="text" placeholder="All" @change="filterBy($event,'location')"></td>
            <td><!--input type="text" placeholder="All" @change="filterBy($event,'sessionType')"--></td>
            <td class="date__picker">
              <Datepicker
                ref="datePicker"
                input-class="datepicker-input"
                wrapper-class="datepicker-wrapper"
                :editable="false"
                :clear-button="true"
                placeholder="All"
                @cleared="clearDateFilter('startedDate')"
                @selected="filterBy($event,'startedDate')">
                <Icon slot="afterDateInput" :handleClick="openPicker">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9V11H4V9H6ZM10 9V11H8V9H10ZM14 9V11H12V9H14ZM16 2C16.5304 2 17.0391 2.21071 17.4142 2.58579C17.7893 2.96086 18 3.46957 18 4V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V4C0 3.46957 0.210714 2.96086 0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2H3V0H5V2H13V0H15V2H16ZM16 18V7H2V18H16ZM6 13V15H4V13H6ZM10 13V15H8V13H10ZM14 13V15H12V13H14Z" fill="#111111"/>
                  </svg>
                </Icon>
              </Datepicker>
            </td>
            <td></td>
            <td></td>
            <td class="search-cell search">
              <!-- <Icon>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.55055 12.4468 8.05071 12.9999 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z" fill="#111111"/>
                </svg>
              </Icon>
              <input type="text" placeholder="Search"> -->
            </td>
          </tr>
        </thead>
        <tbody v-if="recordingList.length" slot="body" slot-scope="{displayData}">
          <tr v-for="recording in displayData"
            :key="recording.recordingId"
            @click="() => goToRecording(recording.recordingId)">
            <td class="table__cell table__cell--name">
              <Icon v-if="recording.session.unit.type === 'STANCHION'">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1673 9.99967C17.1673 13.9577 13.9587 17.1663 10.0007 17.1663C6.04261 17.1663 2.83398 13.9577 2.83398 9.99967C2.83398 6.04163 6.04261 2.83301 10.0007 2.83301C13.9587 2.83301 17.1673 6.04163 17.1673 9.99967ZM19.1673 9.99967C19.1673 15.0623 15.0633 19.1663 10.0007 19.1663C4.93804 19.1663 0.833984 15.0623 0.833984 9.99967C0.833984 4.93706 4.93804 0.833008 10.0007 0.833008C15.0633 0.833008 19.1673 4.93706 19.1673 9.99967ZM12.015 5.59984C10.9061 4.49092 9.12319 4.44403 7.95751 5.49314C6.48378 6.81949 6.71283 9.19318 8.41298 10.2133L10.5593 11.5011C11.1063 11.8293 11.18 12.5929 10.7059 13.0196C10.3308 13.3571 9.75725 13.3421 9.40048 12.9853L8.20776 11.7926L6.79354 13.2068L7.98627 14.3995C9.09519 15.5084 10.8781 15.5553 12.0438 14.5062C13.5175 13.1799 13.2885 10.8062 11.5883 9.78608L9.44197 8.49828C8.89501 8.1701 8.82132 7.40644 9.29544 6.97973C9.67046 6.64221 10.2441 6.65729 10.6008 7.01405L11.3769 7.79011L12.7911 6.3759L12.015 5.59984Z" fill="#111111"/>
                </svg>
              </Icon>
              <Icon v-if="recording.session.unit.type === 'EDGE'">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3327 17.3337H2.66601C1.56145 17.3337 0.666016 16.4382 0.666016 15.3337V2.66699C0.666016 1.56242 1.56145 0.666992 2.66602 0.666992H11.5043C12.0347 0.666992 12.5434 0.877706 12.9185 1.25278L16.7469 5.08121C17.122 5.45628 17.3327 5.96499 17.3327 6.49542V15.3337C17.3327 16.4382 16.4373 17.3337 15.3327 17.3337ZM5.49935 3.83366H6.49935H11.4993V5.83366H7.49935V8.00033H11.4993V10.0003H7.49935V12.167H11.4993V14.167H6.49935H5.49935V13.167V4.83366V3.83366Z" fill="#111111"/>
                </svg>
              </Icon>
              <span>{{ recording.session.unit.name }}</span>
            </td>
            <td class="table__cell table__cell table__cell--location">
              <span>{{ recording.session.unit.location }}</span>
            </td>
            <td
              class="table__cell table__cell--type">
              <span v-if="recording.session.type !== 'REGULAR'"
                :class="[getTypeClass(recording.session.type)? getTypeClass(recording.session.type) : 'type--regular']">
                {{ recording.session.type }}
              </span>
            </td>
            <td class="table__cell">
              <span>{{ formatDateTime(recording.session.startedAt) }}</span>
            </td>
            <td class="table__cell">
              <span>{{ secToHm(recording.session.duration) }}</span>
            </td>
            <td class="table__cell--note">
              <span class="table__note">{{ recording.note }}</span>
            </td>
            <td class="table__cell">
              <div class="table__options">
                <div class="table__options--col">
                  <div class="table__row">
                    <div class="table__col">
                      <Icon v-if="recording.medias.count">
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.0007 5.5H10.334V3.83333H12.0007V5.5ZM12.0007 8.83333H10.334V7.16667H12.0007V8.83333ZM12.0007 12.1667H10.334V10.5H12.0007V12.1667ZM3.66732 5.5H2.00065V3.83333H3.66732V5.5ZM3.66732 8.83333H2.00065V7.16667H3.66732V8.83333ZM3.66732 12.1667H2.00065V10.5H3.66732V12.1667ZM12.0007 0.5V2.16667H10.334V0.5H3.66732V2.16667H2.00065V0.5C1.08018 0.5 0.333984 1.24619 0.333984 2.16667V13.8333C0.333984 14.7538 1.08018 15.5 2.00065 15.5V13.8333H3.66732V15.5H10.334V13.8333H12.0007V15.5C12.9211 15.5 13.6673 14.7538 13.6673 13.8333V2.16667C13.6673 1.24619 12.9211 0.5 12.0007 0.5Z" fill="#111111"/>
                        </svg>
                      </Icon>
                    </div>
                    <div class="table__col">
                      {{ recording.medias.count }}
                    </div>
                  </div>
                </div>
                <div class="table__options--col">
                  <div class="table__row">
                    <div class="table__col">
                      <Icon>
                        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3327 11.1664V12.8331H0.666016V11.1664C0.666016 11.1664 0.666016 7.83305 6.49935 7.83305C12.3327 7.83305 12.3327 11.1664 12.3327 11.1664ZM9.41602 3.24972C9.41602 2.67286 9.24496 2.10895 8.92447 1.62931C8.60398 1.14966 8.14846 0.775825 7.61551 0.55507C7.08256 0.334314 6.49611 0.276554 5.93034 0.389095C5.36456 0.501635 4.84486 0.779421 4.43695 1.18732C4.02905 1.59523 3.75127 2.11493 3.63873 2.68071C3.52619 3.24648 3.58395 3.83293 3.8047 4.36588C4.02546 4.89883 4.39929 5.35435 4.87894 5.67484C5.35858 5.99533 5.92249 6.16639 6.49935 6.16639C7.2729 6.16639 8.01476 5.85909 8.56174 5.31211C9.10873 4.76513 9.41602 4.02327 9.41602 3.24972ZM12.2827 7.83305C12.795 8.22951 13.2142 8.73341 13.5107 9.30931C13.8073 9.8852 13.9741 10.5191 13.9993 11.1664V12.8331H17.3327V11.1664C17.3327 11.1664 17.3327 8.14139 12.2827 7.83305ZM11.4993 0.333052C10.9258 0.329858 10.3648 0.501348 9.89102 0.824718C10.3972 1.532 10.6694 2.37996 10.6694 3.24972C10.6694 4.11948 10.3972 4.96744 9.89102 5.67472C10.3648 5.99809 10.9258 6.16958 11.4993 6.16639C12.2729 6.16639 13.0148 5.85909 13.5617 5.31211C14.1087 4.76513 14.416 4.02327 14.416 3.24972C14.416 2.47617 14.1087 1.7343 13.5617 1.18732C13.0148 0.640343 12.2729 0.333052 11.4993 0.333052Z" fill="#111111"/>
                        </svg>
                      </Icon>
                    </div>
                    <div class="table__col">
                      {{ recording.participants.count }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
      <div v-if="!recordingList.length" class="warnings">
         <LoaderIcon v-if="loading"/>
          <div v-if="!loading && !recordingsLength" class="warnings__message">
            <Icon>
              <svg width="68" height="66" viewBox="0 0 68 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 14.3333C9 11.3878 11.3878 9 14.3333 9H51.6667C54.6122 9 57 11.3878 57 14.3333V51.6667C57 54.6122 54.6122 57 51.6667 57H14.3333C11.3878 57 9 54.6122 9 51.6667V14.3333ZM14.3333 51.6667L14.3333 14.3333L19.6667 14.3333V19.6667H25V14.3333H41V19.6667H46.3333V14.3333L51.6667 14.3333V51.6667H46.3333V46.3333H41V51.6667H25V46.3333H19.6667V51.6667H14.3333ZM41 25H46.3333V30.3333H41V25ZM25 25H19.6667V30.3333H25V25ZM41 35.6667H46.3333V41H41V35.6667ZM25 35.6667H19.6667V41H25V35.6667Z" fill="#111111"/>
                <path d="M67 1L3 65" stroke="white" stroke-width="2"/>
                <path d="M65 1L1 65" stroke="#111111" stroke-width="2"/>
              </svg>
            </Icon>
            <div>
              No recordings
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { throttle } from 'lodash'
import LoaderIcon from '@/common/loader-icon/LoaderIcon'
import Icon from '@/common/icon/Icon'
import { formatDateTime, secToHm } from '@/helpers/date'

export default {
  name: 'RecordingsTable',
  components: {
    LoaderIcon,
    Icon,
    Datepicker
  },
  computed: {
    ...mapState({
      recordingList: state => state.Recordings.recordingList,
      loading: state => state.Recordings.loading
    }),
    ...mapGetters([
      'recordingsLength'
    ])
  },
  methods: {
    ...mapMutations(['setFilter', 'clearRecordingList']),
    ...mapActions(['getRecordings']),
    dateSort (a, b) {
      const date1 = new Date(a.session.startedAt).getTime()
      const date2 = new Date(b.session.startedAt).getTime()

      return date1 - date2
    },
    goToRecording (id) {
      this.$router.push({
        name: 'RecordingDetails',
        params: {
          id
        }
      })
    },
    openPicker () {
      this.$refs.datePicker.showCalendar()
    },
    clearDateFilter (filter) {
      this.clearRecordingList()
      this.setFilter({ filter, value: '' })
      this.getRecordings()
    },
    filterBy: throttle(function (e, filter) {
      if (!e) return
      const filterValue = filter === 'startedDate' ? new Date(e).toISOString().slice(0, 10) : e.target.value
      this.clearRecordingList()
      this.setFilter({ filter, value: filterValue })
      this.getRecordings()
    }, 100),
    getTypeClass (type) {
      switch (type) {
        case 'ESCORT':
          return 'type--escort'
        case 'SMARTHANDS':
          return 'type--smarthands'
        case 'TRAINING':
          return 'type--training'
        default:
          return ''
      }
    },
    formatDateTime,
    secToHm
  }
}
</script>

<style lang="scss" scoped>
  .table {
    th {
      border-bottom: none;
      &.search-cell {
        vertical-align: bottom;
        padding-bottom: 16px;
      }
    }
    table-layout: fixed;
    &__row {
      display: flex;
      align-items: center;
      margin: 0 -3px;
    }
    &__note {
      word-break: break-word;
    }
    &__col {
      padding: 0 3px;
    }
    &__options {
      display: flex;
      align-items: center;
      margin: 0 -10px;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
    &__options--col {
      min-width: 60px;
      padding: 0 10px;
    }
  }
  .table__wrapper {
    padding: 24px 32px;
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  .table__caption {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #111;
  }
  .vt-sort:after{
    vertical-align: middle;
    display: inline-block;
    width: 13px;
    height: 15px;
    content: none;
  }
  .vt-asc {
    .table__caption {
      &:after{
        content: url('./arrowDown.svg');
      }
    }
  }
  .vt-desc {
    .table__caption {
      &:after{
        content: url('./arrowDown.svg');
      }
    }
  }
  td {
    font-size: 14px;
    line-height: 16px;
  }
  .table__cell {
    span {
      vertical-align: middle;
    }

    &--name {
      font-weight: bold;

      i {
        margin-right: 14px;
        vertical-align: middle;
      }
    }
    &--type {
      font-size: 10px;
      letter-spacing: 1px;

      span {
        padding: 2px 4px;
        border-width: 1px;
        border-style: solid;
      }
      .type--escort {
        color: #4499FF;
        border-color: #4499FF;
      }
      .type--smarthands {
        color: #9944FF;
        border-color: #9944FF;
      }
      .type--training {
        color: #11DD66;
        border-color: #11DD66;
      }
      .type--regular {
        border: none
      }
    }
    &--note {
      max-width: 370px;
    }
  }
  .row__default {
    cursor: default;
    border: none;
    border-bottom: rgba(17, 17, 17, 0.2);
  }
  input {
      width: 100%;
      // max-width: 160px;
      padding: 10px;
      border: none;
      border-bottom: 1px solid #999;
      &:focus {
        outline: none;
      }
    }
  .row__filters {
    .date__picker {
      position: relative;
      // width: 200px;
      ::v-deep .datepicker-input {
        padding: 10px 48px 10px 10px;
        border: none;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      .datepicker-wrapper {
        // width: 100%;
        // max-width: 160px;
        border-bottom: 1px solid #999;
        position: relative;
      }
      i  {
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto 0;
        height: 20px;
      }
      ::v-deep .vdp-datepicker__clear-button {
        position: absolute;
        top: 0;
        right: 38px;
        bottom: 0;
        margin: auto 0;
        height: 20px;
      }
    }
  }
  .search {
    display: flex;
    justify-content: end;
    position: relative;
    align-items: center;
    i {
      position: absolute;
      left: 30px;
      bottom: 23px;
    }
    input {
      font-size: 16px;
      padding: 10px 5px 10px 35px;
      background: none;
      border-bottom: none;
      background: #F5F5F5;
    }
  }
  .warnings {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 50px 24px;
    &__message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.5px;
      .icon {
        width: auto;
      }
    }
  }
  @media (hover: hover) {
  .table tbody tr.row__default:hover {
    transform: scale(1);
    border-color: none;
    filter: none;
    // border-bottom: 1px solid rgba(17, 17, 17, 0.1);
    border: none
  }
}
  @media screen and (max-width: 1300px) {
    .table__cell {
      &--type {
        // width: 150px;
      }
      &--name {
        // width: 200px;
      }
      &--location {
        // width: 200px;
      }
    }
    .row__filters {
      .search__outer {
        // width: 200px;
      }
    }
  }
</style>
