<template>
  <div class="device-inventory" v-if="loggedIn && connectedStatus">
    <HeaderView headerType="vest"/>
    <DeviceInventoryTable/>
  </div>
  <Loading v-else/>
</template>

<script>
import { mapGetters } from 'vuex'
import DeviceInventoryTable from '@/components/device-inventory-table/DeviceInventoryTable'
import HeaderView from '@/components/header-view/HeaderView'
import Loading from '@/common/loading/Loading'

export default {
  name: 'DeviceInventory',
  components: {
    DeviceInventoryTable,
    HeaderView,
    Loading
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'connectedStatus'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .device-inventory {
    height: 100%;
  }
</style>
