<template>
  <div class="control">
    <v-popover placement="top">
      <span class="control__icon">
        <Icon class="icon--focus">
          <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 8C10.2044 8 9.44129 8.31607 8.87868 8.87868C8.31607 9.44129 8 10.2044 8 11C8 11.7956 8.31607 12.5587 8.87868 13.1213C9.44129 13.6839 10.2044 14 11 14C11.7956 14 12.5587 13.6839 13.1213 13.1213C13.6839 12.5587 14 11.7956 14 11C14 10.2044 13.6839 9.44129 13.1213 8.87868C12.5587 8.31607 11.7956 8 11 8ZM18 18H14V20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V14H18V18ZM18 2H14V4H18V8H20V4C20 3.46957 19.7893 2.96086 19.4142 2.58579C19.0391 2.21071 18.5304 2 18 2ZM4 4H8V2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V8H4V4ZM4 14H2V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H8V18H4V14Z" fill="white"/>
            <path d="M14 17C13.4477 17 13 17.4477 13 18V20C13 20.5523 13.4477 21 14 21H18C18.7957 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7957 21 18V14C21 13.4477 20.5523 13 20 13H18C17.4477 13 17 13.4477 17 14V17H14ZM14 1C13.4477 1 13 1.44772 13 2V4C13 4.55228 13.4477 5 14 5H17V8C17 8.55228 17.4477 9 18 9H20C20.5523 9 21 8.55228 21 8V4C21 3.20435 20.6839 2.44129 20.1213 1.87868C19.5587 1.31607 18.7957 1 18 1H14ZM8 5C8.55228 5 9 4.55228 9 4V2C9 1.44772 8.55228 1 8 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4V8C1 8.55228 1.44772 9 2 9H4C4.55228 9 5 8.55228 5 8V5H8ZM5 14C5 13.4477 4.55228 13 4 13H2C1.44772 13 1 13.4477 1 14V18C1 18.7957 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H8C8.55228 21 9 20.5523 9 20V18C9 17.4477 8.55228 17 8 17H5V14ZM11 7C9.93913 7 8.92172 7.42143 8.17157 8.17157C7.42143 8.92172 7 9.93913 7 11C7 12.0609 7.42143 13.0783 8.17157 13.8284C8.92172 14.5786 9.93913 15 11 15C12.0609 15 13.0783 14.5786 13.8284 13.8284C14.5786 13.0783 15 12.0609 15 11C15 9.93913 14.5786 8.92172 13.8284 8.17157C13.0783 7.42143 12.0609 7 11 7Z" stroke="#111111" stroke-opacity="0.1" stroke-width="2" stroke-linejoin="round"/>
          </svg>
        </Icon>
      </span>
      <template slot="popover">
        <div class="popover__buttons">
          <ul class="control__list">
            <li class="control__list-itm">
              <a
                :class="plusClasses"
                @mousedown="sendFocus(50)"
                @mouseup="sendFocus(0)"
              >
                <Icon class="icon--plus">
                  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"/>
                  </svg>
                </Icon>
              </a>
            </li>
            <li class="control__list-itm">
              <a
                :class="minusClasses"
                @mousedown="sendFocus(-50)"
                @mouseup="sendFocus(0)"
              >
                <Icon class="icon--minus">
                  <svg viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 2H0V0H14V2Z"/>
                  </svg>
                </Icon>
              </a>
            </li>
            <li class="control__list-itm control__list--bordered">
              <a
                :class="autoClasses"
                @click="sendAutoFocus('on')"
              >
                Auto
              </a>
            </li>
          </ul>
        </div>
      </template>
      <span class="control__text">{{ focusText }}</span>
    </v-popover>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'
import countPercents from '@/helpers/countPercents'
import { mapActions } from 'vuex'

export default {
  name: 'FocusBtn',
  props: ['currentUnit', 'device', 'type'],
  data () {
    return {
    }
  },
  components: {
    Icon
  },
  computed: {
    plusClasses: function () {
      return {
        control__btn: true,
        'control__btn--inactive': this.device.telemetry.ptz?.focus >= this.device.telemetry.ptz?.MaxFocus
      }
    },
    minusClasses: function () {
      return {
        control__btn: true,
        'control__btn--inactive': this.device.telemetry.ptz?.focus <= this.device.telemetry.ptz?.MinFocus
      }
    },
    autoClasses: function () {
      return {
        control__btn: true,
        'control__btn--inactive': this.device.telemetry.ptz?.autofocus === 'on'
      }
    },
    focusText () {
      const countedFocus = countPercents(this.device.telemetry.ptz?.focus, this.device.telemetry.ptz?.MaxFocus)
      return this.device.telemetry.ptz?.autofocus === 'on' ? 'AUTO' : countedFocus
    }
  },
  methods: {
    ...mapActions([
      'sendPTZCommand'
    ]),
    sendFocus (focusCount) {
      this.sendPTZCommand({ currentContainerId: this.currentUnit.id, currentUnitId: this.device.id, destination: 'unit', command: 'FOCUS', focus: focusCount, type: this.type })
    },
    sendAutoFocus (focusMode) {
      this.sendPTZCommand({ currentContainerId: this.currentUnit.id, currentUnitId: this.device.id, destination: 'unit', command: 'AUTO_FOCUS', autofocus: focusMode, type: this.type })
    }
  }
}
</script>

<style lang="scss" scoped></style>
