<template>
  <div :class="itemClasses">
    <div
      v-if="type === 'VEST_CLIENT'"
      :class="nameClasses"
    >
      {{formattedName}}
    </div>
    <div
      v-else-if="type === 'MORE_ICON'"
      class="participant-icon__more-text"
    >
      {{name}}
    </div>
    <div
      v-else
      class="participant-icon__icon"
    >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3846 9.90866L17.0763 14.567L14.3096 17.3003L9.6513 12.642V11.8753L11.618 9.90866H12.3846ZM13.143 7.80033L12.3346 7.00033L8.34297 11.0003V12.642L3.6513 17.3337L0.917969 14.567L5.5763 9.90866H7.21797L7.86797 9.25866L4.66797 6.05033H3.58464L1.24297 3.68366L3.4263 1.50033L5.75963 3.83366V4.92533L9.0013 8.12533L11.218 5.90866L10.418 5.06699L11.5013 3.97533H9.28464L8.74297 3.43366L11.5013 0.666992L12.0513 1.21699V3.43366L13.143 2.33366L15.8763 5.06699C16.7846 5.98366 16.7846 7.47533 15.8763 8.38366L14.2346 6.70866L13.143 7.80033Z" fill="#111111"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParticipantIcon',
  props: ['name', 'type', 'active', 'red'],
  data () {
    return {}
  },
  computed: {
    formattedName () {
      const firstName = this.name.split(' ')[0]
      const secondName = this.name.split(' ')[1]

      return firstName && secondName ? `${firstName.split('')[0]}${secondName.split('')[0]}` : this.name[0]
    },
    itemClasses () {
      return {
        'participant-icon': true,
        'participant-icon--active': this.active
      }
    },
    nameClasses () {
      return {
        'participant-icon__text': true,
        'participant-icon__text--red': this.red
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .participant-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #F5F5F5;
    padding: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(17,17,17,0.1);
    margin: 0 4px;
    position: relative;
    transition: border-color .2s;

    &--active {
      border: 2px solid #D31920;
      padding: 10px;
    }

    .participants-short--big & {
      margin: 0 -4px;

      &:nth-child(1) {
        margin: 0 -4px 0 0;
        z-index: 4;
      }

      &:nth-child(2) {
        z-index: 3;
      }

      &:nth-child(3) {
        z-index: 2;
      }

      &:nth-child(4) {
        z-index: 1;
        margin: 0 0 0 -4px;
      }
    }

    &__text {
      color: #111111;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 17px;
      text-align: center;
      width: 40px;

      &--red {
        color: #D31920
      }
    }

    &__more-text {
      font-weight: 400;
      line-height: 17px;
      font-size: 12px;
      color: rgba(17,17,17,.6)
    }

    &__icon {
      svg {
        width: 16px;
        height: 16px;
        fill: #111111
      }
    }
  }
</style>
