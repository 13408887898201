<template>
  <div id="app">
    <main class="main">
      <div class="container">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Vue from 'vue'
import uuid from '@/helpers/uuid'
import { mapActions, mapGetters } from 'vuex'
import * as axioshelper from './helpers/axios.js'
import { showErrorToast, dismissToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'loggedIn',
      'user',
      'currentUnit',
      'defaultTitle'
    ]),
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions([
      'setUser',
      'setLoggedIn',
      'setUID',
      'connectToSocket',
      'setWifiConnection',
      'disconnectFromSocket',
      'connectToSocket',
      'getUnits'
    ]),
    getUserInfo () {
      return this.$auth.getIAMUserInfo()
        .then((response) => {
          this.setUser(response)
          return response
        })
    }
  },
  created () {
    uuid.generateUUID()
    axioshelper.axiosInjector(this, Vue)
  },
  mounted () {
    window.addEventListener('offline', (e) => {
      this.disconnectFromSocket()
      this.setWifiConnection(false)
      console.log('offline', e)
      showErrorToast({ id: 1, message: ERRORS[1] })
    }, false)
    window.addEventListener('online', (e) => {
      this.setWifiConnection(true)
      console.log('online', e)
      if (this.currentRouteName === 'HomePage') {
        this.getUnits(this.user.user_metadata.organization_id)
      } else {
        this.connectToSocket()
      }
      dismissToast(1)
    }, false)
    document.title = this.defaultTitle
  }
}
</script>

<style lang="scss">
  @import './styles/global';

  .main, #app {
    height: 100%;
  }

   @media only screen
   and (min-device-width : 768px)
   and (max-device-width : 1024px)
   and (orientation : portrait) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      padding-right: 40px;
    }
  }
</style>
