<template>
  <div class="base-timer">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path :stroke-dasharray="circleDasharray" class="base-timer__path-remaining" d="M50,50m-45,0a 45,45 0 1,0 90,0a 45,45 0 1,0 -90,0"></path>
      </g>
    </svg>
    <!-- <span class="base-timer__label">{{ formattedTimeLeft }}</span> -->
  </div>
</template>

<script>
const FULL_DASH_ARRAY = 283

export default {
  name: 'ReconnectionTimer',
  data () {
    return {
      timePassed: 0,
      timerInterval: null
    }
  },
  props: {
    timeLimit: Number
  },
  computed: {
    circleDasharray () {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
    },
    formattedTimeLeft () {
      const timeLeft = this.timeLeft
      let seconds = timeLeft % 60
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      return `${seconds}`
    },
    timeLeft () {
      return this.timeLimit - this.timePassed
    },
    timeFraction () {
      const rawTimeFraction = this.timeLeft / this.timeLimit
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction)
    }
  },
  watch: {
    timeLeft (newValue) {
      if (newValue === 0) {
        this.onTimesUp()
      }
    }
  },
  mounted () {
    this.startTimer()
  },
  methods: {
    onTimesUp () {
      clearInterval(this.timerInterval)
    },
    startTimer () {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.base-timer {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 50px;
  height: 50px;
  &__svg {
    transform: scaleX(-1);
  }
  &__circle {
    fill: none;
    stroke: none;
  }
  &__path-elapsed {
    stroke: transparent;
  }
  &__path-remaining {
    stroke-width: 6px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
    color: #D31920;
  }
  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #fff;
    font-weight: normal;
  }
}
</style>
