<template>
  <div class="room">
    <div class="room__outer" v-if="!recordWarning">
      <div class="room__itm">
        <HeaderSessionRoom
          v-if="currentUnit"
          :currentUnit="currentUnit"
          :camMobile="currentCamMobile"
          :camUnit="currentCamUnit"/>
      </div>
      <div class="room__itm room__itm--camera">
        <CameraView v-if="sessionStatus === 'IN_SESSION' && this.currentSession.id"
          :currentUnit="currentUnit"
          :camMobile="currentCamMobile"
          :camUnit="currentCamUnit"/>
        <div v-if="!connected" class="room__loader">
          <LoaderIcon :timer="true">
            <template slot="loaderInfo">Hold on</template>
            <template slot="loaderDescription">Something went wrong. We’re trying to get back to the session</template>
          </LoaderIcon>
        </div>
      </div>
    </div>
    <Loading v-else/>
<!--    <Loading :recordWarning="recordWarning" v-else/>-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import HeaderSessionRoom from '@/components/header-session-room/HeaderSessionRoom'
import Loading from '@/common/loading/Loading'
import CameraView from '@/components/camera-view/CameraView'
import LoaderIcon from '@/common/loader-icon/LoaderIcon'
// import { showInfoToast } from '@/helpers/toastification'
// import RecordIcon from '@/common/toastification/RecordIcon'

export default {
  name: 'SessionRoom',
  components: {
    Loading,
    CameraView,
    HeaderSessionRoom,
    LoaderIcon
  },
  data () {
    return {
      timeId: null,
      recordWarning: true,
      reconnectTimeout: null,
      reconnectionTime: 60000,
      connected: true
    }
  },
  computed: {
    ...mapGetters([
      'units',
      'currentUnitName',
      'connectedStatus',
      'currentSession',
      'currentUnit',
      'currentCamUnit',
      'currentCamMobile',
      'wifiConnection',
      'user'
    ]),
    sessionStatus () {
      return this.currentUnit && this.currentUnit.session && this.currentUnit.session.status
    },
    internetConnection () {
      return this.wifiConnection && this.connectedStatus
    }
  },
  methods: {
    ...mapMutations(['setUnits']),
    ...mapActions([
      'setCurrentUnitName',
      'setCurrentSession',
      'subscribeToTopic',
      'sendCloseSession',
      'clearPulishersList',
      'setSessionStatus',
      'deleteSessionData',
      'connectToSocket',
      'getUnits',
      'disconnectFromSocket'
    ]),
    timeOutSession () {
      this.timeId = setTimeout(() => {
        if (this.sessionStatus || this.currentUnit.status || this.currentUnit.session || this.currentCamUnit.status === 'ONLINE') return 'continue session'
        console.warn(this.sessionStatus, this.currentUnit, 'loading time out!')
        this.$router.push({ path: '/' })
        this.disconnectFromSocket()
        this.setUnits([])
        this.getUnits(this.user.user_metadata.organization_id)
      }, 10000)
    }
  },
  mounted () {
    this.setCurrentUnitName(this.$route.params.unitName)
    document.title = this.$route.params.unitName
    setTimeout(() => {
      this.recordWarning = false
    }, 1200)
    /**
     * Recording are not implemented so next line should be commented
     * https://supernap.atlassian.net/browse/VEST2-1290
     */
    // showInfoToast('Recording has been started by the host', false, RecordIcon, false)

    // this.timeOutSession()
  },
  destroyed () {
    this.clearPulishersList()
    clearTimeout(this.reconnectTimeout)
  },
  watch: {
    internetConnection: {
      handler (val, oldVal) {
        if (oldVal === val) return
        if (!val) {
          const session = {
            status: 'NO_SESSION'
          }
          this.setSessionStatus({ id: this.currentUnit.id, session })
          this.connected = false
        } else {
          setTimeout(() => {
            if (this.sessionStatus === 'IN_SESSION') {
              this.setCurrentSession(this.currentUnit.session)
              this.subscribeToTopic(this.currentUnit.session.destination)
            }
          }, 4000)
        }
      }
    },
    sessionStatus: {
      handler (val, oldVal) {
        if (val === 'NO_SESSION' || (val === 'NO_SESSION' && oldVal === undefined)) {
          this.reconnectTimeout = setTimeout(() => {
            this.$router.push({ path: '/' })
            this.disconnectFromSocket()
            this.deleteSessionData()
            this.setUnits([])
            clearTimeout(this.reconnectTimeout)
            this.connected = false
            this.getUnits(this.user.user_metadata.organization_id)
          }, this.reconnectionTime)
        } else if (val === 'NO_SESSION' || oldVal === 'IN_SESSION') {
          this.$router.push({ path: '/' })
          this.disconnectFromSocket()
          this.deleteSessionData()
          this.setUnits([])
          this.getUnits(this.user.user_metadata.organization_id)
        } else {
          this.connected = true
          if (this.reconnectionTime) {
            clearTimeout(this.reconnectTimeout)
          }
        }
      }
    },
    currentUnit: {
      handler () {
        if (!this.currentSession.id && this.currentUnit && this.currentUnit.session) {
          this.setCurrentSession(this.currentUnit.session)
          this.subscribeToTopic(this.currentUnit.session.destination)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .room {
    height: 100%;
    &__outer {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
    &__loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
    &__itm--camera {
      background: #000;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
</style>
