import Vue from 'vue'

export default {
  namespaced: false,
  state: {
    loading: false,
    mediaList: [],
    recordingDetails: {
      recordingId: null,
      session: {
        id: '',
        unit: {
          id: '',
          name: '',
          location: '',
          type: ''
        },
        type: '',
        startedAt: null,
        duration: null
      },
      note: '',
      participants: {
        count: null,
        list: []
      }
    },
    currentMediaId: null,
    downloadRecordingUrl: null
  },
  getters: {
    participants (state) {
      return state.recordingDetails.participants.list
    },
    currentMedia (state) {
      return state.mediaList.find(el => el.id === state.currentMediaId)
    },
    recordingDetails (state) {
      return state.recordingDetails
    },
    mediaList (state) {
      return state.mediaList
    },
    downloadRecordingUrl (state) {
      return state.downloadRecordingUrl
    },
    note (state) {
      return state.recordingDetails.note
    }
  },
  mutations: {
    setMediaList (state, mediaList) {
      state.mediaList = mediaList
    },
    setCurrentMediaId (state, id) {
      if (!id && !state.currentMediaId) {
        state.currentMediaId = state.mediaList[0].id
        return
      }
      state.currentMediaId = id
    },
    clearCurrentMediaId (state) {
      state.currentMediaId = null
    },
    setRecordingDetails (state, recordingDetails) {
      state.recordingDetails = recordingDetails
    },
    removeVideoFile (state, id) {
      const removeVideo = state.mediaList.find(media => media.id === id)
      if (removeVideo) {
        state.mediaList.splice(removeVideo, 1)
        if (state.mediaList.length > 0) {
          state.currentMediaId = state.mediaList[0].id
        }
      }
    },
    removeAllVideoFile (state) {
      state.mediaList = []
    },
    setDownloadRecordingUrl (state, url) {
      state.downloadRecordingUrl = url
    },
    handleLoading (state, value) {
      state.loading = value
    },
    deleteSessionRecording (rootState, id) {
      const removeRecording = rootState.recordingList.find(recording => recording.id === id)
      if (removeRecording) {
        rootState.recordingList.splice(removeRecording, 1)
      }
    },
    setDescription (state, note) {
      state.recordingDetails.note = note
    }
  },
  actions: {
    getRecordingDetails ({ commit, rootState }, recordingId) {
      commit('handleLoading', true)
      const recordingsDetailsUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording/${recordingId}`
      return new Promise(resolve => {
        Vue.prototype.$axios
          .get(recordingsDetailsUrl)
          .then(data => {
            commit('setRecordingDetails', data.data)
            resolve()
          }).catch(e => {
            console.warn(`Recording details request error: ${e}`)
          })
          .finally(() => commit('handleLoading', false))
      })
    },
    async getMediaList ({ commit, rootState, dispatch, state }, recordingId) {
      const recordingsMediaListsUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording/${recordingId}/media`
      return new Promise((resolve) => {
        Vue.prototype.$axios
          .get(recordingsMediaListsUrl)
          .then(data => {
            // Show videos only without tags
            const filtered = data.data.filter(item => !item.tags)
            commit('setMediaList', filtered)
            commit('setCurrentMediaId', filtered[0].id)
            resolve()
          }).catch(e => {
            console.warn(`Media list request error: ${e}`)
          })
      })
    },
    deleteMediaFile ({ commit, rootState }, { recordingId, currentMediaId }) {
      const deleteVideoUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording/${recordingId}/media/${currentMediaId}`
      Vue.prototype.$axios
        .delete(deleteVideoUrl)
        .then(() => {
          commit('removeVideoFile', currentMediaId)
        }).catch(e => {
          console.warn(`error: ${e}`)
        })
    },
    deleteAllMediaFile ({ commit, rootState }, recordingId) {
      commit('removeAllVideoFile')
      const deleteVideoUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording/${recordingId}/media`
      Vue.prototype.$axios
        .delete(deleteVideoUrl)
        .then(() => {
          commit('removeAllVideoFile')
        }).catch(e => {
          console.warn(`error: ${e}`)
        })
    },
    clearCurrentMediaId ({ commit }) {
      commit('clearCurrentMediaId')
    },
    getDownloadUrl ({ commit, rootState, state }) {
      const { recordingId } = state.recordingDetails
      const { currentMediaId } = state
      const recordingDownloadUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording/${recordingId}/media/${currentMediaId}/downloadUrl`
      Vue.prototype.$axios
        .get(recordingDownloadUrl)
        .then(response => {
          commit('setDownloadRecordingUrl', response.data.url)
          // window.location.href = response.data.url
        }).catch(e => {
          console.warn(`Recording download request error: ${e}`)
        })
    },
    deleteSessionRecording ({ commit, rootState }, recordingId) {
      const deleteSessionUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording/${recordingId}`
      Vue.prototype.$axios
        .delete(deleteSessionUrl)
        .then(() => {
          commit('deleteSessionRecording', recordingId)
        }).catch(e => {
          console.warn(`error: ${e}`)
        })
    }
  }
}
