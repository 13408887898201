<template>
  <div v-if="audioOnly">
    <audio ref="audioPublisher" :id="streamName" :streamName="streamName" autoplay muted></audio>
  </div>
</template>

<script>
import Vue from 'vue'
import kurentoUtils from 'kurento-utils'
import { mapActions, mapGetters } from 'vuex'
import WebsocketHeartbeatJs from 'websocket-heartbeat-js'
import uuid from '@/helpers/uuid'
import detectSilence from '../../helpers/detectSilence'
import { showErrorToast, clearToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'

export default {
  name: 'KurentoPublisher',
  props: ['audioOnly', 'streamName', 'currentSession'],
  data () {
    return {
      candidatesList: [],
      publishReceived: false,
      audioContext: null,
      mediaStream: null,
      options: {
        iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
        audioPublisher: null,
        onicecandidate: this.onIceCandidate,
        mediaConstraints: {
          video: !this.audioOnly,
          audio: {
            echoCancellation: true
          }
        }
      },
      webRtcPeer: null,
      ws: null,
      mediaUrlKurento: window.VEST_CONFIG.kurento,
      unavailableMicrophone: false
    }
  },
  computed: {
    ...mapGetters([
      'isMuted',
      'UID',
      'suid'
    ])
  },
  methods: {
    ...mapActions([
      'exposeSessionDetails',
      'setActiveParticipant'
    ]),
    getMicrophonePermissions () {
      return navigator.permissions.query({ name: 'microphone' })
        .then(result => {
          if (result.state === 'denied') {
            this.unavailableMicrophone = true
          }
        })
    },
    checkAudioDevices () {
      navigator.mediaDevices.getUserMedia({
        audio: true
      }).then((value) => {
        this.mediaStream = value
      }).catch((error) => { console.error(`Audio device error: ${error}`) })
    },
    publish () {
      if (!this.webRtcPeer) {
        this.ws = new WebsocketHeartbeatJs({ url: this.mediaUrlKurento, pingTimeout: 60000, pongTimeout: 10000, pingMsg: '{"type": "PING"}' })
        this.ws.onmessage = this.onMessage
        this.ws.onopen = () => {
          this.webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(this.options, error => {
            if (error) {
              this.getMicrophonePermissions().then(() => {
                if (this.unavailableMicrophone) {
                  showErrorToast({ id: 5, message: ERRORS[5] })
                  console.warn('onGetUserMedia microphone - off', error)
                } else {
                  console.error('webrtc error', error)
                  showErrorToast({ id: 8, message: ERRORS[8] })
                }
              })
              this.exposeSessionDetails({ noMicrophone: true })
              return
            }
            this.webRtcPeer.generateOffer(this.onOffer)
            this.$refs.audioPublisher.muted = true
            const stream = this.webRtcPeer.getLocalStream()
            this.exposeSessionDetails({ muted: true })
            this.webRtcPeer.audioEnabled = false
            this.audioContext = detectSilence(stream, this.onSilence, this.onSpeak)
          })
        }
        this.ws.onerror = (error) => {
          console.error('WebSocket connection failed', error)
          showErrorToast({ id: 8, message: ERRORS[8] })
        }
      }
    },
    onIceCandidate (candidate) {
      const message = {
        type: 'ICE_CANDIDATE',
        streamId: this.streamName,
        payload: candidate
      }
      if (!this.publishReceived) {
        this.candidatesList.push(message)
      } else {
        if (this.candidatesList.length > 0) {
          this.candidatesList.forEach((value, index, array) => {
            this.sendMessage(value)
            array.splice(index, 1)
          })
        }
        this.sendMessage(message)
      }
    },
    async onOffer (error, offerSdp) {
      if (error) return console.error('Error generating the offer: ' + error)

      const TOKEN = await Vue.prototype.$auth.getToken().then(data => data)
      this.sendMessage({
        authorization: {
          token: TOKEN,
          target: this.currentSession.id
        },
        type: 'PUBLISH',
        streamId: this.streamName,
        id: this.UID,
        payload: {
          offer: offerSdp,
          mediaType: 'A'
        }
      })
    },
    sendMessage (message) {
      const jsonMessage = JSON.stringify(message)
      console.log('<<< Sending message: ' + jsonMessage)
      this.ws.send(jsonMessage)
    },
    response (message) {
      if (message.error) {
        const errorMsg = message.error.message ? message.error.message : 'Unknown error'
        console.info('Call not accepted for the following reason: ' + errorMsg)
        showErrorToast({ id: 6, message: ERRORS[6] })
        this.dispose()
      } else {
        this.webRtcPeer.processAnswer(message.payload.answer, function (error) {
          if (error) {
            return console.error(error)
          }
        })
        this.publishReceived = true
      }
    },
    dispose () {
      if (this.webRtcPeer) {
        uuid.generateUUID()
        this.audioContext.close()
        const tracks = this.mediaStream.getTracks()
        if (tracks?.length > 0) {
          tracks.forEach(track => {
            track.stop()
            this.mediaStream.removeTrack(track)
          })
          this.mediaStream = null
        }
        this.webRtcPeer.dispose()
        this.webRtcPeer = null

        this.ws.close()
        this.ws = null
        console.log(`stream ${this.streamName} successfully closed`)
      }
    },
    stop () {
      this.sendMessage({
        type: 'STOP',
        streamId: this.streamName
      })
      this.dispose()
    },
    onMessage (message) {
      console.info('>>> Received message: ' + message.data)
      const parsedMessage = JSON.parse(message.data)

      switch (parsedMessage.type) {
        case 'ERROR':
          console.error('Error message from server: ' + parsedMessage.error.message)
          showErrorToast({ id: 8, message: ERRORS[8] })
          break

        case 'PUBLISH_RESPONSE':
          this.response(parsedMessage)
          break

        case 'ICE_CANDIDATE':
          this.webRtcPeer.addIceCandidate(parsedMessage.payload, function (error) {
            if (error) {
              return console.error('Error adding candidate: ' + error)
            }
          })
          break

        case 'CLOSE':
          this.dispose()
          break

        case 'PONG':
          break

        default:
          console.error('Unrecognized message', parsedMessage)
          showErrorToast({ id: 11, message: ERRORS[11] })
      }
    },
    onSilence () {
      this.setActiveParticipant({ uuid: this.suid, talking: false })
    },
    onSpeak () {
      this.setActiveParticipant({ uuid: this.suid, talking: true })
    }
  },
  watch: {
    isMuted: {
      handler () {
        if (!this.webRtcPeer) return
        if (this.isMuted) {
          this.webRtcPeer.audioEnabled = false
        } else {
          this.webRtcPeer.audioEnabled = true
        }
      }
    }
  },
  mounted () {
    this.options.audioPublisher = this.$refs.audioPublisher
    this.checkAudioDevices()
    this.publish()
  },
  destroyed () {
    clearToast()
    if (!this.webRtcPeer) return
    this.stop()
  }
}
</script>

<style lang="scss" scoped></style>
