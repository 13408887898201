export default class JoystickElement {
  constructor (selector) {
    this.element = document.querySelector(selector)
    this.rect = this.calculateRect()
    this.current = this.original

    // Recalculate the rect on resizing
    window.onresize = () => {
      this.rect = this.calculateRect()
    }
  }

  get original () {
    return {
      vector: {
        x: 0,
        y: 0
      },
      angle: 0,
      percentage: 0
    }
  }

  updateRect () {
    this.rect = this.calculateRect()
  }

  calculateRect () {
    const rect = this.element.getBoundingClientRect()

    return Object.assign(
      rect,
      {
        center: {
          x: rect.x + window.pageXOffset + rect.width / 2,
          y: rect.y + window.pageYOffset + rect.height / 2
        },
        radius: rect.width / 2 // Improve this
      }
    )
  }
}
