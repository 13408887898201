<template>
  <div class="control">
    <v-popover placement="top">
      <span class="control__icon">
        <Icon class="icon--zoom">
          <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 13L19.5 18L18 19.5L13 14.5V13.71L12.73 13.43C11.554 14.4439 10.0527 15.0011 8.5 15C6.77609 15 5.12279 14.3152 3.90381 13.0962C2.68482 11.8772 2 10.2239 2 8.5C2 6.77609 2.68482 5.12279 3.90381 3.90381C5.12279 2.68482 6.77609 2 8.5 2C10.2239 2 11.8772 2.68482 13.0962 3.90381C14.3152 5.12279 15 6.77609 15 8.5C15 10.11 14.41 11.59 13.43 12.73L13.71 13H14.5ZM8.5 13C11 13 13 11 13 8.5C13 6 11 4 8.5 4C6 4 4 6 4 8.5C4 11 6 13 8.5 13ZM11 9H9V11H8V9H6V8H8V6H9V8H11V9Z" fill="white"/>
            <path d="M8.49929 16C9.78995 16.0009 11.0499 15.6677 12.161 15.0441C12.1988 15.1025 12.243 15.1572 12.2929 15.2071L17.2929 20.2071C17.6834 20.5976 18.3166 20.5976 18.7071 20.2071L20.2071 18.7071C20.5976 18.3166 20.5976 17.6834 20.2071 17.2929L15.2071 12.2929C15.157 12.2428 15.1022 12.1986 15.0436 12.1607C15.6529 11.0759 16 9.82599 16 8.5C16 6.51088 15.2098 4.60322 13.8033 3.1967C12.3968 1.79018 10.4891 1 8.5 1C6.51088 1 4.60322 1.79018 3.1967 3.1967C1.79018 4.60322 1 6.51088 1 8.5C1 10.4891 1.79018 12.3968 3.1967 13.8033C4.60305 15.2097 6.51042 15.9998 8.49929 16ZM8.49929 16L8.5 15M8.49929 16C8.49953 16 8.49976 16 8.5 16V15M8.5 15C6.77609 15 5.12279 14.3152 3.90381 13.0962C2.68482 11.8772 2 10.2239 2 8.5C2 6.77609 2.68482 5.12279 3.90381 3.90381C5.12279 2.68482 6.77609 2 8.5 2C10.2239 2 11.8772 2.68482 13.0962 3.90381C14.3152 5.12279 15 6.77609 15 8.5C15 9.78509 14.6241 10.9874 13.974 12L12 13.9748C10.9611 14.6416 9.74713 15.0009 8.5 15ZM11 10C11.3586 10 11.673 9.8113 11.8495 9.52778C11.5133 10.642 10.642 11.5133 9.52778 11.8495C9.8113 11.673 10 11.3586 10 11V10H11ZM9.52778 5.15046C10.642 5.48673 11.5133 6.35799 11.8495 7.47222C11.673 7.1887 11.3586 7 11 7H10V6C10 5.64145 9.81129 5.32697 9.52778 5.15046ZM5.15046 7.47222C5.48673 6.35799 6.35799 5.48673 7.47222 5.15046C7.18871 5.32697 7 5.64145 7 6V7H6C5.64145 7 5.32697 7.18871 5.15046 7.47222ZM6 10H7V11C7 11.3586 7.1887 11.673 7.47222 11.8495C6.35799 11.5133 5.48673 10.642 5.15046 9.52778C5.32697 9.81129 5.64145 10 6 10Z" stroke="#111111" stroke-opacity="0.1" stroke-width="2" stroke-linejoin="round"/>
          </svg>
        </Icon>
      </span>
      <template slot="popover">
        <div class="popover__buttons">
          <ul class="control__list">
            <li class="control__list-itm">
              <a
                :class="plusClasses"
                @mousedown="sendZoom(99.9)"
                @mouseup="sendZoom(0)"
              >
                <Icon class="icon--plus">
                  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"/>
                  </svg>
                </Icon>
              </a>
            </li>
            <li class="control__list-itm">
              <a
                :class="minusClasses"
                @mousedown="sendZoom(-99.9)"
                @mouseup="sendZoom(0)"
              >
                <Icon class="icon--minus">
                  <svg viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 2H0V0H14V2Z"/>
                  </svg>
                </Icon>
              </a>
            </li>
            <li class="control__list-itm control__list--bordered">
              <a class="control__btn" @mousedown="sendZoom(-9999)">
                <Icon class="icon--reset">
                  <svg viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.39 18 13.68 17.06 15.4 15.4L13.9 13.9C13.2702 14.5664 12.5104 15.0966 11.6676 15.4578C10.8248 15.819 9.91693 16.0036 9 16C2.76 16 -0.36 8.46 4.05 4.05C8.46 -0.36 16 2.77 16 9H13L17 13H17.1L21 9H18C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9Z"/>
                  </svg>
                </Icon>
              </a>
            </li>
          </ul>
        </div>
      </template>
      <span class="control__text">{{ zoomText }}</span>
    </v-popover>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'
import countPercents from '@/helpers/countPercents'
import { mapActions } from 'vuex'

export default {
  name: 'ZoomBtn',
  props: ['currentUnit', 'device', 'type'],
  data () {
    return {}
  },
  components: {
    Icon
  },
  computed: {
    plusClasses: function () {
      return {
        control__btn: true,
        'control__btn--inactive': this.device.telemetry.ptz?.zoom >= this.device.telemetry.ptz?.MaxZoom
      }
    },
    minusClasses: function () {
      return {
        control__btn: true,
        'control__btn--inactive': this.device.telemetry.ptz?.zoom <= this.device.telemetry.ptz?.MinZoom
      }
    },
    zoomText () {
      const countedZoom = countPercents(this.device.telemetry.ptz?.zoom, this.device.telemetry.ptz?.MaxZoom)
      return this.device.telemetry.ptz?.zoom > 1 ? countedZoom : 'ZOOM'
    }
  },
  methods: {
    ...mapActions([
      'sendPTZCommand'
    ]),
    sendZoom (zoomCount) {
      this.sendPTZCommand({ currentContainerId: this.currentUnit.id, currentUnitId: this.device.id, destination: 'unit', command: 'ZOOM', zoom: zoomCount, type: this.type })
    }
  }
}
</script>

<style lang="scss" scoped></style>
