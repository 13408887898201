<template>
  <div class="device" :class="isOpenDeviceClass">
    <div class="device__top" @click.stop="addDevice">
      <div class="device__info">
        <Icon v-if="name === 'SmartCart'" class="icon--smartCart">
          <svg viewBox="0 0 15 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 5V6H12V2H6V3V5ZM4 5V7C4 7.55228 4.44772 8 5 8H13C13.5523 8 14 7.55228 14 7V1C14 0.447715 13.5523 0 13 0H5C4.44772 0 4 0.447715 4 1V3H2C0.895431 3 0 3.89543 0 5V15H1V17C1 18.1046 1.89543 19 3 19H13C14.1046 19 15 18.1046 15 17V12C15 10.8954 14.1046 10 13 10H3C2.63571 10 2.29417 10.0974 2 10.2676V5L4 5ZM1.00977 22C1.00977 20.9 1.89977 20 2.99977 20C4.09977 20 4.99977 20.9 4.99977 22C4.99977 23.1 4.09977 24 2.99977 24C1.89977 24 1.00977 23.1 1.00977 22ZM11.0098 22C11.0098 20.9 11.8998 20 12.9998 20C14.0998 20 14.9998 20.9 14.9998 22C14.9998 23.1 14.0998 24 12.9998 24C11.8998 24 11.0098 23.1 11.0098 22Z"/>
          </svg>
        </Icon>
        <Icon v-if="name === 'Mobile'">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.167 15.833H5.833V4.167h8.334v11.666zm0-15H5.833A1.66 1.66 0 004.167 2.5v15a1.667 1.667 0 001.666 1.667h8.334a1.666 1.666 0 001.666-1.667v-15A1.667 1.667 0 0014.167.833z"/>
          </svg>
        </Icon>
        <span v-if="isOpenDevice" class="device__ttl">{{ name }}</span>
        <span v-else class="device__ttl">Add {{ name }}</span>
      </div>
      <a v-if="isOpenDevice" @click.stop="confirmDelete" class="device__link">Delete</a>
    </div>
    <div class="device__bottom" v-if="isOpenDevice">
      <div class="device__field">
        <Field v-for="device in fields" v-model.trim="device.value" @blur="blur(device)"
         :key="device.id"
         :value="device.value"
         :label="device.label"
         :placeholder="device.placeholder"
         :isRequired="device.isRequired"
         :pattern="device.pattern"
         :isDisabled="device.isDisabled"
         :type="device.type"
         :styleTextBold="device.styleTextBold"
         :isDisabledClass="device.isDisabledClass"
         :isIcon="device.isIcon"
         :bgField="device.bgField"
         :textarea="device.textarea"
         :valid="device.valid"
        >
          <template slot="fieldIcon">
            <button @click="copyValue(device.value)" v-if="name === 'Mobile'" class="field__btn">
              <Icon class="field__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8334 17.5006H6.66675V5.83398H15.8334V17.5006ZM15.8334 4.16732H6.66675C6.22472 4.16732 5.8008 4.34291 5.48824 4.65547C5.17568 4.96803 5.00008 5.39196 5.00008 5.83398V17.5006C5.00008 17.9427 5.17568 18.3666 5.48824 18.6792C5.8008 18.9917 6.22472 19.1673 6.66675 19.1673H15.8334C16.2754 19.1673 16.6994 18.9917 17.0119 18.6792C17.3245 18.3666 17.5001 17.9427 17.5001 17.5006V5.83398C17.5001 5.39196 17.3245 4.96803 17.0119 4.65547C16.6994 4.34291 16.2754 4.16732 15.8334 4.16732ZM13.3334 0.833984H3.33341C2.89139 0.833984 2.46746 1.00958 2.1549 1.32214C1.84234 1.6347 1.66675 2.05862 1.66675 2.50065V14.1673H3.33341V2.50065H13.3334V0.833984Z" fill="#111111"/>
                </svg>
              </Icon>
            </button>
            <button @click="showHelp" v-click-outside="closePopupHelp" v-if="name === 'SmartCart' && !device.isDisabled" class="field__btn">
              <Icon class="field__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="#111111"/>
                </svg>
              </Icon>
            </button>
          </template>
        </Field>
      </div>
    </div>
    <Popup v-if="showPopupHelp" :close="closePopupHelp" class="modal--help">
      <template slot="header">
        <a class="modal__close" @click.prevent="closePopupHelp">
          <Icon class="icon--close">
            <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z"/>
            </svg>
          </Icon>
        </a>
      </template>
      <template slot="body">
        <p class="modal__text">Smart Cart ID is generated by Device during first boot time and saved to
          <a class="modal__url" href="http://mender.vest.switchdock.com/" target="_blank">Mender</a>.
        </p>
        <p class="modal__text">To add Smart Cart device to Unit copy "ID" value from "Device identity" Mender and paste it to "ID" field.</p>
      </template>
    </Popup>
    <Popup v-if="showPopupDelete" class="modal--small">
      <template slot="header">
        <p class="modal__caption">Delete {{ name }} ?</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupDelete">Cancel</a>
          </div>
          <div class="modal__col">
            <Btn class-name="btn btn--white" :action="deleteDevice" @clickAction="deleteDevice" v-click-outside="closePopupDelete">delete device</Btn>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'
import Field from '@/common/field/Field'
import Popup from '@/common/popup/Popup'
import Btn from '@/common/btn/Btn'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'DeviceItem',
  data () {
    return {
      isOpenDevice: false,
      showPopupHelp: false,
      showPopupDelete: false
    }
  },
  props: {
    name: String,
    fields: Array,
    hasDevice: Object
  },
  components: {
    Icon,
    Field,
    Popup,
    Btn
  },
  computed: {
    isOpenDeviceClass () {
      return this.isOpenDevice ? 'device--open' : null
    }
  },
  directives: {
    ClickOutside
  },
  mounted () {
    if (this.hasDevice) {
      this.isOpenDevice = true
    }
  },
  methods: {
    addDevice () {
      this.isOpenDevice = true
      this.$emit('addDevice')
    },
    deleteDevice () {
      this.isOpenDevice = false
      this.showPopupDelete = false
      this.$emit('deleteDevice')
    },
    copyValue (value) {
      navigator.clipboard.writeText(value)
    },
    showHelp () {
      this.showPopupHelp = true
    },
    closePopupHelp () {
      this.showPopupHelp = false
    },
    closePopupDelete () {
      this.showPopupDelete = false
    },
    confirmDelete () {
      this.showPopupDelete = true
    },
    blur (device) {
      this.$emit('blur', device)
    }
  }
}
</script>

<style lang="scss" scoped>
  .device {
    padding: 20px 24px;
    background: #fff;
    border: 2px solid rgba(17, 17, 17, 0.2);
    margin-bottom: 15px;
    transition: border-color 0.2s, box-shadow 0.2s;
    &--open {
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.14), 0 3px 14px rgba(0, 0, 0, 0.12);
      border-color: transparent;
      .device__top {
        cursor: default;
      }
    }
    &__top {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__bottom {
      padding-top: 45px;
    }
    &__info {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 35px;
      }
    }
    &__ttl {
      color: #111111;
      font-size: 16px;
      user-select: none;
    }
    &__link {
      cursor: pointer;
      font-weight: bold;
      color: #111111;
      font-size: 12px;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
</style>
