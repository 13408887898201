<template>
  <div class="loader">
    <Icon v-if="!timer" class="icon--loading">
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2C23.5601 2 27.0402 3.05568 30.0003 5.03354C32.9603 7.0114 35.2674 9.82262 36.6298 13.1117C37.9922 16.4007 38.3487 20.0199 37.6541 23.5116C36.9596 27.0033 35.2453 30.2106 32.7279 32.7279C30.2106 35.2452 27.0033 36.9596 23.5117 37.6541C20.02 38.3487 16.4008 37.9922 13.1117 36.6299C9.82267 35.2675 7.01145 32.9604 5.03358 30.0003C3.05571 27.0402 2.00001 23.5601 2 20.0001" stroke="#D31920" stroke-width="4"/>
      </svg>
    </Icon>
    <template v-else>
      <ReconnectionTimer :timeLimit="60"/>
      <p class="loader__text">
        <slot name="loaderInfo"></slot>
      </p>
      <p class="loader__description">
        <slot name="loaderDescription"></slot>
      </p>
    </template>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'
import ReconnectionTimer from '@/common/reconnection-timer/ReconnectionTimer.vue'

export default {
  name: 'LoaderIcon',
  components: {
    Icon,
    ReconnectionTimer
  },
  props: {
    timer: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .loader {
    &__text {
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      color: #fff;
      margin-top: 45px;
    }
    &__description {
      text-align: center;
      margin-top: 15px;
      color: #fff;
      font-size: 18px;
      font-weight: normal;
    }
  }
</style>
