// import stateMerge from 'vue-object-merge'
import Vue from 'vue'
import recordingLoader from '@/helpers/recordingLoader'
import addFiltersToRequest from '@/helpers/requestParams'

export default {
  namespaced: false,
  state: {
    recordingList: [],
    loading: false,
    filters: {
      unit: null,
      location: null,
      sessionType: null,
      startedDate: null,
      page: 0,
      size: 50
    }
  },
  getters: {
    recordingsLength (state) {
      return state.recordingList.length
    }
  },
  mutations: {
    setRecordingList (state, recordingList) {
      state.recordingList = [...state.recordingList, ...recordingList]
    },
    handleLoading (state, value) {
      state.loading = value
    },
    clearRecordingList (state) {
      state.recordingList = []
    },
    setFilter (state, filterVal) {
      state.filters[filterVal.filter] = filterVal.value
    },
    clearAllFilters (state) {
      state.filters = {
        unit: null,
        location: null,
        sessionType: null,
        startedDate: null,
        page: 0,
        size: 50
      }
    }
  },
  actions: {
    getRecordings ({ commit, rootState, state }) {
      commit('handleLoading', true)
      const filters = addFiltersToRequest(state.filters)
      const recordingsUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording${filters}`
      Vue.prototype.$axios
        .get(recordingsUrl)
        .then(data => {
          commit('setRecordingList', data.data.recordings)
        })
        .catch(error => {
          console.warn(`Recordings loading error: ${error}`)
        })
        .finally(() => commit('handleLoading', false))
    },
    addComment ({ commit, rootState }, { recordingId, note }) {
      const recordingsUrl = `${window.VEST_CONFIG.recordings}${rootState.User.user.user_metadata.organization_id}/recording/${recordingId}`
      Vue.prototype.$axios
        .patch(recordingsUrl, { note })
        .then(() => {
          commit('setDescription', note)
        })
        .catch(error => {
          console.warn(`Comment addition error: ${error}`)
        })
    },
    loadMore ({ commit }) {
      commit('handleLoading', true)
      recordingLoader.getMoreRecordings()
        .then(data => {
          commit('setRecordingList', data.recordings)
        })
        .finally(() => {
          commit('handleLoading', false)
        })
    }
  }
}
