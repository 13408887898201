<template>
  <div :class="[previewClass, previewPrimaryWidth, videoExpandClass]" @mouseup="changeVideoSize()" >
    <LoaderIcon v-if="!device.streams.video.name"/>
    <div class="preview__video" @mouseover="setHoveredIpCameraId(device.uuid)" @mouseout="setHoveredIpCameraId(null)">
      <div v-if="device.uuid !== primaryIpCameraId" class="preview__name">
        <Icon class="icon--unit">
          <svg viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99997 2C11.8565 2 13.637 2.7375 14.9497 4.05025C16.2625 5.36301 17 7.14349 17 9C17 10.8565 16.2625 12.637 14.9497 13.9497C13.637 15.2625 11.8565 16 9.99997 16C8.14345 16 6.36298 15.2625 5.05022 13.9497C3.73747 12.637 2.99997 10.8565 2.99997 9C2.99997 7.14349 3.73747 5.36301 5.05022 4.05025C6.36298 2.7375 8.14345 2 9.99997 2ZM9.99997 4C8.67389 4 7.40212 4.52678 6.46444 5.46447C5.52675 6.40215 4.99997 7.67392 4.99997 9C4.99997 10.3261 5.52675 11.5979 6.46444 12.5355C7.40212 13.4732 8.67389 14 9.99997 14C10.6566 14 11.3068 13.8707 11.9134 13.6194C12.52 13.3681 13.0712 12.9998 13.5355 12.5355C13.9998 12.0712 14.3681 11.52 14.6194 10.9134C14.8706 10.3068 15 9.65661 15 9C15 7.67392 14.4732 6.40215 13.5355 5.46447C12.5978 4.52678 11.3261 4 9.99997 4ZM9.99997 6C10.7956 6 11.5587 6.31607 12.1213 6.87868C12.6839 7.44129 13 8.20435 13 9C13 9.79565 12.6839 10.5587 12.1213 11.1213C11.5587 11.6839 10.7956 12 9.99997 12C9.20432 12 8.44126 11.6839 7.87865 11.1213C7.31604 10.5587 6.99997 9.79565 6.99997 9C6.99997 8.20435 7.31604 7.44129 7.87865 6.87868C8.44126 6.31607 9.20432 6 9.99997 6ZM3.99997 22C3.46954 22 2.96083 21.7893 2.58576 21.4142C2.21068 21.0391 1.99997 20.5304 1.99997 20C1.99997 19.62 2.09997 19.27 2.28997 18.97L4.10997 15.81C5.74673 17.2234 7.83739 18.0008 9.99997 18C12.25 18 14.31 17.17 15.89 15.81L17.71 18.97C17.9 19.27 18 19.62 18 20C18 20.5304 17.7893 21.0391 17.4142 21.4142C17.0391 21.7893 16.5304 22 16 22H3.99997Z" fill="#fff"/>
            <path d="M3.84749 14.2621L4.76355 15.0531C6.21859 16.3096 8.07714 17.0007 9.99962 17M3.84749 14.2621L9.99962 17M3.84749 14.2621L3.24342 15.3109M3.84749 14.2621L3.24342 15.3109M9.99962 17C7.87801 16.9999 5.84332 16.1571 4.34311 14.6569C2.84282 13.1566 1.99997 11.1217 1.99997 9C1.99997 6.87827 2.84282 4.84344 4.34311 3.34315C5.84341 1.84285 7.87824 1 9.99997 1M9.99962 17L9.99997 1M3.24342 15.3109L1.43277 18.4547C1.14461 18.918 0.999969 19.448 0.999969 20C0.999969 20.7957 1.31604 21.5587 1.87865 22.1213C2.44126 22.6839 3.20432 23 3.99997 23H16C16.7956 23 17.5587 22.6839 18.1213 22.1213C18.6839 21.5587 19 20.7957 19 20C19 19.448 18.8553 18.918 18.5672 18.4547L16.7565 15.3109L16.1534 14.2638M3.24342 15.3109L16.1534 14.2638M16.1534 14.2638L15.2376 15.0521M16.1534 14.2638L15.2376 15.0521M15.2376 15.0521C13.8307 16.2631 11.9997 17 9.99997 17C12.1217 17 14.1565 16.1571 15.6568 14.6569C17.1571 13.1566 18 11.1217 18 9C18 6.87827 17.1571 4.84344 15.6568 3.34315C14.1565 1.84285 12.1217 1 9.99997 1M15.2376 15.0521L9.99997 1" stroke="#111111" stroke-opacity="0.1" stroke-width="2"/>
          </svg>
        </Icon>
        <span class="preview__text">{{ currentDeviceName }}</span>
      </div>
      <div v-if="device.uuid !== primaryIpCameraId" class="preview__controls">
        <div v-if="mediaInfoPodLength" class="preview__controls--top">
          <template v-if="!videoExpand">
            <Icon class="icon--expand">
              <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 20V18H5.41L9.91 13.5L8.5 12.09L4 16.59V13H2V20H9ZM13.5 9.91L18 5.41V9H20V2H13V4H16.59L12.09 8.5L13.5 9.91Z" fill="#fff"/>
                <path d="M9 21H10V20V18V17H9H7.82421L10.6171 14.2071L11.3242 13.5L10.6171 12.7929L9.20711 11.3829L8.5 10.6758L7.79289 11.3829L5 14.1758V13V12H4H2H1V13V20V21H2H9ZM12.7929 10.6171L13.5 11.3242L14.2071 10.6171L17 7.82421V9V10H18H20H21V9V2V1H20H13H12V2V4V5H13H14.1758L11.3829 7.79289L10.6758 8.5L11.3829 9.20711L12.7929 10.6171Z" stroke="#111111" stroke-opacity="0.2" stroke-width="2"/>
              </svg>
            </Icon>
            <span class="preview__controls--ttl">Expand</span>
          </template>
          <template v-else>
            <Icon class="icon--collapse">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.49984 20.91L8.99984 16.41V20H10.9998V13H3.99984V15H7.58984L3.08984 19.5L4.49984 20.91Z" fill="#fff"/>
                <path d="M20 10.9998V8.99984H16.41L20.91 4.49984L19.5 3.08984L15 7.58984V3.99984H13V10.9998H20Z" fill="#fff"/>
                <path d="M3.79274 21.6171L4.49984 22.3242L5.20695 21.6171L7.99984 18.8242V20V21H8.99984H10.9998H11.9998V20V13V12H10.9998H3.99984H2.99984V13V15V16H3.99984H5.17563L2.38274 18.7929L1.67563 19.5L2.38274 20.2071L3.79274 21.6171ZM20 11.9998H21V10.9998V8.99984V7.99984H20H18.8242L21.6171 5.20695L22.3242 4.49984L21.6171 3.79274L20.2071 2.38274L19.5 1.67563L18.7929 2.38274L16 5.17563V3.99984V2.99984H15H13H12V3.99984V10.9998V11.9998H13H20Z" stroke="#111111" stroke-opacity="0.2" stroke-width="2"/>
              </svg>
            </Icon>
            <span class="preview__controls--ttl">Collapse</span>
          </template>
        </div>
        <a v-if="amIHost" class="preview__controls--close" @click.stop="removeCamDevice" v-tooltip="`Delete video`">
          <Icon class="icon--close">
            <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z"/>
            </svg>
          </Icon>
        </a>
        <div class="preview__controls--bottom">
          <a class="preview__controls-btn" @click.stop="clickAction" v-tooltip="`Make primary`">
            <Icon class="icon--primary">
              <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="white"/>
                <path d="M11.0002 18H13.0002L13.0002 14.41L20.5002 21.91L21.9102 20.5L14.4102 13H18.0002L18.0002 11L11.0002 11L11.0002 18Z" fill="#111111"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29 11V29L11 29L11 21H13L13 27H27V13L21 13V11L29 11Z" fill="#111111"/>
              </svg>
            </Icon>
          </a>
        </div>
      </div>
      <slot name="video"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/common/icon/Icon'
import LoaderIcon from '@/common/loader-icon/LoaderIcon'
import { VTooltip } from 'v-tooltip'
import { mapGetters, mapActions } from 'vuex'

Vue.directive('tooltip', VTooltip)

export default {
  name: 'VideoPodView',
  props: ['device'],
  data () {
    return {
      videoExpand: false
    }
  },
  components: {
    Icon,
    LoaderIcon
  },
  computed: {
    ...mapGetters([
      'mediaInfoPod',
      'currentPod',
      'primaryIpCameraId',
      'amIHost'
    ]),
    previewPrimaryWidth () {
      return this.device.uuid === this.primaryIpCameraId && !this.mediaInfoPodLength ? 'preview--medium' : null
    },
    previewClass () {
      return this.device.uuid === this.primaryIpCameraId ? 'preview--primary' : this.device.uuid !== this.primaryIpCameraId && this.mediaInfoPodLength ? 'preview' : 'preview--multi'
    },
    videoExpandClass () {
      return this.videoExpand && this.device.uuid !== this.primaryIpCameraId && this.mediaInfoPodLength ? 'preview--expand' : null
    },
    mediaInfoPodLength () {
      return this.mediaInfoPod.length <= 2
    },
    currentDevice () {
      return this.currentPod.devices.find(device => device.id === this.device.uuid)
    },
    currentDeviceName () {
      return `${this.currentDevice.meta.orientation}-${this.currentDevice.meta.position}`
    }
  },
  methods: {
    ...mapActions([
      'setHoveredIpCameraId'
    ]),
    changeVideoSize () {
      if (this.device.uuid !== this.primaryIpCameraId && this.mediaInfoPodLength) {
        this.videoExpand = !this.videoExpand
      }
    },
    clickAction () {
      this.videoExpand = false
      this.$emit('clickAction')
    },
    removeCamDevice () {
      this.$emit('removeCamDevice')
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 192px;
    height: 108px;
    border: 2px solid transparent;
    transition: border-color 0.2s;
    z-index: 6;
    &:hover {
      border-color: #fff;
      .preview__controls {
        opacity: 1;
      }
      .preview__name {
        opacity: 0;
      }
    }
    &--primary {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      z-index: 1;
      &.preview--medium {
        width: calc(100% - 360px);
      }
    }
    &--expand {
      width: 384px;
      height:216px;
    }
    &__joystick {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: 15px;
      z-index: 5;
    }
    &__controls--close {
      display: flex;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background: rgba(17, 17, 17, 0.1);
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
      .icon--close {
        fill: #fff;
        margin: auto;
      }
    }
    &--multi {
      flex: 1;
      border: 2px solid #fff;
      border-top: 2px solid transparent;
      z-index: 2;
      transition: box-shadow 0.2s;
      min-height: 20%;
      &:hover {
        z-index: 3;
        border-top-color: #fff;
        box-shadow: 1px -1px 1px 6px #fff;
        .preview__controls {
          opacity: 1;
        }
      }
    }
    &__video {
      height: 100%;
      background: #000;
      position: relative;
      .player {
        height: 100%;
      }
    }
    &__name {
      display: flex;
      align-items: center;
      position: absolute;
      top: 20px;
      left: 20px;
      transition: opacity 0.2s;
      color: #fff;
    }
    &__text {
      margin-left: 10px;
      color: #fff;
      font-weight: 600 ;
      font-size: 8px;
      letter-spacing: 1px;
      text-transform: uppercase;
      -webkit-text-stroke: 2px rgba(17, 17, 17, 0.1);
    }
    &__controls {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 100%;
      opacity: 0;
      width: 100%;
      transition: opacity 0.2s;
      &--top {
        position: absolute;
        display: flex;
        align-items: center;
        top: 15px;
        left: 15px;
      }
      &--bottom {
        position: absolute;
        bottom: 15px;
        left: 15px;
      }
      &--ttl {
        margin-left: 10px;
        color: #fff;
        font-weight: 600 ;
        font-size: 8px;
        text-transform: uppercase;
        letter-spacing: 1px;
        -webkit-text-stroke: 2px rgba(17, 17, 17, 0.1);
      }
      &-btn {
        display: block;
        cursor: pointer;
      }
    }
  }
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
