<template>
  <div class="units">
    <div class="camera__loader">
      <LoaderIcon v-if="loading"/>
    </div>
    <div class="tabs">
      <div class="tabs__head">
        <ul class="tabs__nav">
          <li class="tabs__item">
            <a class="tabs__link" @click.prevent="setActive('stanchions')" :class="{ active: isActive('stanchions') }">
              <span class="tabs__caption">Stanchions</span>
              <span class="tabs__length">{{ formattedUnits.length }}</span>
            </a>
          </li>
          <li class="tabs__item">
            <a class="tabs__link" @click.prevent="setActive('edge')" :class="{ active: isActive('edge') }">
              <span class="tabs__caption">Surveillance</span>
              <span class="tabs__length">{{ mod.length }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="tabs__content">
        <transition name="fade">
          <div v-if="isActive('stanchions')" class="tabs__wrap">
            <v-table :hideSortIcons="true" class="table" :data="formattedUnits">
              <thead slot="head">
                <tr>
                  <v-th :sortKey="sortByStatus" class="table__status-sort" default-sort="asc">
                    <span class="table__caption table__caption--status">
                      <Icon class="icon--sort">
                        <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.16669 0.333328H7.83336V10.3333L12.4167 5.74999L13.6 6.93333L7.00002 13.5333L0.400024 6.93333L1.58336 5.74999L6.16669 10.3333V0.333328Z"/>
                        </svg>
                      </Icon>
                    </span>
                  </v-th>
                  <v-th sortKey="name">
                    <span class="table__caption">Name</span>
                  </v-th>
                  <th>
                    <span class="table__caption">Devices</span>
                  </th>
                  <v-th sortKey="meta.location">
                    <span class="table__caption">Location</span>
                  </v-th>
                  <th></th>
                </tr>
              </thead>
              <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="unit in displayData"
                  :key="unit.id"
                  :class="unit.session && unit.session.status === 'IN_SESSION' || unit.smartHands ? `table__session`: null"
                  @click.prevent="showUnitDetails($event, unit.id)">
                  <td class="table__td--status">
                    <Icon v-if="unit.session && unit.session.status === 'IN_SESSION'" class="icon--session">
                      <svg viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1667 14.2083V16.9167H4.83334V14.2083C4.83334 13.0583 6.7 12.125 9 12.125C11.3 12.125 13.1667 13.0583 13.1667 14.2083ZM9 3.58333C10.4366 3.58333 11.8143 4.15402 12.8302 5.16984C13.846 6.18566 14.4167 7.56341 14.4167 9C14.4167 10.0417 14.125 11.0167 13.6167 11.8417L12.3333 10.7C12.6 10.1917 12.75 9.60833 12.75 9C12.75 6.91667 11.0833 5.25 9 5.25C6.91667 5.25 5.25 6.91667 5.25 9C5.25 9.60833 5.4 10.1917 5.66667 10.7L4.38334 11.8417C3.875 11.0167 3.58334 10.0417 3.58334 9C3.58334 7.56341 4.15402 6.18566 5.16984 5.16984C6.18566 4.15402 7.56342 3.58333 9 3.58333ZM9 0.25C11.3206 0.25 13.5462 1.17187 15.1872 2.81282C16.8281 4.45376 17.75 6.67936 17.75 9C17.75 10.9 17.1417 12.6583 16.1167 14.0917L14.8667 12.9667C15.6333 11.8333 16.0833 10.4667 16.0833 9C16.0833 7.12138 15.3371 5.31971 14.0087 3.99133C12.6803 2.66294 10.8786 1.91667 9 1.91667C7.12139 1.91667 5.31971 2.66294 3.99133 3.99133C2.66295 5.31971 1.91667 7.12138 1.91667 9C1.91667 10.4667 2.36667 11.8333 3.13334 12.9667L1.88334 14.0917C0.819563 12.6071 0.248298 10.8263 0.250004 9C0.250004 6.67936 1.17188 4.45376 2.81282 2.81282C4.45376 1.17187 6.67936 0.25 9 0.25ZM9 6.91667C9.55254 6.91667 10.0824 7.13616 10.4731 7.52686C10.8638 7.91756 11.0833 8.44747 11.0833 9C11.0833 9.55253 10.8638 10.0824 10.4731 10.4731C10.0824 10.8638 9.55254 11.0833 9 11.0833C8.44747 11.0833 7.91757 10.8638 7.52687 10.4731C7.13616 10.0824 6.91667 9.55253 6.91667 9C6.91667 8.44747 7.13616 7.91756 7.52687 7.52686C7.91757 7.13616 8.44747 6.91667 9 6.91667Z"/>
                      </svg>
                    </Icon>
                    <span v-else :class="unitStatusClass(currentCamUnit(unit.devices), currentCamMobile(unit.devices))" class="table__status"></span>
                  </td>
                  <td class="table__td--name">
                    <span class="table__name">{{ unit.name }}</span>
                    <span v-if="unit.smartHands" class="table__smarthands">smarthands</span>
                  </td>
                  <td class="table__td--devices">
                    <div class="table__devices">
                      <div class="table__devices--col">
                        <TelemetryIcon name="Cam Unit" :tooltipShow="true" :status="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).status ? currentCamUnit(unit.devices).status : `OFFLINE`" class="icon--small">
                          <svg viewBox="0 0 18 11" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M.667 2a2 2 0 012-2h12.666a2 2 0 012 2v6a2 2 0 01-1.666 1.972v.861h-2.5V10H4.833v.833h-2.5v-.86A2 2 0 01.667 8V2zm1.666.5h13.334v5H2.333v-5zM6.5 5H4v.833h2.5V5zm1.667 0h2.5v.833h-2.5V5zM14 4.167h-1.667v1.666H14V4.167z"/>
                          </svg>
                        </TelemetryIcon>
                      </div>
                      <div class="table__devices--col">
                        <TelemetryIcon name="Camera" :tooltipShow="true" :status="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).telemetry && currentCamUnit(unit.devices).telemetry.camera && currentCamUnit(unit.devices).status !== 'OFFLINE' ? currentCamUnit(unit.devices).telemetry.camera.status : `DISCONNECTED`" class="icon--small">
                          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 1.667a5.833 5.833 0 110 11.666 5.833 5.833 0 010-11.666zm0 1.666a4.167 4.167 0 100 8.334 4.167 4.167 0 000-8.334zM10 5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zM5 18.333a1.667 1.667 0 01-1.667-1.666c0-.317.084-.609.242-.859l1.517-2.633A7.506 7.506 0 0010 15a7.506 7.506 0 004.908-1.825l1.517 2.633c.158.25.242.542.242.859A1.667 1.667 0 0115 18.333H5z"/>
                          </svg>
                        </TelemetryIcon>
                      </div>
                      <div class="table__devices--col">
                        <v-popover trigger="hover" placement="top">
                          <TelemetryIcon name="Mobile" :tooltipShow="false" :battery="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry ? currentCamMobile(unit.devices).telemetry.batteryLvl : 'DISCONNECTED'" :status="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).status ? currentCamMobile(unit.devices).status : `OFFLINE`" class="icon--small">
                            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.167 15.833H5.833V4.167h8.334v11.666zm0-15H5.833A1.66 1.66 0 004.167 2.5v15a1.667 1.667 0 001.666 1.667h8.334a1.666 1.666 0 001.666-1.667v-15A1.667 1.667 0 0014.167.833z"/>
                            </svg>
                          </TelemetryIcon>
                          <template slot="popover">
                            <div class="popover__outer">
                              <span class="popover__unit">Mobile</span>
                              <Battery v-if="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry && currentCamMobile(unit.devices).status !== 'OFFLINE'" :status="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).status" :battery="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry.batteryLvl" :showBatteryLevel="true"/>
                              <span v-else>unavailable</span>
                            </div>
                          </template>
                        </v-popover>
                      </div>
                      <div class="table__devices--col">
                        <v-popover trigger="hover" placement="top">
                          <TelemetryIcon name="Gimbal" :tooltipShow="false" :battery="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry && currentCamMobile(unit.devices).status !== 'OFFLINE' ? currentCamMobile(unit.devices).telemetry.gimbal.batteryLvl : 'DISCONNECTED'" :status="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry && currentCamMobile(unit.devices).status !== 'OFFLINE' ? currentCamMobile(unit.devices).telemetry.gimbal.status : `DISCONNECTED`" class="icon--small">
                            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.833.833A.833.833 0 005 1.667v2.5H3.333v-.834h-2.5v3.334h.834v2.028c0 .585.307 1.128.809 1.429l3.564 2.138c.26.156.555.238.858.238h1.435v5.833c0 .46.373.834.834.834h1.666c.46 0 .834-.373.834-.834v-6.666a.833.833 0 00-.834-.834H6.898L3.333 8.695V5.833H5V7.5c0 .46.373.833.833.833h10c.46 0 .834-.373.834-.833V1.667a.833.833 0 00-.834-.834h-10zm.834 2.5a.833.833 0 100-1.666.833.833 0 000 1.666z"/>
                            </svg>
                          </TelemetryIcon>
                          <template slot="popover">
                            <div class="popover__outer">
                              <span class="popover__unit">Gimbal</span>
                              <Battery v-if="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry && currentCamMobile(unit.devices).status !== 'OFFLINE' && currentCamMobile(unit.devices).telemetry.gimbal.status === 'CONNECTED'" :status="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry.gimbal.status" :battery="currentCamMobile(unit.devices) && currentCamMobile(unit.devices).telemetry.gimbal.batteryLvl" :showBatteryLevel="true"/>
                              <span v-else>unavailable</span>
                            </div>
                          </template>
                        </v-popover>
                      </div>
                      <div class="table__devices--col">
                        <v-popover trigger="hover" placement="top">
                          <TelemetryIcon name="Headset" :tooltipShow="false" :battery="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).telemetry && currentCamUnit(unit.devices).telemetry.headset ? currentCamUnit(unit.devices).telemetry.headset.batteryLvl : 'DISCONNECTED'" :status="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).telemetry && currentCamUnit(unit.devices).telemetry.headset && currentCamUnit(unit.devices).status !== 'OFFLINE' ? currentCamUnit(unit.devices).telemetry.headset.status : `DISCONNECTED`" class="icon--small">
                            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10 .833a7.469 7.469 0 00-7.5 7.5v5.834a2.5 2.5 0 002.5 2.5h2.5V10H4.167V8.333a5.833 5.833 0 1111.666 0V10H12.5v6.667h3.333v.833H10v1.667h5a2.5 2.5 0 002.5-2.5V8.333c0-4.166-3.358-7.5-7.5-7.5z"/>
                            </svg>
                          </TelemetryIcon>
                          <template slot="popover">
                            <div class="popover__outer">
                              <span class="popover__unit">Headset</span>
                              <Battery v-if="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).telemetry && currentCamUnit(unit.devices).telemetry.headset && currentCamUnit(unit.devices).telemetry.headset.status === 'CONNECTED'" :status="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).telemetry.headset.status" :battery="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).telemetry.headset.batteryLvl" :showBatteryLevel="true"/>
                              <span v-else>unavailable</span>
                            </div>
                          </template>
                        </v-popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="table__location">{{ unit.meta.location }}</span>
                  </td>
                  <td class="table__btn">
                    <Btn v-if="unit.session && unit.session.status === 'IN_SESSION'"
                      class-name="btn--red btn--small"
                      @clickAction="openSessionRoom(unit.name)">join</Btn>
                    <Btn v-else-if="currentCamUnit(unit.devices) && currentCamUnit(unit.devices).status === 'ONLINE'"
                      class-name="btn--white btn--small"
                      @clickAction="startSession(unit, currentCamUnit(unit.devices).id)">Start</Btn>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="isActive('edge')" class="tabs__wrap">
            <v-table :hideSortIcons="true" class="table" :data="mod">
              <thead slot="head">
                <tr>
                  <v-th :sortKey="sortByStatus" class="table__status-sort" default-sort="asc">
                    <span class="table__caption table__caption--status">
                      <Icon class="icon--sort">
                        <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.16669 0.333328H7.83336V10.3333L12.4167 5.74999L13.6 6.93333L7.00002 13.5333L0.400024 6.93333L1.58336 5.74999L6.16669 10.3333V0.333328Z"/>
                        </svg>
                      </Icon>
                    </span>
                  </v-th>
                  <v-th sortKey="name">
                    <span class="table__caption">Data center</span>
                  </v-th>
                  <th>
                    <span class="table__caption">Type</span>
                  </th>
                  <v-th sortKey="meta.location">
                    <span class="table__caption">Location</span>
                  </v-th>
                </tr>
              </thead>
              <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="unit in displayData" :key="unit.id" :class="unit.session && unit.session.status === 'IN_SESSION' ? `table__session` : null" @click.prevent="showPodList(unit)">
                  <td class="table__td--status">
                    <Icon v-if="podSessionStatusClass(unit)" class="icon--session">
                      <svg viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1667 14.2083V16.9167H4.83334V14.2083C4.83334 13.0583 6.7 12.125 9 12.125C11.3 12.125 13.1667 13.0583 13.1667 14.2083ZM9 3.58333C10.4366 3.58333 11.8143 4.15402 12.8302 5.16984C13.846 6.18566 14.4167 7.56341 14.4167 9C14.4167 10.0417 14.125 11.0167 13.6167 11.8417L12.3333 10.7C12.6 10.1917 12.75 9.60833 12.75 9C12.75 6.91667 11.0833 5.25 9 5.25C6.91667 5.25 5.25 6.91667 5.25 9C5.25 9.60833 5.4 10.1917 5.66667 10.7L4.38334 11.8417C3.875 11.0167 3.58334 10.0417 3.58334 9C3.58334 7.56341 4.15402 6.18566 5.16984 5.16984C6.18566 4.15402 7.56342 3.58333 9 3.58333ZM9 0.25C11.3206 0.25 13.5462 1.17187 15.1872 2.81282C16.8281 4.45376 17.75 6.67936 17.75 9C17.75 10.9 17.1417 12.6583 16.1167 14.0917L14.8667 12.9667C15.6333 11.8333 16.0833 10.4667 16.0833 9C16.0833 7.12138 15.3371 5.31971 14.0087 3.99133C12.6803 2.66294 10.8786 1.91667 9 1.91667C7.12139 1.91667 5.31971 2.66294 3.99133 3.99133C2.66295 5.31971 1.91667 7.12138 1.91667 9C1.91667 10.4667 2.36667 11.8333 3.13334 12.9667L1.88334 14.0917C0.819563 12.6071 0.248298 10.8263 0.250004 9C0.250004 6.67936 1.17188 4.45376 2.81282 2.81282C4.45376 1.17187 6.67936 0.25 9 0.25ZM9 6.91667C9.55254 6.91667 10.0824 7.13616 10.4731 7.52686C10.8638 7.91756 11.0833 8.44747 11.0833 9C11.0833 9.55253 10.8638 10.0824 10.4731 10.4731C10.0824 10.8638 9.55254 11.0833 9 11.0833C8.44747 11.0833 7.91757 10.8638 7.52687 10.4731C7.13616 10.0824 6.91667 9.55253 6.91667 9C6.91667 8.44747 7.13616 7.91756 7.52687 7.52686C7.91757 7.13616 8.44747 6.91667 9 6.91667Z"/>
                      </svg>
                    </Icon>
                    <span v-else :class="modStatusClass(availableDevicesOfPod(unit))" class="table__status"></span>
                  </td>
                  <td class="table__td--name">
                    <span class="table__name">{{ unit.name }}</span>
                  </td>
                  <td class="table__td--devices">
                    <span :class="modStatusClass(availableDevicesOfPod(unit))" class="table__type">BUILDING {{ unit.meta.type.split('MOD')[1] }}</span>
                  </td>
                  <td>
                    <span class="table__location">{{ unit.meta.location }}</span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </transition>
      </div>
    </div>
    <PopupUnit v-if="showPopupUnit" :camMobile="currentCamMobile(currentUnit.devices)" :camUnit="currentCamUnit(currentUnit.devices)" :unit="currentUnit" :close="closePopupUnit">
      <template slot="buttons">
        <Btn v-if="currentUnit.session && currentUnit.session.status === 'IN_SESSION'" class-name="btn--red btn--full-width" @clickAction="openSessionRoom(currentUnit.name)">join</Btn>
        <Btn v-else-if="currentCamUnit(currentUnit.devices) && currentCamUnit(currentUnit.devices).status === 'ONLINE'" class-name="btn--full-width" @clickAction="startSession(currentUnit, currentCamUnit(currentUnit.devices).id)">Start</Btn>
      </template>
    </PopupUnit>
    <PopupPodsList v-if="showPopupPods" :close="closePopupPods"/>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import SmartTable from 'vuejs-smart-table'
import Icon from '@/common/icon/Icon'
import PopupUnit from '@/common/popup-unit-details/PopupUnitDetails'
import TelemetryIcon from '@/common/telemetry-icon/TelemetryIcon'
import Btn from '@/common/btn/Btn'
import Battery from '@/common/battery/Battery'
import LoaderIcon from '@/common/loader-icon/LoaderIcon'
import PopupPodsList from '@/components/popup-pods-list/PopupPodsList'

Vue.use(SmartTable)

export default {
  name: 'UnitsTable',
  data () {
    return {
      currentUnit: null,
      showPopupUnit: false,
      showPopupPods: false,
      loading: false,
      activeItem: 'stanchions'
    }
  },
  components: {
    Icon,
    TelemetryIcon,
    Btn,
    LoaderIcon,
    Battery,
    PopupUnit,
    PopupPodsList
  },
  computed: {
    ...mapGetters([
      'units',
      'mod',
      'currentMod'
    ]),
    unitStatusClass () {
      return (statusCamUnit, statusCamMobile) => (statusCamUnit?.status && statusCamUnit?.status === 'ONLINE') || (statusCamMobile?.status && statusCamMobile?.status === 'ONLINE') ? 'table__status--online' : 'table__status--offline'
    },
    modStatusClass: function () {
      return (availableDevicesOfPod) => availableDevicesOfPod ? 'table__status--online' : 'table__status--offline'
    },
    podSessionStatusClass: function () {
      return (unit) => unit.childContainers.find(pod => pod.status === 'IN_SESSION')
    },
    availableDevicesOfPod () {
      return (unit) => unit.childContainers.find(pod => pod.statusDevicesOfPod === 'ONLINE')
    },
    formattedUnits () {
      const formattedUnits = this.units.filter(i => i.fullInfo === undefined || i.fullInfo)

      return formattedUnits
    },
    currentCamUnit () {
      return (devices) => devices !== undefined ? devices.find(device => device.type === 'CAM_UNIT') : undefined
    },
    currentCamMobile () {
      return (devices) => devices !== undefined ? devices.find(device => device.type === 'CAM_MOBILE') : undefined
    }
  },
  methods: {
    ...mapActions([
      'unsubscribeFromTopic',
      'sendStartSession',
      'subscribeJoinSession',
      'subscribeToTopic',
      'setCurrentSession',
      'setSessionStatus',
      'setCurrentMod'
    ]),
    sortByStatus ({ session, smartHands, devices, childContainers }) {
      let unitStatus
      if (devices) {
        unitStatus = this.unitStatusClass(this.currentCamUnit(devices), this.currentCamMobile(devices))
      }
      if (childContainers) {
        const pod = childContainers.find(pod => pod.devices.find(device => device.status === 'ONLINE'))
        unitStatus = this.modStatusClass(pod)
      }
      let isUnitOnline = null
      unitStatus === 'table__status--online' ? isUnitOnline = true : isUnitOnline = false
      const sessionStatus = session?.status ? session.status : 'NO_SESSION'
      const prioritySH = smartHands ? 3 : 0
      if (isUnitOnline) {
        switch (sessionStatus) {
          case 'IN_SESSION':
            return 4 - prioritySH
          default:
            return 5 - prioritySH
        }
      } else {
        return 6 - prioritySH
      }
    },
    openSessionRoom (name, isStartSession) {
      if (!isStartSession) {
        this.currentUnit = this.units.find(unit => unit.name === name)
        this.setCurrentSession(this.currentUnit.session)
        this.subscribeToTopic(this.currentUnit.session.destination)
      }

      this.$router.push({
        name: 'SessionRoom',
        params: {
          unitName: name
        }
      })
    },
    showUnitDetails (event, id) {
      if (event.target.nodeName === 'BUTTON') return
      this.currentUnit = this.units.find(unit => unit.id === id)
      this.showPopupUnit = true
    },
    showPodList (mod) {
      this.setCurrentMod(mod)
      this.showPopupPods = true
    },
    closePopupUnit (event) {
      event.preventDefault()
      event.stopPropagation()

      this.showPopupUnit = false
    },
    closePopupPods (event) {
      event.preventDefault()
      event.stopPropagation()

      this.showPopupPods = false
    },
    async startSession (unit, camUnitId) {
      const subscribeJoinSession = this.subscribeJoinSession()
      const sessionType = unit.smartHands ? 'SMARTHANDS' : null
      this.sendStartSession({ currentUnitId: unit.id, currentCamUnitId: camUnitId, sessionType: sessionType })
      const { topic, tick } = await subscribeJoinSession

      this.currentUnit = unit
      this.setSessionStatus({ id: unit.id, session: tick.session })
      this.openSessionRoom(unit.name, true)

      this.setCurrentSession(tick.session)
      this.subscribeToTopic(tick.session.destination)
      this.unsubscribeFromTopic(topic)
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    }
  }
}
</script>

<style lang="scss" scoped>
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  .units {
    padding: 32px 0;
  }
  .vt-sort:after{
    vertical-align: middle;
    display: inline-block;
    width: 13px;
    height: 15px;
    content: none;
  }
  .vt-asc {
    .table__caption {
      &:after{
        content: url('./arrowDown.svg');
      }
    }
  }
  .vt-desc {
    .table__caption {
      &:after{
        content: url('./arrowDown.svg');
      }
    }
  }
  .tabs {
    &__head {
      border-bottom: 1px solid rgba(17, 17, 17, 0.1)
    }
    &__nav {
      display: flex;
    }
    &__item {
      padding: 0 32px;
    }
    &__caption {
      font-family: 'CenturyGothic-Bold';
      color: rgba(17,17,17, 0.6);
      font-size: 24px;
      margin-right: 8px;
      transition: color 0.2s;
    }
    &__length {
      line-height: 16px;
      font-size: 16px;
      color: rgba(17,17,17, 0.6);
    }
    &__content {
      padding: 0 32px;
      position: relative;
    }
    &__wrap {
      position: absolute;
      left: 32px;
      top: 0;
      width: calc(100% - 64px)
    }
    &__link {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 4px solid transparent;
      transition: border-color 0.2s;
      &:hover {
        .tabs__caption {
          color: #111111;
        }
      }
      &.active {
        border-color: #D31920;
        .tabs__caption {
          color: #111111;
        }
      }
    }
  }
</style>
