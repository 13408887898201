import JoystickElement from './JoystickElement'
import JoystickShaft from './JoystickShaft'

export default class Joystick {
  constructor (base, shaft) {
    this.state = 'inactive'
    this.base = new JoystickElement(base)
    this.shaft = new JoystickShaft(shaft)
    this.boundary = this.base.rect.radius * 0.75
    this.moveInterval = null

    this.onactivate = function () { }
    this.ondeactivate = function () { }
    this.ondrag = function () { }

    this.activate = this.activate.bind(this)
    this.deactivate = this.deactivate.bind(this)
    this.drag = this.drag.bind(this)
  }

  static get ANIMATION_TIME () {
    return 0.1 // in seconds
  }

  attachEvents () {
    this.base.element.addEventListener('pointerdown', this.activate, false)
    this.base.element.addEventListener('touchstart', this.activate, false)
    document.addEventListener('pointerup', this.deactivate, false)
    document.addEventListener('touchend', this.deactivate, false)
    document.addEventListener('pointermove', this.drag, false)
    document.addEventListener('touchmove', this.drag, false)

    return this
  }

  detachEvents () {
    this.base.element.removeEventListener('pointerdown', this.activate, false)
    this.base.element.removeEventListener('touchstart', this.activate, false)
    document.removeEventListener('pointerup', this.deactivate, false)
    document.removeEventListener('touchend', this.deactivate, false)
    document.removeEventListener('pointermove', this.drag, false)
    document.removeEventListener('touchmove', this.drag, false)

    this.deactivate()

    return this
  }

  activate () {
    // in case heights have changed
    this.base.updateRect()
    this.shaft.updateRect()

    this.state = 'active'
    this.base.element.classList.add('active')

    if (typeof this.onactivate === 'function') {
      this.onactivate()
    }

    if (this.moveInterval == null) {
      this.moveInterval = window.setInterval(() => {
        this.ondrag()
      }, 100)
    }

    return this
  }

  deactivate () {
    this.state = 'inactive'
    this.base.element.classList.remove('active')
    this.moveInterval = null
    this.shaft.move(
      this.shaft.current.vector,
      this.shaft.original.vector,
      this.constructor.ANIMATION_TIME,
      () => {
        this.shaft.element.removeAttribute('style')
        this.shaft.current = this.shaft.original

        if (typeof this.ondeactivate === 'function') {
          this.ondeactivate()
        }
      }
    )

    return this
  }

  drag (e) {
    if (this.state !== 'active') {
      return this
    }
    e.preventDefault()
    this.shaft.move(

      this.shaft.original.vector,
      this.shaft.clamp(e.pageX, e.pageY, this.boundary),
      0,
      () => {
        if (typeof this.ondrag === 'function') {
          // this.ondrag();
        }
      }
    )
    return this
  }
}
