const DeviceTypes = ['CAM_UNIT', 'CAM_MOBILE', 'VEST_CLIENT', 'AUDIO_STREAM_DEVICE', 'IP_CAMERA']

export default {
  namespaced: true,
  actions: {
    startSession ({ state, rootState }, inviteList) {
      this.dispatch('sendMessage', {
        topic: rootState.UnitsTelemetry.topics.getForSMCommand({ commandName: 'START_SESSION' }),
        message: {
          invite: inviteList.map(({ deviceType, unitId, deviceId }) => {
            if (!DeviceTypes.includes(deviceType)) {
              throw new Error('Unknown device type')
            }
            return [
              deviceType,
              rootState.User.user.user_metadata.organization_id,
              unitId,
              deviceId
            ].join(':')
          })
        }
      })
    },
    sendCommand ({ dispatch, state, rootState }, { deviceType, unitId, deviceId, commandName }) {
      const publishersKey = commandName === 'INVITE' ? 'invite' : 'publishers'
      this.dispatch('sendMessage', {
        topic: rootState.UnitsTelemetry.topics.getForSMCommand({ commandName }),
        message: {
          sessionId: rootState.UnitsTelemetry.currentSession.id,
          [publishersKey]: [[
            deviceType,
            rootState.User.user.user_metadata.organization_id,
            unitId,
            deviceId
          ].join(':')]
        }
      })
    }
  }
}
