<template>
  <div class="camera">
    <div class="camera__wrap">
      <div class="camera__preview">
        <VideoView v-show="mediaInfo.unit.streams.video.name"
          videoType="camUnit"
          name="PTZ Cam"
          :streams="mediaInfo.unit.streams.video.name"
          :currentUnit="currentUnit"
          :camUnit="camUnit"
          :primary="video.unit.streams.video.statusPrimary"
          @clickAction="makeVideoPrimary"
        >
          <template slot="video">
            <KurentoPublisher :streamName="publishedStreamName" :currentSession="currentSession" :audioOnly="true"/>
            <KurentoPlayer
              v-if="mediaInfo.unit.streams.video.name"
              :audioOnly="false"
              :streamName="mediaInfo.unit.streams.video.name"
              :currentSession="currentSession"
              :ptzFeed="true"
              :volume="volume"
              :publisherUuid="camUnitId.id"
              :elementId="camUnitId.id"/>
          </template>
        </VideoView>
        <VideoView v-show="mediaInfo.mobile.streams.video.name"
          videoType="mobile"
          name="Mobile"
          :streams="mediaInfo.mobile.streams.video.name"
          :currentUnit="currentUnit"
          :camMobile="camMobile"
          :primary="video.mobile.streams.video.statusPrimary"
          @clickAction="makeVideoPrimary">
          <template slot="video">
            <KurentoPlayer
              :class="`player--mobile`"
              :audioOnly="false"
              v-if="mediaInfo.mobile.streams.video.name"
              :streamName="mediaInfo.mobile.streams.video.name"
              :currentSession="currentSession"
              :volume="volume"
              :publisherUuid="camMobileId.id"
              :elementId="camMobileId.id"/>
          </template>
        </VideoView>
      </div>
      <template v-if="camUnit && camUnit.telemetry && camUnit.telemetry.ptz && mediaInfo.unit.streams.video.name && amIHost">
        <PtzClickOverlay v-if="video.unit.streams.video.statusPrimary" :currentUnit="currentUnit" :device="camUnit" type="CAM_UNIT"/>
        <PtzControls v-if="video.unit.streams.video.statusPrimary" :currentUnit="currentUnit" :device="camUnit" type="CAM_UNIT" class="camera__ptz-controls"/>
      </template>
      <KurentoPlayer
        v-for="publisher in publishersWithAudioStream"
        v-bind:key="publisher.streams.audio.name"
        :audioOnly="true"
        :streamName="publisher.streams.audio.name"
        :publisherUuid="publisher.uuid"
        :currentSession="currentSession"
        :volume="volume"
        :elementId="publisher.uuid"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import KurentoPublisher from '@/components/kurento-publisher/KurentoPublisher'
import KurentoPlayer from '@/components/kurento-player/KurentoPlayer'
import PtzControls from '@/components/ptz-controls/PtzControls'
import VideoView from '@/components/video-view/VideoView'
import PtzClickOverlay from '@/components/ptz-click-overlay/PtzClickOverlay'
import { showErrorToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'

export default {
  name: 'CameraView',
  props: ['currentUnit', 'camMobile', 'camUnit'],
  data () {
    return {
      video: {
        unit: {
          streams: {
            video: {
              statusPrimary: this.camUnit ? this.camUnit.status === 'ONLINE' : false,
              name: null
            }
          }
        },
        mobile: {
          streams: {
            video: {
              statusPrimary: this.camUnit ? this.camUnit.status !== 'ONLINE' : true,
              name: null
            }
          }
        }
      }
    }
  },
  components: {
    KurentoPublisher,
    KurentoPlayer,
    PtzControls,
    VideoView,
    PtzClickOverlay
  },
  computed: {
    ...mapGetters([
      'currentUnitName',
      'startSessionCamUnit',
      'startSessionCamMobile',
      'currentSession',
      'user',
      'publishers',
      'mediaInfo',
      'volume',
      'publishedStreamName',
      'amIHost'
    ]),
    camUnitId: function () {
      return this.currentUnit.devices.find(item => item.type === 'CAM_UNIT')
    },
    camMobileId: function () {
      return this.currentUnit.devices.find(item => item.type === 'CAM_MOBILE')
    },
    publishersWithAudioStream: function () {
      return this.publishers.filter(itm => itm.streams.audio?.name)
    },
    camUnitStatus () {
      return this.camUnit ? this.camUnit.status : null
    },
    isUnavailablePtzControls () {
      return (this.mediaInfo.unit.streams.video.name && !this.camUnit && !this.camUnit.telemetry && this.amIHost) || (this.mediaInfo.unit.streams.video.name && this.camUnit && this.camUnit.telemetry && !this.camUnit.telemetry.ptz && this.amIHost)
    }
  },
  methods: {
    ...mapActions([
      'createStreamName',
      'deleteSessionData'
    ]),
    makeVideoPrimary () {
      this.video.unit.streams.video.statusPrimary = !this.video.unit.streams.video.statusPrimary
      this.video.mobile.streams.video.statusPrimary = !this.video.mobile.streams.video.statusPrimary
    }
  },
  watch: {
    camUnitStatus: {
      handler () {
        if (!this.camUnitStatus || this.camUnitStatus === 'OFFLINE') {
          this.video.mobile.streams.video.statusPrimary = true
          this.video.unit.streams.video.statusPrimary = false
        }
      }
      // immediate: true
    },
    'mediaInfo.unit.streams.video.name': {
      handler (val) {
        this.video.mobile.streams.video.statusPrimary = Boolean(!val)
        this.video.unit.streams.video.statusPrimary = Boolean(val)
      },
      immediate: true
    },
    isUnavailablePtzControls: {
      handler () {
        if (this.isUnavailablePtzControls) {
          showErrorToast({ id: 4, message: ERRORS[4] })
        }
      }
    }
  },
  beforeMount () {
    this.createStreamName()
  },
  destroyed () {
    this.deleteSessionData()
  }
}
</script>

<style lang="scss">
.camera {
  height: 100%;
  position: relative;
  background: #000;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  &__wrap {
    height: 100%;
    position: relative;
    z-index: 6;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
  }
  &__preview {
    flex: 1 1 100%;
  }
  &__ptz-controls {
    position: absolute;
    bottom: 25px;
    right: 40px;
    z-index: 6;
  }
}
</style>
