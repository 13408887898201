<template>
  <span>{{time}}</span>
</template>

<script>
import msToTime from './msToTime'

export default {
  name: 'Btn',
  props: {
    startTime: String
  },
  data () {
    return {
      time: '00:00:00'
    }
  },
  methods: {
    runTimer () {
      if (this.startTime) {
        this.time = msToTime((new Date() - new Date(this.startTime)))
      }
    }
  },
  mounted () {
    this.intervalHandler = setInterval(this.runTimer.bind(this), 1000)
  },
  destroyed () {
    clearInterval(this.intervalHandler)
  }
}
</script>

<style lang="scss" scoped>
</style>
