import { TweenMax } from 'gsap/all'
import JoystickElement from './JoystickElement'

export default class JoystickShaft extends JoystickElement {
  clamp (x, y, boundary) {
    // Trigonometry time!
    // - Who says what you learn in school won't become useful :D
    const diff = {
      x: x - this.rect.center.x,
      y: y - this.rect.center.y
    }

    // Get the distance between the cursor and the center
    const distance = Math.sqrt(
      Math.pow(diff.x, 2) + Math.pow(diff.y, 2)
    )

    // Get the angle of the line
    const angle = Math.atan2(diff.y, diff.x)
    // Convert into degrees!
    this.current.angle = angle// 180 - (angle * 180 / Math.PI);

    // If the cursor is distance from the center is
    // less than the boundary, then return the diff
    //
    // Note: Boundary = radius
    if (distance < boundary) {
      this.current.percentage = (distance / boundary) * 100
      this.current.vector = diff
      return this.current.vector
    }

    // If it's a longer distance, clamp it!
    this.current.percentage = 100

    this.current.vector = {
      x: Math.cos(angle) * boundary,
      y: Math.sin(angle) * boundary
    }
    return this.current.vector
  }

  move (from, to, duration, callback) {
    TweenMax.fromTo(
      this.element,
      duration,
      { x: from.x, y: from.y },
      { x: to.x, y: to.y, onComplete: callback }
    )
  }
}
