<template>
  <div :class="listClasses" @click="clickHandler(!participantsListVisible)">
    <div class="participants-short__list">
      <ParticipantIcon
        v-for="item in shortenParticipantsList"
        :key="item.suid"
        :name="item.name"
        :type="item.type"
        :active="item.talking"
      />
      <ParticipantIcon
        v-if="sessionParticipants.length > 4"
        :name="countedMoreUsers"
        type="MORE_ICON"
      />
    </div>
  </div>
</template>

<script>
import ParticipantIcon from '../participant-icon/ParticipantIcon'

export default {
  name: 'ParticipantsShort',
  props: ['clickHandler', 'participantsListVisible', 'sessionParticipants'],
  data () {
    return {}
  },
  components: {
    ParticipantIcon
  },
  computed: {
    listClasses: function () {
      return {
        'participants-short': true,
        'participants-short--big': this.sessionParticipants.length >= 4
      }
    },
    shortenParticipantsList: function () {
      const formattedList = this.sessionParticipants.map((b, idx) => Object.assign({ index: idx }, b))

      formattedList.sort((a, b) => {
        if (a.type !== b.type) {
          return a.type === 'CAM_UNIT' || a.type === 'CAM_MOBILE' ? -1 : 1
        }
        if (a.host !== b.host) {
          return a.host ? -1 : 1
        }
        if (a.talking !== b.talking) {
          return a.talking ? -1 : 1
        }
        return 1
      })

      if (this.sessionParticipants.length > 4) {
        return formattedList.slice(0, 3)
      } else {
        return formattedList.slice(0, 4)
      }
    },
    countedMoreUsers: function () {
      return `+${this.sessionParticipants.length - 3}`
    }
  }
}
</script>

<style lang="scss" scoped>
.participants-short {
  margin: 0 5px;

  &__list {
    display: flex
  }
}
</style>
