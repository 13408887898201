<template>
  <div class="media__list">
    <ul>
      <li v-for="item in mediaList" :key="item.id">
        <video-item
          :mediaInfo="item"
          @mediaClicked="handleMediaClick"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import VideoItem from '@/components/video-item/VideoItem'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'MediaList',
  components: {
    VideoItem
  },
  props: {
    mediaList: Array
  },
  computed: {
    ...mapState({
      recordingInfo: state => state.recordingDetails.recordingDetails,
      currentMediaId: state => state.recordingDetails.currentMediaId
    })
  },
  methods: {
    ...mapMutations(['setCurrentMediaId']),
    ...mapActions([
      'getRecordingDetails',
      'getMediaList',
      'getDownloadUrl'
    ]),
    handleMediaClick (id) {
      this.setCurrentMediaId(id)
      this.getDownloadUrl()
      this.$emit('mediaChanged')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
