<template>
  <div class="player">
    <audio v-if="audioOnly" ref="audioPlayer" :id="elementId" :streamName="streamName" autoplay></audio>
    <video v-else class="video" ref="videoPlayer" :id="elementId" :streamName="streamName" :controls="false" autoplay></video>
    <LoaderIcon v-if="!connected && !audioOnly"/>
  </div>
</template>

<script>
import Vue from 'vue'
import kurentoUtils from 'kurento-utils'
import detectSilence from '@/helpers/detectSilence'
import LoaderIcon from '@/common/loader-icon/LoaderIcon'
import { showErrorToast, clearToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'
import { mapActions } from 'vuex'
import WebsocketHeartbeatJs from 'websocket-heartbeat-js'

export default {
  name: 'KurentoPlayer',
  props: ['audioOnly', 'streamName', 'currentSession', 'volume', 'publisherUuid', 'elementId'],
  components: {
    LoaderIcon
  },
  data () {
    return {
      candidatesList: [],
      subscribeResponseReceived: false,
      connected: false,
      audioContext: null,
      reconnectionTime: 3500,
      playing: false,
      reconnectTimeout: null,
      options: {
        iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
        remoteVideo: null,
        onicecandidate: this.onIceCandidate
      },
      webRtcPeer: null,
      ws: null,
      mediaUrlKurento: window.VEST_CONFIG.kurento
    }
  },
  methods: {
    ...mapActions([
      'setStartSessionCamUnit',
      'setStartSessionCamMobile',
      'setActiveParticipant'
    ]),
    setConnected (value) {
      this.connected = value
      if (!this.connected) {
        // this.retryConnect()
      }
    },
    retryConnect () {
      clearTimeout(this.reconnectTimeout)
      if (!this.connected) {
        this.reconnectTimeout = setTimeout(this.subscribe, this.reconnectionTime)
      }
    },
    onIceCandidate (candidate) {
      const message = {
        type: 'ICE_CANDIDATE',
        streamId: this.streamName,
        payload: candidate
      }
      if (!this.subscribeResponseReceived) {
        this.candidatesList.push(message)
      } else {
        // Send ICE candidates gathered before SUBSCRIBE_RESPONSE received
        if (this.candidatesList.length > 0) {
          this.candidatesList.forEach((value, index, array) => {
            this.sendMessage(value)
            array.splice(index, 1)
          })
        }
        this.sendMessage(message)
      }
    },
    async onOffer (error, offerSdp) {
      if (error) return console.log('Error generating the offer: ' + error)

      const TOKEN = await Vue.prototype.$auth.getToken().then(data => data)
      this.sendMessage({
        authorization: {
          token: TOKEN,
          target: this.currentSession.id
        },
        type: 'SUBSCRIBE',
        streamId: this.streamName,
        payload: {
          offer: offerSdp
        }
      })
    },
    sendMessage (message) {
      const jsonMessage = JSON.stringify(message)
      console.log('<<< Sending message: ' + jsonMessage)
      this.ws.send(jsonMessage)
    },
    response (message) {
      if (message.error) {
        const errorMsg = message.error.message ? message.error.message : 'Unknown error'
        console.info('Call not accepted for the following reason: ' + errorMsg)
        showErrorToast({ id: 7, message: ERRORS[7] })
        this.dispose()
      } else {
        this.webRtcPeer.processAnswer(message.payload.answer, function (error) {
          // this.setConnected(true)
          if (error) {
            return console.error(error)
          }
        })
        this.subscribeResponseReceived = true
      }
    },
    dispose () {
      this.setConnected(false)
      if (this.webRtcPeer) {
        if (this.audioContext) {
          this.audioContext.close()
        }
        const audioTracks = this.options.remoteVideo.srcObject?.getTracks()
        if (audioTracks?.length > 0) {
          audioTracks.forEach(track => track.stop())
        }
        this.webRtcPeer.dispose()
        this.webRtcPeer = null

        this.ws.close()
        this.ws = null
      }
    },
    stop () {
      this.sendMessage({
        type: 'STOP',
        streamId: this.streamName
      })
      this.dispose()
    },
    subscribe () {
      clearTimeout(this.reconnectTimeout)

      if (!this.webRtcPeer) {
        this.ws = new WebsocketHeartbeatJs({ url: this.mediaUrlKurento, pingTimeout: 60000, pongTimeout: 10000, pingMsg: '{"type": "PING"}' })
        this.ws.onmessage = this.onMessage
        this.ws.onopen = () => {
          this.webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(this.options, error => {
            if (error) {
              showErrorToast({ id: 8, message: ERRORS[8] })
              this.setConnected(false)
              return
            }
            this.webRtcPeer.peerConnection.addEventListener('track', async (event) => {
              const stream = await event.track
              if (stream.kind === 'audio') {
                this.audioContext = detectSilence(stream, this.onSilence, this.onSpeak)
              }
            })
            this.webRtcPeer.generateOffer(this.onOffer)
            console.log('webrtc rtcSubscriber is playing')
            this.playing = true
            this.setConnected(true)
            this.setStartSessionCamUnit(true)
            this.setStartSessionCamMobile(true)
          })
        }
      }
      this.ws.onerror = (error) => {
        console.error('WebSocket connection failed', error)
        showErrorToast({ id: 8, message: ERRORS[8] })
      }
    },
    onMessage (message) {
      console.info('>>> Received message: ' + message.data)
      const parsedMessage = JSON.parse(message.data)

      switch (parsedMessage.type) {
        case 'ERROR':
          console.error('Error message from server: ' + parsedMessage.error.message)
          showErrorToast({ id: 8, message: ERRORS[8] })
          this.setConnected(false)
          break

        case 'SUBSCRIBE_RESPONSE':
          this.response(parsedMessage)
          break

        case 'ICE_CANDIDATE':
          this.webRtcPeer.addIceCandidate(parsedMessage.payload, function (error) {
            if (error) {
              return console.error('Error adding candidate: ' + error)
            }
          })
          break

        case 'CLOSE':
          this.dispose()
          this.setConnected(false)
          break

        case 'PONG':
          break

        default:
          console.error('Unrecognized message', parsedMessage)
          showErrorToast({ id: 11, message: ERRORS[11] })
      }
    },
    onSilence () {
      this.setActiveParticipant({ uuid: this.publisherUuid, talking: false })
    },
    onSpeak () {
      this.setActiveParticipant({ uuid: this.publisherUuid, talking: true })
    },
    changeVolume (val) {
      if (val) {
        this.options.remoteVideo.volume = 1
      } else {
        this.options.remoteVideo.volume = 0
      }
    }
  },
  watch: {
    volume: {
      handler (val) {
        if (!this.webRtcPeer) return
        this.changeVolume(val)
      }
    }
  },
  mounted () {
    if (this.audioOnly) {
      this.options.remoteVideo = this.$refs.audioPlayer
    } else {
      this.options.remoteVideo = this.$refs.videoPlayer
    }
    this.changeVolume(this.volume)
    this.subscribe()
  },
  destroyed () {
    clearTimeout(this.reconnectTimeout)
    if (!this.webRtcPeer) return
    this.dispose()

    clearToast()
  }
}
</script>

<style lang="scss" scoped>
  .video {
    display: block;
    margin: auto;
    width: 100%!important;
    height: 100%!important;
    object-fit: cover;
    object-position: center;
  }
  .player--mobile {
    display: flex;
    height: 100%;
    margin: auto;
  }
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
