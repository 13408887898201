const createUnitStructure = ({ id, location, name, unitId, telemetry, status, containerId, mobileId, smartHands, fullInfo, session }) => {
  const unit = {}
  unit.id = id
  unit.meta = {
    location: location
  }
  unit.fullInfo = fullInfo
  unit.smartHands = true
  unit.type = 'VEST_UNIT'
  unit.name = name
  unit.session = session
  unit.devices = [
    {
      id: unitId,
      type: 'CAM_UNIT',
      telemetry: telemetry,
      status: status,
      containerId: containerId
    },
    {
      id: mobileId,
      type: 'CAM_MOBILE',
      telemetry: null,
      status: '',
      containerId: containerId
    }
  ]

  return unit
}

export default createUnitStructure
