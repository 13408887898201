export default {
  namespaced: false,
  state: {
    defaultTitle: 'Switch Vest'
  },
  getters: {
    defaultTitle (state) {
      return state.defaultTitle
    }
  }
}
