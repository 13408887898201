const detectSilence = (
  stream,
  onSoundEnd = _ => {},
  onSoundStart = _ => {},
  silenceDelay = 500,
  minDecibels = -60
) => {
  const isStreamTrack = stream.constructor.name === 'MediaStreamTrack'
  const audioTracks = stream && isStreamTrack
    ? [stream]
    : stream ? stream.getAudioTracks() : []

  if (audioTracks.length === 0) {
    return false
  }
  const streamTrack = isStreamTrack ? new MediaStream([stream]) : null
  const AudioContext = window.AudioContext || window.webkitAudioContext
  const ctx = new AudioContext()
  const analyser = ctx.createAnalyser()
  const streamNode = ctx.createMediaStreamSource(isStreamTrack ? streamTrack : stream)
  console.warn(streamNode)
  streamNode.connect(analyser)
  analyser.minDecibels = minDecibels

  const data = new Uint8Array(analyser.frequencyBinCount)
  let silenceStart = performance.now()
  let triggered = false
  let rafId = null

  function loop (time) {
    rafId = requestAnimationFrame(loop)
    if (!analyser) {
      window.cancelAnimationFrame(rafId)
    }
    analyser.getByteFrequencyData(data)
    if (data.some(v => v)) {
      if (triggered) {
        triggered = false
        onSoundStart()
      }
      silenceStart = time
    }
    if (!triggered && time - silenceStart > silenceDelay) {
      onSoundEnd()
      triggered = true
    }
  }
  loop()
  return ctx
}

export default detectSilence
