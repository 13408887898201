import Vue from 'vue'
import 'vue-toastification/dist/index.css'

let displayedErrors = []

const showInfoToast = (message, time = 8000, icon = false, closeOnClick = true) => {
  const options = {
    toastClassName: 'Vue-Toastification__toast--recording',
    position: 'bottom-right',
    timeout: time,
    closeOnClick: closeOnClick,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: closeOnClick,
    draggablePercent: 0.5,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: icon,
    rtl: false
  }
  Vue.$toast.info(message, options)
}

const showErrorToast = ({ id, message, time = 8000 }) => {
  const options = {
    id: id,
    position: 'bottom-left',
    timeout: time,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.5,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: false,
    rtl: false,
    onClose: () => {
      displayedErrors = displayedErrors.filter(el => el !== id)
    }
  }
  if (displayedErrors.find(el => el === id)) {
    Vue.$toast.update(id, { content: message, options })
  } else {
    displayedErrors.push(id)
    Vue.$toast.error(message, options)
  }
}
const dismissToast = (id) => {
  Vue.$toast.dismiss(id)
}

const clearToast = () => {
  Vue.$toast.clear()
}

export { showInfoToast, showErrorToast, dismissToast, clearToast }
