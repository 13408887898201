const mockedRecordings = {
  totalItems: 8,
  totalPages: 3,
  currentPage: 1,
  recordings: [
    {
      recordingId: '00gres6ujyt01',
      session: {
        unit: {
          id: '000000-00fsrfser000',
          name: 'Verter',
          location: 'Minsk',
          type: 'EDGE'
        },
        type: 'ESCORT',
        startedAt: new Date('06-05-2019'),
        duration: 70000
      },
      note: 'some perfect note',
      participants: {
        count: 5
      },
      mediaFiles: {
        count: 5
      }
    },
    {
      recordingId: 'grdgsr002',
      session: {
        unit: {
          id: '000fsegseh000-00001',
          name: 'Verter',
          location: 'Canada, Vancouver',
          type: 'STANCHION'
        },
        type: 'SMARTHANDS',
        startedAt: new Date('01-03-2020'),
        duration: 60000
      },
      note: 'some perfect note2',
      participants: {
        count: 18
      },
      mediaFiles: {
        count: 5000
      }
    },
    {
      recordingId: '000bdsfse3',
      session: {
        unit: {
          id: '00fsehre40000-00002',
          name: 'KITT',
          location: 'Indonesia, Denpasar',
          type: 'STANCHION'
        },
        type: 'REGULAR',
        startedAt: new Date('07-12-2020'),
        duration: 25000
      },
      note: 'some perfect note3',
      participants: {
        count: 3
      },
      mediaFiles: {
        count: 3
      }
    },
    {
      recordingId: '00gsdrh5wf04',
      session: {
        unit: {
          id: '0000gsr00-00fsagsr003',
          name: 'E2D2',
          location: 'Optimus Prime',
          type: 'EDGE'
        },
        type: 'SMARTHANDS',
        startedAt: new Date('12-12-2020'),
        duration: 25000
      },
      note: 'some perfect note4 frsfrg gesgregerhgt ghwsghrhfmseafj fawefjnawlegnawlgnrg',
      participants: {
        count: 11
      },
      mediaFiles: {
        count: 7
      }
    },
    {
      recordingId: '000hdthdfsearhr1',
      session: {
        unit: {
          id: '0000fe00-00hftn23sg000',
          name: 'Verter',
          location: 'Minsk',
          type: 'EDGE'
        },
        type: 'ESCORT',
        startedAt: new Date('06-05-2019'),
        duration: 70000
      },
      note: 'some perfect note',
      participants: {
        count: 5
      },
      mediaFiles: {
        count: 5
      }
    },
    {
      recordingId: '000hftntfn2',
      session: {
        unit: {
          id: '0000gerr300-00001',
          name: 'Verter',
          location: 'Canada, Vancouver',
          type: 'STANCHION'
        },
        type: 'SMARTHANDS',
        startedAt: new Date('01-03-2020'),
        duration: 60000
      },
      note: 'some perfect note2',
      participants: {
        count: 18
      },
      mediaFiles: {
        count: 5000
      }
    },
    {
      recordingId: 'gdrgdhd53',
      session: {
        unit: {
          id: '000grd000-00002',
          name: 'KITT',
          location: 'Indonesia, Denpasar',
          type: 'STANCHION'
        },
        type: 'REGULAR',
        startedAt: new Date('07-12-2020'),
        duration: 25000
      },
      note: 'some perfect note3',
      participants: {
        count: 3
      },
      mediaFiles: {
        count: 3
      }
    },
    {
      recordingId: '000t3454',
      session: {
        unit: {
          id: '000000-grdg',
          name: 'E2D2',
          location: 'Optimus Prime',
          type: 'EDGE'
        },
        type: 'SMARTHANDS',
        startedAt: new Date('12-12-2020'),
        duration: 25000
      },
      note: 'some perfect note4 frsfrg gesgregerhgt ghwsghrhfmseafj fawefjnawlegnawlgnrg',
      participants: {
        count: 11
      },
      mediaFiles: {
        count: 7
      }
    },
    {
      recordingId: '000serftge1',
      session: {
        unit: {
          id: '000f000-00gsdgse000',
          name: 'Verter',
          location: 'Minsk',
          type: 'EDGE'
        },
        type: 'ESCORT',
        startedAt: new Date('06-05-2019'),
        duration: 70000
      },
      note: 'some perfect note',
      participants: {
        count: 5
      },
      mediaFiles: {
        count: 5
      }
    },
    {
      recordingId: '000tgsrg2',
      session: {
        unit: {
          id: '00fae0000-0000ndfgrdt1',
          name: 'Verter',
          location: 'Canada, Vancouver',
          type: 'STANCHION'
        },
        type: 'SMARTHANDS',
        startedAt: new Date('01-03-2020'),
        duration: 60000
      },
      note: 'some perfect note2',
      participants: {
        count: 18
      },
      mediaFiles: {
        count: 5000
      }
    },
    {
      recordingId: '0042gdrh03',
      session: {
        unit: {
          id: '00000hgs40-00002',
          name: 'KITT',
          location: 'Indonesia, Denpasar',
          type: 'STANCHION'
        },
        type: 'REGULAR',
        startedAt: new Date('07-12-2020'),
        duration: 25000
      },
      note: 'some perfect note3',
      participants: {
        count: 3
      },
      mediaFiles: {
        count: 3
      }
    },
    {
      recordingId: '00twtgw04',
      session: {
        unit: {
          id: '000000-000sf42503',
          name: 'E2D2',
          location: 'Optimus Prime',
          type: 'EDGE'
        },
        type: 'TRAINING',
        startedAt: new Date('12-12-2020'),
        duration: 25000
      },
      note: 'some perfect note4 frsfrg gesgregerhgt ghwsghrhfmseafj fawefjnawlegnawlgnrg',
      participants: {
        count: 11
      },
      mediaFiles: {
        count: 7
      }
    }
  ]
}
const mockedRecordingDetails = {
  recordingId: '0001',
  session: {
    id: '0000-0000-0000-0000',
    unit: {
      id: '000000-00000',
      name: 'Verter',
      location: 'Minsk',
      type: 'EDGE'
    },
    type: 'ESCORT',
    startedAt: new Date('06-05-2019'),
    duration: 70000
  },
  note: 'some perfect note',
  participants: {
    count: 3,
    list: [
      {
        id: 'guid',
        name: 'Vasja Fedorov',
        initiator: true
      },
      {
        id: 'guid',
        name: 'Petya Fedorov',
        initiator: false
      },
      {
        id: 'guid',
        name: 'Kolia Fedorov',
        initiator: false
      }
    ]
  }
}
const mockedMediaList = [
  {
    id: 'guidcaecw',
    displayName: 'Something',
    mediaType: 'AUDIO|VIDEO',
    uri: 'download_uri',
    duration: 10000,
    size: 100000
  },
  {
    id: 'guid',
    displayName: 'Something',
    mediaType: 'AUDIO|VIDEO',
    uri: 'download_uri',
    duration: 40000,
    size: 10000000
  }
]
function getMoreRecordings () {
  return new Promise((resolve) => {
    setTimeout(() => resolve({
      totalItems: 8,
      totalPages: 3,
      currentPage: 2,
      recordings: [
        {
          recordingId: '00gres6vfasujyt01',
          session: {
            unit: {
              id: '0000fase00-00fsrfser000',
              name: 'Verter',
              location: 'Minsk',
              type: 'EDGE'
            },
            type: 'ESCORT',
            startedAt: new Date('06-05-2019'),
            duration: 70000
          },
          note: 'some perfect note',
          participants: {
            count: 5
          },
          mediaFiles: {
            count: 5
          }
        },
        {
          recordingId: 'grdgvsarsr002',
          session: {
            unit: {
              id: '000fsegsehvrsa000-00001',
              name: 'Verter',
              location: 'Canada, Vancouver',
              type: 'STANCHION'
            },
            type: 'SMARTHANDS',
            startedAt: new Date('01-03-2020'),
            duration: 60000
          },
          note: 'some perfect note2',
          participants: {
            count: 18
          },
          mediaFiles: {
            count: 5000
          }
        },
        {
          recordingId: '000bvsdzvdsfse3',
          session: {
            unit: {
              id: '00fsehre4vdfs0000-00002',
              name: 'KITT',
              location: 'Indonesia, Denpasar',
              type: 'STANCHION'
            },
            type: 'REGULAR',
            startedAt: new Date('07-12-2020'),
            duration: 25000
          },
          note: 'some perfect note3',
          participants: {
            count: 3
          },
          mediaFiles: {
            count: 3
          }
        },
        {
          recordingId: '00gsasdfvedrh5wf04',
          session: {
            unit: {
              id: '0000gsr00-00fsagsvbtdr003',
              name: 'E2D2',
              location: 'Optimus Prime',
              type: 'EDGE'
            },
            type: 'SMARTHANDS',
            startedAt: new Date('12-12-2020'),
            duration: 25000
          },
          note: 'some perfect note4 frsfrg gesgregerhgt ghwsghrhfmseafj fawefjnawlegnawlgnrg',
          participants: {
            count: 11
          },
          mediaFiles: {
            count: 7
          }
        },
        {
          recordingId: '000hdthdfvsssearhr1',
          session: {
            unit: {
              id: '0000fe00-00hftncves23sg000',
              name: 'Verter',
              location: 'Minsk',
              type: 'EDGE'
            },
            type: 'ESCORT',
            startedAt: new Date('06-05-2019'),
            duration: 70000
          },
          note: 'some perfect note',
          participants: {
            count: 5
          },
          mediaFiles: {
            count: 5
          }
        },
        {
          recordingId: '000hfsefgtntfn2',
          session: {
            unit: {
              id: '0000gvseverr300-00001',
              name: 'Verter',
              location: 'Canada, Vancouver',
              type: 'STANCHION'
            },
            type: 'SMARTHANDS',
            startedAt: new Date('01-03-2020'),
            duration: 60000
          },
          note: 'some perfect note2',
          participants: {
            count: 18
          },
          mediaFiles: {
            count: 5000
          }
        },
        {
          recordingId: 'gdrgdbgtbdhd53',
          session: {
            unit: {
              id: '000grd000-000vsbbvsr02',
              name: 'KITT',
              location: 'Indonesia, Denpasar',
              type: 'STANCHION'
            },
            type: 'REGULAR',
            startedAt: new Date('07-12-2020'),
            duration: 25000
          },
          note: 'some perfect note3',
          participants: {
            count: 3
          },
          mediaFiles: {
            count: 3
          }
        },
        {
          recordingId: '000fesfet3454',
          session: {
            unit: {
              id: '000fesf000-grdg',
              name: 'E2D2',
              location: 'Optimus Prime',
              type: 'EDGE'
            },
            type: 'SMARTHANDS',
            startedAt: new Date('12-12-2020'),
            duration: 25000
          },
          note: 'some perfect note4 frsfrg gesgregerhgt ghwsghrhfmseafj fawefjnawlegnawlgnrg',
          participants: {
            count: 11
          },
          mediaFiles: {
            count: 7
          }
        },
        {
          recordingId: '000serftvsrvsge1',
          session: {
            unit: {
              id: '000f000-00gsdgvrsvse000',
              name: 'Verter',
              location: 'Minsk',
              type: 'EDGE'
            },
            type: 'ESCORT',
            startedAt: new Date('06-05-2019'),
            duration: 70000
          },
          note: 'some perfect note',
          participants: {
            count: 5
          },
          mediaFiles: {
            count: 5
          }
        },
        {
          recordingId: '00scesv0tgsrg2',
          session: {
            unit: {
              id: '00fae0000-0000ndvsevfgrdt1',
              name: 'Verter',
              location: 'Canada, Vancouver',
              type: 'STANCHION'
            },
            type: 'SMARTHANDS',
            startedAt: new Date('01-03-2020'),
            duration: 60000
          },
          note: 'some perfect note2',
          participants: {
            count: 18
          },
          mediaFiles: {
            count: 5000
          }
        },
        {
          recordingId: '0042gdrvesveh03',
          session: {
            unit: {
              id: '00000vesvehgs40-00002',
              name: 'KITT',
              location: 'Indonesia, Denpasar',
              type: 'STANCHION'
            },
            type: 'REGULAR',
            startedAt: new Date('07-12-2020'),
            duration: 25000
          },
          note: 'some perfect note3',
          participants: {
            count: 3
          },
          mediaFiles: {
            count: 3
          }
        },
        {
          recordingId: '00twtg32rw04',
          session: {
            unit: {
              id: '000000-000sf42f3e503',
              name: 'E2D2',
              location: 'Optimus Prime',
              type: 'EDGE'
            },
            type: 'TRAINING',
            startedAt: new Date('12-12-2020'),
            duration: 25000
          },
          note: 'some perfect note4 frsfrg gesgregerhgt ghwsghrhfmseafj fawefjnawlegnawlgnrg',
          participants: {
            count: 11
          },
          mediaFiles: {
            count: 7
          }
        }
      ]
    }), 2000)
  })
}

function getRecordings () {
  return Promise.resolve(mockedRecordings)
}
function getRecordingDetails (id) {
  return Promise.resolve(mockedRecordingDetails)
}
function getMediaList (id) {
  return Promise.resolve(mockedMediaList)
}

export default {
  getRecordings,
  getRecordingDetails,
  getMediaList,
  getMoreRecordings
}
