<template>
  <div class="new-unit" v-if="loggedIn && connectedStatus">
    <AddVestUnit/>
  </div>
  <Loading v-else/>
</template>

<script>
import { mapGetters } from 'vuex'
import AddVestUnit from '@/components/add-vest-unit/AddVestUnit'
import Loading from '@/common/loading/Loading'

export default {
  name: 'NewUnitPage',
  components: {
    Loading,
    AddVestUnit
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'connectedStatus'
    ])
  }
}
</script>

<style lang="scss" scoped></style>
