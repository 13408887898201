const topics = (orgId, uid, suid) => {
  const TOPICS = {
    camUnit: `/topic/${orgId}/unit/*/CAM_UNIT/*/status`,
    podController: `/topic/${orgId}/unit/*/POD_CONTROLLER/*/status`,
    mobile: `/topic/${orgId}/unit/*/CAM_MOBILE/*/status`,
    sessionStatus: `/topic/${orgId}/unit/*/session/status`,
    error: `/queue/${orgId}/client/${uid}/${suid}/errors`,
    joinSession: `/queue/${orgId}/client/${uid}/${suid}/command/JOIN_SESSION`,
    leaveSession: `/sm/${orgId}/command/LEAVE_SESSION`,
    startSession: `/sm/${orgId}/command/START_SESSION`,
    closeSession: `/sm/${orgId}/command/CLOSE_SESSION`,
    expose: `/sm/${orgId}/command/EXPOSE`,
    unitCommandQueue: ({ currentContainerId, currentUnitId, command }) => `/queue/${orgId}/unit/${currentContainerId}/CAM_UNIT/${currentUnitId}/command/${command}`,
    mobileCommandQueue: ({ currentContainerId, currentSubUnitId, command }) => `/queue/${orgId}/unit/${currentContainerId}/CAM_MOBILE/${currentSubUnitId}/command/${command}`,
    clientCommandQueue: ({ userId, suid, command }) => `/queue/${orgId}/client/${userId}/${suid}/command/${command}`,
    modxCamCommandQueue: ({ currentContainerId, currentUnitId, command }) => `/queue/${orgId}/unit/${currentContainerId}/IP_CAMERA/${currentUnitId}/command/${command}`,
    getPtz: ({
      currentContainerId,
      currentUnitId
    }) => `/queue/${orgId}/unit/${currentContainerId}/CAM_UNIT/${currentUnitId}/command/PTZ`,
    getPtzMobile: ({
      currentContainerId,
      currentSubUnitId
    }) => `/queue/${orgId}/unit/${currentContainerId}/CAM_MOBILE/${currentSubUnitId}/command/PTZ`,
    getForUnitStatus: ({
      unitId = '*'
    }) => `/topic/${orgId}/unit/${unitId}/session/status`,
    getForDeviceStatus: ({
      deviceType = '*',
      unitId = '*',
      deviceId = '*'
    }) => `/topic/${orgId}/unit/${unitId}/${deviceType}/${deviceId}/status`,
    getForSMCommand: ({
      commandName = '*'
    }) => `/sm/${orgId}/command/${commandName}`,
    getForDeviceCommand: ({
      deviceType = '*',
      unitId = '*',
      deviceId = '*',
      command = '*'
    }) => `/queue/${orgId}/unit/${unitId}/${deviceType}/${deviceId}/command/${command}`,
    getForDeviceErrors: ({
      deviceType = '*',
      unitId = '*',
      deviceId = '*'
    }) => `/queue/${orgId}/unit/${unitId}/${deviceType}/${deviceId}/errors`
  }
  return TOPICS
}

export default topics
