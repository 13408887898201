<template>
  <div
    class="video__item"
    :class="isActive ? 'active' : ''"
    @click="handleClick"
  >
    <div class="video__item--preview">
      <div class="item__preview--controls">
      </div>
      <img class="video__item--img" src="@/components/video-item/MobileVideo.png" alt="preview">
    </div>
    <div class="video__item--details">
      <div class="item__details--title">{{mediaInfo.displayName}}</div>
      <div class="item__details--size">
        <span class="details__size--duration">{{secToHm(mediaInfo.duration)}}</span>
        <span class="details__size--volume">{{bytesToSize(mediaInfo.size)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { secToHm } from '@/helpers/date'
import { bytesToSize } from '@/helpers/size'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'VideoItem',
  props: {
    mediaInfo: Object
  },
  computed: {
    ...mapState({
      recordingInfo: state => state.recordingDetails.recordingDetails,
      currentMediaId: state => state.recordingDetails.currentMediaId
    }),
    isActive () {
      return this.currentMediaId === this.mediaInfo.id
    }
  },
  methods: {
    ...mapActions([
      'deleteMediaFile'
    ]),
    secToHm,
    bytesToSize,
    handleClick (e) {
      this.$emit('mediaClicked', this.mediaInfo.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.video__item {
  display: flex;
  padding: 12px 16px;
  cursor: pointer;

  &.active {
    background: #F5F5F5;
  }

  &:hover {
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12);
  }

  &--img {
    max-width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
  }

  &--preview {
    width: 114px;
    height: 64px;
    overflow: hidden;
    position: relative;

    .item__preview--controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      z-index: -1;
      background: none;
    }
  }

  &--details {
    width: calc(100% - 114px);
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .item__details--title {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .item__details--size {
      white-space: nowrap;
      span {
        font-size: 14px;
        line-height: 16px;
        &:first-child{
          padding-right: 12px;
        }
        &:last-child {
          padding-left: 12px;
          border-left: 1px solid #999;
        }
      }
      display: flex;
    }
  }
}
</style>
