import Router from 'vue-router'
import store from '@/store'
import { IAMClientVueRouterMiddleware } from '@switch-app-dev/js-lib_oauth-auth'

import HomePage from '../pages/home-page/HomePage'
import SessionRoom from '../pages/session-room/SessionRoom'
import PodSessionRoom from '../pages/pod-session-room/PodSessionRoom'
import KurentoPlayer from '../components/kurento-player/KurentoPlayer'
import KurentoPublisher from '../components/kurento-publisher/KurentoPublisher'
import RecordingsPage from '../pages/recordings-page/RecordingsPage'
import RecordingDetails from '../pages/recording-details/RecordingDetails'
import DeviceInventory from '../pages/device-inventory/DeviceInventory'
import AddUnitPage from '../pages/add-unit-page/AddUnitPage'
import EditUnitPage from '../pages/edit-unit-page/EditUnitPage'
import ClosePage from '../pages/close-page/ClosePage'
import { showErrorToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'

/**
 * Patching original push method because of NavigationDuplicated error
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'HomePage',
      path: '/',
      component: HomePage
    },
    {
      name: 'SessionRoom',
      path: '/stanchion/:unitName/',
      component: SessionRoom
    },
    {
      name: 'PodSessionRoom',
      path: '/surveillance/:modName/:podName',
      component: PodSessionRoom
    },
    {
      name: 'KurentoPlayer',
      path: '/kurentoplayer',
      component: KurentoPlayer
    },
    {
      name: 'KurentoPublisher',
      path: '/kurentopublisher',
      component: KurentoPublisher
    },
    {
      name: 'RecordingsPage',
      path: '/recordings',
      component: RecordingsPage
    },
    {
      name: 'RecordingDetails',
      path: '/recording/:id',
      component: RecordingDetails,
      props: true
    },
    {
      name: 'DeviceInventory',
      path: '/deviceInventory',
      component: DeviceInventory,
      beforeEnter: (to, from, next) => {
        if (store.getters.isSwitchAdmin || store.getters.isOtherAdmin) next()
      }
    },
    {
      name: 'AddUnitPage',
      path: '/add-vest-unit',
      component: AddUnitPage,
      beforeEnter: (to, from, next) => {
        if (store.getters.isSwitchAdmin || store.getters.isOtherAdmin) next()
      }
    },
    {
      name: 'EditUnitPage',
      path: '/edit-vest-unit/:unitName',
      component: EditUnitPage,
      beforeEnter: (to, from, next) => {
        if (store.getters.isSwitchAdmin || store.getters.isOtherAdmin) next()
      }
    },
    {
      name: 'ClosePage',
      path: '/close',
      component: ClosePage
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.path === '/close') {
    next()
  } else {
    IAMClientVueRouterMiddleware(to, from, next, router.app.$auth)
      .then(success => {
        if (success === null) {
        } else if (success) {
          if (store.state.User.loggedIn) return next()
          router.app.$auth.getIAMUserInfo()
            .then((response) => {
              store.dispatch('setUser', response)
              return response
            })
            .then(res => {
              if (res) store.dispatch('setLoggedIn', true)
              const user = store.getters.user
              store.dispatch('setUID', user.user_metadata.identity_id)
              store.dispatch('getUnits', user.user_metadata.organization_id)
              return next()
            })
            .catch(error => {
              console.warn(error)
              if (error.toString() === 'Error: Network Error') {
                showErrorToast({ id: 1, message: ERRORS[1] })
              } else {
                showErrorToast({ id: 3, message: ERRORS[3] })
              }
              next('/error')
            })
        } else {
          return next('/error')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
})

export default router
