<template>
  <div class="header">
    <div class="header__row">
      <div class="header__col">
        <div class="header__outer">
          <div class="header__itm header--bordered header--bordered-logo">
            <a v-tooltip="`Switch Vest`" @click.prevent="leaveRoom" href="#" class="header__logo--link">
              <Icon class="icon--logo">
                <svg viewBox="0 0 68 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33.297 3.24L25.506.229l-.022 1.166c-5.605.565-9.832 3.072-9.832 6.082 0 1.758 1.443 3.344 3.755 4.469.04.02.08.041.12.057.1.048.208.094.312.14 1.336.575 2.585.857 3.393.991-2.555-.75-4.288-2.18-4.288-3.83 0-.01.005-.021.005-.032 0-.01-.005-.019-.005-.026 0-.13.014-.26.042-.388.362-1.886 2.992-3.415 6.427-3.831l-.018.946 7.902-2.731z" fill="#000"/>
                  <path d="M41.923 9.076c0-1.76-1.445-3.344-3.756-4.471-.038-.02-.078-.039-.116-.057-.104-.048-.21-.092-.314-.137a14.903 14.903 0 00-3.393-.99c2.555.747 4.288 2.18 4.288 3.83 0 .012-.003.024-.003.03 0 .01.003.019.003.028 0 .128-.017.258-.043.386-.361 1.886-2.989 3.415-6.427 3.831l.017-.946-7.9 2.731 7.791 3.013.021-1.168c5.606-.565 9.832-3.07 9.832-6.08z" fill="#D31920"/>
                  <path d="M5.825 31.651c-2.151 0-4.094-.229-5.825-1.906l2.229-2.158c1.129 1.09 2.6 1.246 3.648 1.246 1.18 0 2.416-.382 2.416-1.374 0-.658-.369-1.118-1.443-1.216l-2.151-.203c-2.47-.229-3.99-1.274-3.99-3.71 0-2.743 2.493-4.217 5.274-4.217 2.125 0 3.91.356 5.22 1.55L9.108 21.72c-.789-.686-1.995-.89-3.176-.89-1.367 0-1.943.61-1.943 1.269 0 .485.21 1.045 1.42 1.145l2.15.204c2.701.253 4.069 1.652 4.069 3.885-.003 2.921-2.577 4.318-5.802 4.318zM27.172 31.497h-2.807l-2.86-8.306-2.861 8.306h-2.808l-4.2-13.234h3.622l2.205 8.178 2.78-8.178h2.493l2.807 8.178 2.206-8.178h3.596l-4.173 13.234zM32.072 31.497V18.263h3.411v13.234h-3.411zM42.541 31.498c-2.779 0-3.962-1.907-3.962-3.785v-7.04h-1.44v-2.515h1.44v-1.687l3.412-1.124v2.811h2.413v2.515h-2.413v6.836c0 .761.366 1.196 1.178 1.196h1.235l-.448 2.793H42.54zM51.144 31.65c-2.732 0-6.116-1.421-6.116-6.781 0-5.363 3.386-6.76 6.116-6.76 1.889 0 3.305.56 4.512 1.781l-2.31 2.233c-.705-.736-1.31-1.042-2.202-1.042-.815 0-1.443.281-1.941.864-.527.636-.763 1.522-.763 2.921 0 1.397.236 2.309.763 2.947.498.582 1.128.861 1.94.861.893 0 1.498-.304 2.204-1.04l2.309 2.213c-1.207 1.22-2.623 1.803-4.512 1.803zM64.588 31.498V23.42c0-1.753-1.152-2.336-2.229-2.336-1.076 0-2.205.61-2.205 2.336v8.078H56.74V13.41h3.414v6.068c.918-.914 2.07-1.371 3.282-1.371 2.992 0 4.564 2.034 4.564 4.827v8.56h-3.412v.003z" fill="#000"/>
                </svg>
              </Icon>
              <span class="header__vest">vest</span>
            </a>
          </div>
          <div class="header__itm">
            <div class="header__unit">
              <p class="header__unit-name">{{ currentMod.name }}</p>
              <p class="header__unit-location">{{ currentMod.meta.location }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="header__col">
        <div class="header__outer header__outer--right">
          <div class="header__itm header--bordered">
            <div class="header__options">
              <div class="header__options--col">
                <a v-tooltip="`unit details`" class="header__icon" href="#" @click.prevent="showUnitDetails" v-click-outside="closePopupUnit">
                  <Icon class="icon--info">
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z"/>
                    </svg>
                  </Icon>
                </a>
              </div>
            </div>
          </div>
          <div class="header__itm header--options">
            <div class="header__options">
              <div class="header__options--col">
                <Timer :startTime="currentSession.startTime"/>
              </div>
              <div class="header__options--col">
                <a @click.prevent="changeMute" class="header__icon" href="#">
                  <Icon v-tooltip="`mute`" v-if="!isMuted" class="icon--unmute">
                    <svg viewBox="0 0 14 19" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 0C7.79565 0 8.55871 0.316071 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3V9C10 9.79565 9.68393 10.5587 9.12132 11.1213C8.55871 11.6839 7.79565 12 7 12C6.20435 12 5.44129 11.6839 4.87868 11.1213C4.31607 10.5587 4 9.79565 4 9V3C4 2.20435 4.31607 1.44129 4.87868 0.87868C5.44129 0.316071 6.20435 0 7 0ZM14 9C14 12.53 11.39 15.44 8 15.93V19H6V15.93C2.61 15.44 0 12.53 0 9H2C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9H14Z"/>
                    </svg>
                  </Icon>
                  <Icon v-tooltip="`unmute`" v-else class="icon--mute">
                    <svg viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 9C16 10.19 15.66 11.3 15.1 12.28L13.87 11.05C14.14 10.43 14.3 9.74 14.3 9H16ZM12 9.16L6 3.18V3C6 2.20435 6.31607 1.44129 6.87868 0.87868C7.44129 0.316071 8.20435 0 9 0C9.79565 0 10.5587 0.316071 11.1213 0.87868C11.6839 1.44129 12 2.20435 12 3V9.16ZM1.27 1L18 17.73L16.73 19L12.54 14.81C11.77 15.27 10.91 15.58 10 15.72V19H8V15.72C4.72 15.23 2 12.41 2 9H3.7C3.7 12 6.24 14.1 9 14.1C9.81 14.1 10.6 13.91 11.31 13.58L9.65 11.92L9 12C8.20435 12 7.44129 11.6839 6.87868 11.1213C6.31607 10.5587 6 9.79565 6 9V8.28L0 2.27L1.27 1Z"/>
                    </svg>
                  </Icon>
                </a>
              </div>
              <div class="header__options--col">
                <a @click.prevent="changeVolume" class="header__icon" href="#">
                  <Icon v-tooltip="`turn off the volume`" v-if="volume" class="icon--volume">
                    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 0.230011V2.29001C13.89 3.15001 16 5.83001 16 9.00001C16 12.17 13.89 14.84 11 15.7V17.77C15 16.86 18 13.28 18 9.00001C18 4.72001 15 1.14001 11 0.230011ZM13.5 9.00001C13.5 7.23001 12.5 5.71001 11 4.97001V13C12.5 12.29 13.5 10.76 13.5 9.00001ZM0 6.00001V12H4L9 17V1.00001L4 6.00001H0Z"/>
                    </svg>
                  </Icon>
                  <Icon v-tooltip="`turn on the volume`" v-else class="icon--unvolume">
                    <svg viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 5H4L9 0V16L4 11H0V5ZM13.59 8L11 5.41L12.41 4L15 6.59L17.59 4L19 5.41L16.41 8L19 10.59L17.59 12L15 9.41L12.41 12L11 10.59L13.59 8Z"/>
                    </svg>
                  </Icon>
                </a>
              </div>
              <div class="header__participants" v-if="sessionParticipants.length">
                <ParticipantsShort
                  :sessionParticipants="sessionParticipants"
                  :clickHandler="toggleParticipantsList"
                  :participantsListVisible="participantsListVisible"
                  v-click-outside="hideParticipantsList"/>
                <ParticipantsFull
                  v-show="participantsListVisible"
                  :sessionParticipants="sessionParticipants"
                  @click.stop />
              </div>
              <div class="header__options--col">
                <Btn v-if="false" v-tooltip="`leave the session`" class-name="btn--red" @clickAction="leaveRoom">Leave</Btn>
                <BtnSplit
                  class-name="btn--red"
                  :leaveSession="leaveRoom"
                  :closeSession="confirmCloseSession"
                  :sessionModX="true"
                  ref="btnSplit">
                  Leave
                </BtnSplit>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupPodDetails v-if="showPopupUnit" :close="closePopupUnit"/>
    <Popup v-if="showPopupLeave">
      <template slot="header">
        <p class="modal__title">End session?</p>
      </template>
      <template slot="body">
        <p class="modal__text">Are you sure you want to end this session for all participants?</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link modal__link--red" @click.prevent="closeSession">END SESSION</a>
          </div>
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupLeave">Cancel</a>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/common/icon/Icon'
import Btn from '@/common/btn/Btn'
import Timer from '@/common/timer/Timer'
import VTooltip from 'v-tooltip'
import PopupPodDetails from '@/common/popup-pod-details/PopupPodDetails'
import ParticipantsShort from '@/components/participants-short/ParticipantsShort'
import ParticipantsFull from '@/components/participants-full/ParticipantsFull'
import Popup from '@/common/popup/Popup'
import ClickOutside from 'vue-click-outside'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BtnSplit from '../header-session-room/btn-split/BtnSplit'

Vue.use(VTooltip)

export default {
  name: 'HeaderPodSessionRoom',
  data () {
    return {
      showPopupUnit: false,
      showPopupLeave: false,
      participantsListVisible: false,
      shouldPreventLeaving: false
    }
  },
  computed: {
    ...mapGetters([
      'isMuted',
      'volume',
      'user',
      'topics',
      'publishedStreamName',
      'currentSession',
      'sessionParticipants',
      'currentMod'
    ])
  },
  components: {
    Icon,
    Btn,
    BtnSplit,
    PopupPodDetails,
    Timer,
    ParticipantsShort,
    Popup,
    ParticipantsFull
  },
  directives: {
    ClickOutside
  },
  methods: {
    ...mapActions([
      'sendCloseSession',
      'sendLeaveSession',
      'changeMute',
      'changeVolume'
    ]),
    ...mapMutations([
      'setMute',
      'setVolume'
    ]),
    leaveRoom () {
      this.sendLeaveSession()
      this.$router.push({ path: '/' })
    },
    closeSession () {
      this.sendCloseSession()
      this.$router.push({ path: '/' })
    },
    closePopupUnit () {
      this.showPopupUnit = false
    },
    closePopupLeave () {
      this.showPopupLeave = false
      this.$refs.btnSplit.close()
    },
    confirmCloseSession () {
      this.showPopupLeave = true
    },
    showUnitDetails () {
      this.showPopupUnit = true
    },
    toggleParticipantsList (value) {
      this.participantsListVisible = value
    },
    hideParticipantsList (event) {
      if (event.target.closest('.participants-full')) return false
      this.participantsListVisible = false
    }
  }
}

</script>
