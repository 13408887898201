<template>
  <div class="recording-page">
    <template v-if="loggedIn">
      <HeaderRecordingDetails
        v-if="recordingDetails"
        :name="recordingDetails.session.unit.name"
        :location="recordingDetails.session.unit.location"
        :startedAt="recordingDetails.session.startedAt ? new Date(recordingDetails.session.startedAt) : null"
        :duration="recordingDetails.session.duration" />
      <RecordingInfo
        v-if="recordingDetails"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import HeaderRecordingDetails from '@/components/header-recording-details/HeaderRecordingDetails'
import RecordingInfo from '@/components/recording-info/RecordingInfo'

export default {
  name: 'RecordingDetails',
  components: {
    HeaderRecordingDetails,
    RecordingInfo
  },
  data () {
    return {
      id: this.$route.params.id
    }
  },
  created () {
    Promise.all([this.getMediaList(this.id), this.getRecordingDetails(this.id)])
      .then(() => this.getDownloadUrl())
      .catch(e => console.error(`Error in getting media data: ${e}`))
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'recordingDetails'
    ])
  },
  methods: {
    ...mapActions([
      'getRecordingDetails',
      'getMediaList',
      'getDownloadUrl'
    ]),
    ...mapMutations([
      'setRecordingDetails'
    ])
  },
  destroyed () {
    this.setRecordingDetails(null)
  }
}
</script>

<style lang="scss" scoped>
  .recording-page {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
