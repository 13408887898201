<template>
  <div>
  <div class="controls">
    <div class="controls__group">
      <CameraBtn v-for="camera in currentPodDevices.filter(camera => camera.meta.orientation === 'BACK')"
        class="camera-btn"
        @doubleClickAction="makePrimary"
        @clickAction="turnOn"
        :key="camera.id"
        :id="camera.id"
        :containerId="camera.containerId"
        :status="camera.status"
        :isOn="camera.isOn"
        :position="camera.meta.position"
        :orientation="camera.meta.orientation"
        :isPrimary="camera.id === primaryIpCameraId"
        :isHovered="camera.id === hoveredIpCameraId"/>
    </div>
    <div class="controls__group">
      <div v-for="box in [1, 2, 3]" :key="box" class="box"><span class="box__dots">..</span></div>
    </div>
    <div class="controls__group--info">
      <span><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 0L8 8H0L4 0Z" fill="white" />
      </svg></span>
      <span style="margin-left: 10px; margin-right: 10px">FRONT</span>
      <span><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 0L8 8H0L4 0Z" fill="white" />
      </svg></span>
    </div>
    <div class="controls__group">
      <CameraBtn v-for="camera in currentPodDevices.filter(camera => camera.meta.orientation === 'FRONT')"
        class="camera-btn"
        @doubleClickAction="makePrimary"
        @clickAction="turnOn"
        :key="camera.id"
        :id="camera.id"
        :containerId="camera.containerId"
        :status="camera.status"
        :isOn="camera.isOn"
        :position="camera.meta.position"
        :orientation="camera.meta.orientation"
        :isPrimary="camera.id === primaryIpCameraId"
        :isHovered="camera.id === hoveredIpCameraId"/>
    </div>
  </div>
    <div class="info-tip">
      <span>double click to make primary</span>
    </div>
  </div>
</template>

<script>
import CameraBtn from './CameraBtn'
import {
  mapActions,
  mapGetters
} from 'vuex'

export default {
  name: 'PodsControls',
  components: {
    CameraBtn
  },
  data: function () {
    return {
      cameraList: []
    }
  },
  computed: {
    ...mapGetters([
      'amIHost',
      'mediaInfoPod',
      'currentPod',
      'primaryIpCameraId',
      'hoveredIpCameraId',
      'mod',
      'currentPodDevices'
    ])
  },
  methods: {
    ...mapActions([
      'setPrimaryIpCameraId',
      'removeModxCamFromPod',
      'closeSession'
    ]),
    ...mapActions('ModxSession', ['sendCommand']),
    turnOn ({ id, status, isOn, containerId }) {
      if (!this.amIHost || id === this.primaryIpCameraId || !status) return

      if (isOn) {
        this.sendCommand({
          deviceType: 'IP_CAMERA',
          unitId: containerId,
          deviceId: id,
          commandName: 'INVITE'
        })
      } else {
        this.sendCommand({
          deviceType: 'IP_CAMERA',
          unitId: containerId,
          deviceId: id,
          commandName: 'KICK'
        })
        this.removeModxCamFromPod(id)
      }
    },
    makePrimary ({ id, status, isOn, containerId }) {
      if (id === this.primaryIpCameraId || !status) return
      if (!this.amIHost && !isOn) return

      if (!isOn) this.turnOn({ id, status, isOn: !isOn, containerId })
      this.setPrimaryIpCameraId(id)
    }
  },
  watch: {
    mediaInfoPod () {
      const isOnMap = this.mediaInfoPod.reduce((map, item) => {
        if (item.streams) {
          map[item.uuid] = true
        }
        return map
      }, {})
      // this.cameraList = this.cameraList.map(camera => {
      //   if (isOnMap[camera.id]) {
      //     camera.isOn = true
      //   } else {
      //     camera.isOn = false
      //   }
      //   if (!this.primaryIpCameraId && camera.meta.orientation === 'FRONT' && camera.meta.position === 'CENTER') {
      //     this.setPrimaryIpCameraId(camera.id)
      //   }
      //   return camera
      // })
      // this.cameraList = JSON.parse(JSON.stringify(this.cameraList))
      this.$store.commit('handlePodCameraOn', isOnMap)
    }
  },
  mounted () {
    this.cameraList = this.currentPod.devices.filter(device => device.type === 'IP_CAMERA')
    // this.cameraList = JSON.parse(JSON.stringify(this.cameraList))
    // this.cameraList.forEach(camera => {
    //   if (camera.status && camera.meta.orientation === 'FRONT' && camera.meta.position === 'CENTER') {
    //     camera.isOn = true
    //     this.setPrimaryIpCameraId(camera.id)
    //   } else {
    //     camera.isOn = false
    //   }
    // })
  }
}

</script>

<style lang="scss">
  .controls {
    height: 130px;
    width: 200px;
    background-color: rgba(1, 1, 1, 0.3);
    border: 3px solid rgba(247, 245, 245, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .controls__group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: stretch;
    align-items: flex-start;
    &:first-child {
      transform: translate(0, -50%);
    }
    &:last-child {
      transform: translate(0, 50%);
    }
    &--info{
      padding: 6px;
      margin: -18px;
      height: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      user-select: none;
      color: white;
      font-weight: bold;
      justify-content: center;
    }
  }

  .box {
    width: 52px;
    height: 32px;
    color: white;
    opacity: 0.6;
    border: 2px solid #FFFFFF;
    &__dots {
      user-select: none;
      transform: translate(35px, -10px);
      position: absolute;
    }
  }

  .info-tip {
    display: none;
    position: relative;
    top: 20px;
    // transform: translate(0, 100%);
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    user-select: none;
    color: white;
    font-weight: bold;
    justify-content: center;
  }
  .controls:hover + .info-tip {
    display: block;
  }

</style>
