<template>
  <div class="devices">
    <div class="devices__outer" :class="devicesOuterClass">
      <div v-if="isUnits" class="devices__itm">
        <h2 class="devices__ttl">Units</h2>
      </div>
      <div class="devices__itm devices__itm--inner">
        <Organizations
          v-if="selectedOrganization"
          :organizations="organizations"
          :selectedOption="selectedOrganization"
        />
        <Btn v-if="isUnits" class-name="btn--red btn--icon" @clickAction="addUnit">
          <Icon class="icon--plus">
            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white"/>
            </svg>
          </Icon>
          add unit
        </Btn>
      </div>
    </div>
    <v-table v-if="isUnits" :hideSortIcons="true" class="table table--device-inventory" :data="formattedUnits">
      <thead slot="head">
        <tr>
          <th class="table__status-sort">
            <span class="table__caption">Type</span>
          </th>
          <v-th sortKey="name" default-sort="asc">
            <span class="table__caption">Name</span>
          </v-th>
          <v-th sortKey="meta.location">
            <span class="table__caption">Location</span>
          </v-th>
          <th>
            <span class="table__caption">Description</span>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody slot="body" slot-scope="{displayData}">
        <tr v-for="unit in displayData" :key="unit.id" @click.prevent="editUnit(unit)">
          <td class="table__td--type">
            <div class="table__devices">
              <div v-if="currentCamUnit(unit.devices)" class="table__devices--col">
                <Icon class="icon--smartCart">
                  <svg viewBox="0 0 15 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 5V6H12V2H6V3V5ZM4 5V7C4 7.55228 4.44772 8 5 8H13C13.5523 8 14 7.55228 14 7V1C14 0.447715 13.5523 0 13 0H5C4.44772 0 4 0.447715 4 1V3H2C0.895431 3 0 3.89543 0 5V15H1V17C1 18.1046 1.89543 19 3 19H13C14.1046 19 15 18.1046 15 17V12C15 10.8954 14.1046 10 13 10H3C2.63571 10 2.29417 10.0974 2 10.2676V5L4 5ZM1.00977 22C1.00977 20.9 1.89977 20 2.99977 20C4.09977 20 4.99977 20.9 4.99977 22C4.99977 23.1 4.09977 24 2.99977 24C1.89977 24 1.00977 23.1 1.00977 22ZM11.0098 22C11.0098 20.9 11.8998 20 12.9998 20C14.0998 20 14.9998 20.9 14.9998 22C14.9998 23.1 14.0998 24 12.9998 24C11.8998 24 11.0098 23.1 11.0098 22Z"/>
                  </svg>
                </Icon>
              </div>
              <div v-if="currentCamMobile(unit.devices)" class="table__devices--col">
                <Icon>
                  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.167 15.833H5.833V4.167h8.334v11.666zm0-15H5.833A1.66 1.66 0 004.167 2.5v15a1.667 1.667 0 001.666 1.667h8.334a1.666 1.666 0 001.666-1.667v-15A1.667 1.667 0 0014.167.833z"/>
                  </svg>
                </Icon>
              </div>
            </div>
          </td>
          <td class="table__td--name">
            <span class="table__name">{{ unit.name }}</span>
          </td>
          <td>
            <span class="table__location">{{ unit.meta.location }}</span>
          </td>
          <td>
            <span class="table__location">{{ unit.description }}</span>
          </td>
          <td class="table__btn--delete">
            <button @click.stop="confirmDeleteUnit(unit)" class="btn--delete">
              <Icon>
                <svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"/>
                </svg>
              </Icon>
            </button>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div v-else class="devices__wrap">
      <h2 class="devices__ttl">No devices</h2>
      <Btn class-name="btn--red btn--icon" @clickAction="addUnit">
        <Icon class="icon--plus">
          <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white"/>
          </svg>
        </Icon>
        add unit
      </Btn>
    </div>
    <Popup v-if="showPopupDeleteUnit">
      <template slot="header">
        <p class="modal__caption">Delete {{ currentUnit.name }}</p>
      </template>
      <template slot="body">
        <p class="modal__text">Are you sure you want to delete this unit?</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link modal__link--red" @click.prevent="deleteUnit">Delete unit</a>
          </div>
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupDeleteUnit" v-click-outside="closePopupDeleteUnit">Cancel</a>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import SmartTable from 'vuejs-smart-table'
import Icon from '@/common/icon/Icon'
import Btn from '@/common/btn/Btn'
import Organizations from '@/components/organizations/Organizations'
import Popup from '@/common/popup/Popup'
import ClickOutside from 'vue-click-outside'

Vue.use(SmartTable)

export default {
  name: 'DeviceInventoryTable',
  data () {
    return {
      showPopupDeleteUnit: false,
      currentUnit: null
    }
  },
  components: {
    Icon,
    Btn,
    Organizations,
    Popup
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters([
      'units',
      'organizations',
      'selectedOrganization',
      'user'
    ]),
    formattedUnits () {
      const formattedUnits = this.units.filter(i => i.fullInfo === undefined || i.fullInfo)

      return formattedUnits
    },
    currentCamUnit () {
      return (devices) => devices !== undefined ? devices.find(device => device.type === 'CAM_UNIT') : undefined
    },
    currentCamMobile () {
      return (devices) => devices !== undefined ? devices.find(device => device.type === 'CAM_MOBILE') : undefined
    },
    isUnits () {
      return this.units.length
    },
    devicesOuterClass () {
      return !this.isUnits ? 'devices__outer--flexend' : null
    }
  },
  methods: {
    ...mapActions([
      'getOrganizationsList',
      'deleteUnitFromDIM',
      'setEditUnitPage',
      'setCurrentEditUnit'
    ]),
    confirmDeleteUnit (unit) {
      this.showPopupDeleteUnit = true
      this.currentUnit = unit
    },
    closePopupDeleteUnit () {
      this.showPopupDeleteUnit = false
    },
    deleteUnit () {
      this.showPopupDeleteUnit = false
      this.deleteUnitFromDIM(this.currentUnit)
    },
    addUnit () {
      this.$router.push({ name: 'AddUnitPage' })
    },
    editUnit (unit) {
      this.setEditUnitPage(true)
      this.setCurrentEditUnit(unit)
      this.$router.push({
        name: 'EditUnitPage',
        params: {
          unitName: unit.name
        }
      })
    }
  },
  mounted () {
    if (!this.selectedOrganization.name) {
      this.getOrganizationsList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .devices {
    padding: 15px 32px;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    &__outer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__outer--flexend {
      justify-content: flex-end;
    }
    &__ttl {
      font-family: 'CenturyGothic-Bold';
      color: #111111;
      padding-left: 24px;
      font-size: 32px;
    }
    &__itm--inner {
      display: flex;
      .btn--red {
        margin-left: 24px;
      }
    }
    &__wrap {
      text-align: center;
      margin: auto;
      .btn {
        width: 200px;
      }
      .devices__ttl {
        margin-bottom: 32px;
        padding-left: 0;
      }
    }
    .table__devices {
      margin: 0;
    }
  }
  .vt-sort:after{
    vertical-align: middle;
    display: inline-block;
    width: 13px;
    height: 15px;
    content: none;
  }
  .vt-asc {
    .table__caption {
      &:after{
        content: url('./arrowDown.svg');
      }
    }
  }
  .vt-desc {
    .table__caption {
      &:after{
        content: url('./arrowDown.svg');
      }
    }
  }
  .btn--delete {
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
    border: none;
    background: none;
    .icon {
      width: 14px;
    }
  }
</style>
