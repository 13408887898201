import stateMerge from 'vue-object-merge'

export default {
  namespaced: false,
  state: {
    loggedIn: false,
    acceptedRule: false,
    user: {
      application_metadata: null,
      email: null,
      picture: null,
      preferred_username: null,
      sub: null,
      updated_at: null,
      user_metadata: {
        organization_id: null,
        roles: []
      },
      name: null
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    loggedIn (state) {
      return state.loggedIn
    },
    acceptedRule (state) {
      return state.acceptedRule
    },
    isAdmin (state) { return state.user.user_metadata.roles.includes('vest:rma:admin') },
    isCommentor (state) { return state.user.user_metadata.roles.includes('vest:rma:read') && state.user.user_metadata.roles.includes('vest:rma:comment:write') },
    isWatcher (state) { return state.user.user_metadata.roles.includes('vest:rma:read') && !state.user.user_metadata.roles.includes('vest:rma:comment:write') },
    isSwitchAdmin (state) { return state.user.user_metadata.roles.includes('vest:inventory:unit:admin') },
    isOtherAdmin (state) { return state.user.user_metadata.roles.includes('vest:inventory:unit:write') }
  },
  mutations: {
    setUser (state, data) {
      stateMerge(state.user, data)
    },
    setLoggedIn (state, status) {
      state.loggedIn = status
    },
    setAcceptedRule (state, status) {
      state.acceptedRule = status
    }
  },
  actions: {
    setUser ({ commit }, data) {
      commit('setUser', data)
    },
    setLoggedIn ({ commit }, status) {
      commit('setLoggedIn', status)
    },
    setAcceptedRule ({ commit }, status) {
      commit('setAcceptedRule', status)
    }
  }
}
