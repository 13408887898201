<template>
  <i class="icon" @click.prevent="handleClick">
    <slot/>
  </i>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    handleClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon {
    display: block;
    margin: 0 auto;
    width: 20px;
    fill: #111;
    svg {
      display: block;
    }
    &--red {
      fill: #d71921;
    }
    &--details {
      width: 25px;
    }
    &--logo {
      width: 68px;
      height: 32px;
    }
    &--logout {
      width: 18px;
      height: 20px;
      margin: 0 15px 0 0;
    }
    &--session {
      fill: #D31920;
      width: 18px;
    }
    &--close {
      width: 14px;
      fill: #111;
    }
    &--sort {
      width: 13px;
      height: 13px;
      display: none;
      vertical-align: middle;
      fill: rgba(17,17,17,0.1);
    }
    &--loading {
      width: 40px;
      height: 40px;
      fill: #D31920;
    }
    &--mute {
      width: 18px;
    }
    &--unmute {
      width: 14px;
    }
    &--no-microphone {
      width: 14px;
      fill: rgba(17,17,17,0.1);
    }
    &--volume {
      width: 18px;
    }
    &--unvolume {
      width: 19px;
    }
    &--expand {
      width:18px;
      height: 18px;
      fill: #fff;
    }
    &--collapse {
      width:23px;
      height: 23px;
      fill: #fff;
    }
    &--primary {
      width: 40px;
      height: 40px;
    }
    &--nightmode {
      width: 24px;
    }
    &--focus {
      width: 22px;
    }
    &--plus {
      width: 14px;
    }
    &--minus {
      width: 14px;
    }
    &--reset {
      width: 21px;
    }
    &--zoom {
      width: 22px;
    }
    &--warning {
      width: 16px;
    }
    &--edge {
      width: 24px;
    }
    &--record {
      width: 24px;
      margin-right: 16px;
    }
    &--guide {
      width: 22px;
      height: 18px;
      margin: 0 15px 0 0;
    }
    &--arrow {
      width: 15px;
      height: 15px;
    }
    &--smartCart {
      width: 13px;
    }
  }
</style>
