<template>
  <transition name="fade">
    <div class="modal__overlay">
      <div class="modal" @click.stop="">
        <div class="modal__outer">
          <div class="modal__itm">
            <div class="modal__header">
              <slot name="header"></slot>
            </div>
            <div class="modal__body">
              <slot name="body"/>
            </div>
          </div>
          <div class="modal__itm">
            <slot name="buttons"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Popup',
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
  .modal--small {
    .modal {
      width: 360px;
    }
  }
  .modal--help {
    .modal {
      width: 360px;
    }
    .modal__text {
      margin-top: 10px;
    }
  }
  .modal {
    min-width: 360px;
    background: #FFFFFF;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12);
    padding: 25px;
    color: #111111;
    margin: auto;
    position: relative;
    &__outer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__overlay {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
    }
    &__title {
      font-family: "CenturyGothic-Bold";
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 1px;
    }
    &__caption {
      font-family: "CenturyGothic-Bold";
      font-size: 20px;
    }
    &__user {
      font-family: "CenturyGothic-Bold";
      font-size: 20px;
      margin-bottom: 30px;
    }
    &__text {
      font-size: 16px;
    }
    &__info {
      margin-bottom: 5px;
      font-size: 20px;
    }
    &__row {
      display: flex;
      align-items: center;
      margin: 0 -15px;
    }
    &__row--right {
      justify-content: flex-end;
    }
    &__col {
      padding: 0 15px;
    }
    &__link {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #111111;
      text-decoration: none;
      display: inline-block;
    }
    &__url {
      color: rgba(17, 17, 17, 0.6);
      text-decoration: none;
      &:hover {
        color: #D31920;
      }
    }
    &__link--flex {
      display: flex;
      align-items: center;
    }
    &__link--red {
      color: #D31920;
    }
    &__header {
      margin-bottom: 15px;
    }
    &__body {
      margin-bottom: 40px;
    }
    &__li {
      display: block;
      &+& {
        margin-top: 25px;
      }
    }
    &__close {
      cursor: pointer;
      position: absolute;
      top: 30px;
      right: 30px;
      display: block;
    }
  }
</style>
