<template>
  <div class="btn-split"
    v-click-outside="close">
    <button class="btn btn--red" @click="leaveSession"><slot/></button>
    <div v-if="internetConnection || sessionModX" class="dropdown">
      <a
        @click.prevent="handleShowDropdownContent"
        class="btn btn--red dropdown__btn"
        style="border-left:1px solid rgba(0, 0, 0, .1)">
        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0L5 5L10 0H0Z" fill="white" />
        </svg>
      </a>
      <div class="dropdown-content" :class="{active: showMenu}">
        <a href="#" @click.prevent="leaveSession">
          <svg class="icon" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 15V12H6V8H13V5L18 10L13 15ZM11 0C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V4H11V2H2V18H11V16H13V18C13 18.5304 12.7893 19.0391 12.4142 19.4142C12.0391 19.7893 11.5304 20 11 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H11Z"
              fill="#111111" />
          </svg>
          <span><slot/></span>
        </a>
        <a href="#" @click.prevent="closeSession">
          <svg class="icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="#111111" />
          </svg>
          <span>End Session</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'
export default {
  name: 'BtnSPlit',
  data () {
    return {
      showMenu: false
    }
  },
  directives: {
    ClickOutside
  },
  props: {
    leaveSession: Function,
    closeSession: Function,
    sessionModX: Boolean
  },
  computed: {
    ...mapGetters([
      'wifiConnection',
      'connectedStatus',
      'currentUnit'
    ]),
    internetConnection () {
      return this.connectedStatus && this.wifiConnection && this.currentUnit?.session?.status === 'IN_SESSION'
    }
  },
  methods: {
    handleShowDropdownContent () {
      this.showMenu = !this.showMenu
    },
    close () {
      this.showMenu = false
    }
  }
}

</script>

<style lang="scss" scoped>
  .dropdown {
    position: relative;
  }

  .dropdown-content {
    right: 1px;
    display: none;
    position: absolute;
    background: #ffffff;
    box-shadow: 0 16px 24px 0 rgba(0,0,0,0.14);
    min-width: 200px;
    z-index: 300;
  }

  .dropdown-content a {
    text-transform: uppercase;
    padding: 15px 16px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    color: #111111;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 20px;
    width: 18px;
  }

  .dropdown-content a:hover {
    background-color: #ddd
  }

  .btn.dropdown__btn {
    padding: 9px 11px;
    height: 100%;
  }
  .dropdown__btn + .dropdown-content.active,
  .dropdown__btn:focus + .dropdown-content {
    display: block;
  }

  .btn {
    cursor: pointer;
    display: inline-block;
    margin: 0 auto;
    padding: 11px 11px;
    border: 2px solid rgba(17, 17, 17, 0.2);
    font-size: 14px;
    border-radius: 0;
    font-weight: 600;
    background: transparent;
    outline: 0;
    text-transform: uppercase;
    text-align: center;
    color: #D31920;
    letter-spacing: 1px;
    transition: 0.2s;

    &:active {
      border-color: #C51A21;
      background: #C51A21;
      color: #fff;
    }

    &:hover {
      &:not([disabled]) {
        border-color: #D31920;
      }
    }

    &.btn--red {
      color: #ffffff;
      background: #D31920;
      border: none;

      &:active {
        background: #C51A21;
      }

      &:hover {
        &:not([disabled]) {
          background: #DB3037;
        }
      }

      &:disabled {
        background: #F5F5F5;
        color: rgba(17, 17, 17, 0.4);
      }
    }
  }

  .btn-split {
    display: flex;
  }
</style>
