<template>
  <Loading v-if="loading" />
  <div v-else class="info">
    <div class="info__back-to-list">
      <a href="#" @click.prevent="backToRecordingList">
        <Icon class="icon--arrow">
          <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0002 7.00008V9.00008H4.00016L9.50016 14.5001L8.08016 15.9201L0.160156 8.00008L8.08016 0.0800781L9.50016 1.50008L4.00016 7.00008H16.0002Z" fill="#D31920"/>
          </svg>
        </Icon>
        <span>to list</span>
      </a>
    </div>
    <div class="info__main--wrapper">
        <div class="info__main">
        <div class="main__row">
          <div class="row__player">
            <div v-if="!loading && listLength" class="player__wrapper">
              <div class="player__wrapper--current__video" v-if="currentMedia">
                <div class="current__video--size">
                  <span>{{secToHm(currentMedia.duration)}}</span>
                  <span>{{bytesToSize(currentMedia.size)}}</span>
                </div>
                <!-- <div class="current__video--controls">
                  <a class="current__video--download" @click.prevent="download" :href="downloadRecordingUrl" download target="_blank">
                    <Icon>
                      <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" fill="white"/>
                      </svg>
                    </Icon>
                    <span>download</span>
                  </a>
                </div> -->
                <div class="current__video--controls">
                  <button
                    v-if="isAdmin"
                    @click="confirmDelete"
                    v-click-outside="closePopupDelete">
                    <Icon>
                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z" fill="white"/>
                      </svg>
                    </Icon>
                    <span>delete</span>
                  </button>
                </div>
              </div>
              <media-player ref="player" />
            </div>
            <div v-else class="player__empty">
              <Icon>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.50023 13.0002H9.50023V15.0002H7.50023V13.0002Z" fill="#111111"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0181 20.9323L20.793 21.7072L22.2072 20.293L4.20718 2.29297L2.79297 3.70718L3.56782 4.48203C3.52363 4.64726 3.50008 4.82092 3.50008 5.00008V19.0001C3.50008 20.1046 4.39551 21.0001 5.50008 21.0001H19.5001C19.6792 21.0001 19.8529 20.9765 20.0181 20.9323ZM18.0859 19.0001L17.5002 18.4144V19.0001H18.0859ZM16.086 17.0002L9.50023 10.4144V11.0002H7.50023V9.00023H8.08601L5.50008 6.41429L5.50008 19.0001H7.50023V17.0002H9.50023V19.0001H15.5002V17.0002H16.086Z" fill="#111111"/>
                  <path d="M21.5001 5.00008V17.5859L19.5001 15.5859V5.00008H8.91429L6.91429 3.00008H19.5001C20.6046 3.00008 21.5001 3.89551 21.5001 5.00008Z" fill="#111111"/>
                  <path d="M17.5002 5.00023H15.5002V7.00023H17.5002V5.00023Z" fill="#111111"/>
                  <path d="M15.5002 9.00023H17.5002V11.0002H15.5002V9.00023Z" fill="#111111"/>
                </svg>
              </Icon>
              <span>Videos were deleted</span>
            </div>
          </div>
        </div>
        <div class="main__row justify--spb">
          <div class="main__row--left-section">
            <textarea v-if="isCommentor || isWatcher"
              ref="description"
              :value="note"
              :disabled="isWatcher"
              @change="handleComment"
              cols="30" rows="1"
              placeholder="Description" />
          </div>
          <div class="main__row--right-section">
            <span>Participants:</span>
            <div class="right__section--participants"
              v-if="participants.length">
                <ParticipantsShort
                  :sessionParticipants="participants"
                  :clickHandler="toggleParticipantsList"
                  :participantsListVisible="participantsListVisible"
                  v-click-outside="hideParticipantsList"
                />
                <ParticipantsFull
                  v-show="participantsListVisible"
                  :sessionParticipants="participants"
                  @click.stop/>
            </div>
            <button v-if="isAdmin" @click="confirmDeleteSession" v-click-outside="closePopupDeleteSession">delete session</button>
          </div>
        </div>
        <div class="main__row">
          <div class="row__id">
            <div class="row__id--session">
              <span>Session ID :</span>
              <p>{{recordingInfo.recordingId || ''}}</p>
            </div>
            <div class="row__id--unit">
              <span>Unit ID :</span>
              <p>{{recordingInfo.session.unit.id || ''}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="info__media-list">
        <h3>Videos <span>{{listLength}}</span></h3>
        <div class="info__media-list--controls">
          <!-- <button class="media-list__controls--download">
            <Icon>
              <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" fill="white"/>
              </svg>
            </Icon>
            download all
          </button> -->
          <button
            v-if="mediaList.length && isAdmin"
            class="media-list__controls--delete"
            @click="confirmDeleteAll"
            v-click-outside="closePopupDeleteAll">delete all</button>
        </div>
        <media-list :mediaList="mediaList" @mediaChanged="mediaChanged" />
      </div>
    </div>
    <Popup v-if="showPopupDelete" class="modal--small">
      <template slot="header">
        <p class="modal__caption">Delete this video?</p>
      </template>
      <template slot="body">
        <p class="modal__text">Video will be deleted forever and you won’t be able to restore it.</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupDelete">Cancel</a>
          </div>
          <div class="modal__col">
            <Btn class-name="btn btn--white" :action="deleteVideo" @clickAction="deleteVideo">delete video</Btn>
          </div>
        </div>
      </template>
    </Popup>
    <Popup v-if="showPopupDeleteAll" class="modal--small">
      <template slot="header">
        <p class="modal__caption">Delete all videos?</p>
      </template>
      <template slot="body">
        <p class="modal__text">All attached videos will be deleted forever and you won’t be able to restore it.</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupDeleteAll">Cancel</a>
          </div>
          <div class="modal__col">
            <Btn class-name="btn btn--white" :action="deleteAll" @clickAction="deleteAll">delete video</Btn>
          </div>
        </div>
      </template>
    </Popup>
    <Popup v-if="showPopupDeleteSession" class="modal--small">
      <template slot="header">
        <p class="modal__caption">Delete this session?</p>
      </template>
      <template slot="body">
        <p class="modal__text">All attached meta information and videos will be deleted forever and you won’t be able to restore it.</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupDeleteSession">Cancel</a>
          </div>
          <div class="modal__col">
            <Btn class-name="btn btn--white" @clickAction="deleteSession">delete session</Btn>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import Icon from '@/common/icon/Icon'
import { formatDateTime, secToHm } from '@/helpers/date'
import { bytesToSize } from '@/helpers/size'
import ParticipantsShort from '@/components/participants-short/ParticipantsShort'
import ParticipantsFull from '@/components/participants-full/ParticipantsFull'
import ClickOutside from 'vue-click-outside'
import MediaList from '@/components/media-list/MediaList.vue'
import MediaPlayer from '@/components/media-player/MediaPlayer.vue'
import Popup from '@/common/popup/Popup'
import Btn from '@/common/btn/Btn'
import Loading from '@/common/loading/Loading'

export default {
  name: 'RecordingInfo',
  components: {
    Icon,
    ParticipantsShort,
    ParticipantsFull,
    MediaList,
    MediaPlayer,
    Popup,
    Btn,
    Loading
  },
  data () {
    return {
      participantsListVisible: false,
      showPopupDelete: false,
      showPopupDeleteAll: false,
      showPopupDeleteSession: false
    }
  },
  computed: {
    ...mapState({
      recordingInfo: state => state.recordingDetails.recordingDetails,
      mediaList: state => state.recordingDetails.mediaList,
      currentMediaId: state => state.recordingDetails.currentMediaId,
      participants: state => state.recordingDetails.recordingDetails.participants.list,
      loading: state => state.recordingDetails.loading
    }),
    ...mapGetters(['currentMedia', 'isAdmin', 'isCommentor', 'isWatcher', 'downloadRecordingUrl', 'note']),
    listLength () {
      return this.mediaList.length
    }
  },
  directives: {
    ClickOutside
  },
  destroyed () {
    this.clearCurrentMediaId('')
    this.setDownloadRecordingUrl('')
  },
  methods: {
    ...mapActions([
      'deleteMediaFile',
      'clearCurrentMediaId',
      'deleteAllMediaFile',
      'addComment',
      'getDownloadUrl',
      'deleteSessionRecording'
    ]),
    ...mapMutations([
      'setDownloadRecordingUrl'
    ]),
    download () {
      this.getDownloadUrl({ recordingId: this.recordingInfo.recordingId, currentMediaId: this.currentMediaId })
    },
    closePopupDelete () {
      this.showPopupDelete = false
    },
    closePopupDeleteSession () {
      this.showPopupDeleteSession = false
    },
    closePopupDeleteAll () {
      this.showPopupDeleteAll = false
    },
    confirmDeleteSession () {
      this.showPopupDeleteSession = true
    },
    confirmDelete () {
      this.showPopupDelete = true
    },
    confirmDeleteAll () {
      this.showPopupDeleteAll = true
    },
    toggleParticipantsList (value) {
      this.participantsListVisible = value
    },
    hideParticipantsList (event) {
      if (event.target.closest('.participants-full')) return false
      this.participantsListVisible = false
    },
    backToRecordingList () {
      this.$router.push({ path: '/recordings' })
    },
    deleteAll () {
      this.deleteAllMediaFile(this.recordingInfo.recordingId)
      this.showPopupDeleteAll = false
    },
    deleteVideo () {
      this.deleteMediaFile({ recordingId: this.recordingInfo.recordingId, currentMediaId: this.currentMediaId })
      this.showPopupDelete = false
    },
    deleteSession () {
      this.deleteSessionRecording(this.recordingInfo.recordingId)
      this.showPopupDeleteSession = false
      this.$router.push({ path: '/recordings' })
    },
    handleComment (event) {
      event.target.value !== this.recordingInfo.note &&
        this.addComment({ recordingId: this.recordingInfo.recordingId, note: event.target.value })
    },
    mediaChanged () {
      this.$refs.player.reset()
    },
    formatDateTime,
    secToHm,
    bytesToSize
  }
}
</script>

<style lang="scss" scoped>
.info,
.info__main,
.main__row,
.row__id,
.info__main--wrapper {
  display: flex;
}
.info {
  padding: 24px 25px;
  flex-wrap: nowrap;
  // justify-content: space-between;
  display: flex;
  &__main--wrapper {
    width: 100%;
  }
  i {
    display: inline-block;
    vertical-align: middle;
    svg {
      display: inline-block;
    }
  }
  textarea {
    width: 100%;
    min-height: 47px;
    box-sizing: border-box;
    font-family: "Open Sans";
    font-size: 16px;
    padding: 12px;
    background: #F5F5F5;
    border: none;
    border-bottom: 1px solid #999;
  }

  &__main {
    flex-grow: 1;
  }

  &__back-to-list {
    min-width: 75px;
    a {
      text-decoration: none;
    }

    i {
      margin-right: 12px;
    }
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #D31920;
      white-space: nowrap;
    }
  }
  &__media-list {
    min-width: 312px;

    &--controls {
      margin-bottom: 16px;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
      padding-top: 24px;

      span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
      }
    }
  }
}

.info__main {
  padding: 0 16px 0 21px;
  flex-direction: column;
}
.main__row {
  margin-bottom: 32px;
  &--left-section {
    max-width: 370px;
    width: 100%;
    padding-right: 50px;
  }
  .row__player {
    width: 100%;
    text-align: center;

    .player__wrapper {
      position: relative;
      &--current__video {
        position: absolute;
        top: 16px;
        right: 16px;
        padding: 12px 16px;
        background: rgba(17, 17, 17, 0.6);
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        z-index: 2;

        .current__video--size {
          span:first-child {
            padding-right: 16px;
          }
          span:last-child {
            padding-left: 16px;
            border-left: 1px solid #fff;
          }
        }
        .current__video--download {
          cursor: pointer;
          color: #fff;
          text-decoration: none;
        }
        .current__video--controls {
          button,
          a{
            padding: 0 16px;
            span {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  .player__empty {
    border: 1px solid #111111;
    padding: 48px 0;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 300;

    i {
      margin-right: 16px;
    }
  }
  .row__id {
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #999;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    &--session {
      margin-right: 32px;
    }
  }
  &--right-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .right__section--participants {
      position: relative;
      cursor: pointer;
      .participants-full {
        bottom: 100%;
        transform: translate(0);
        left: auto;
        right: 0;
        margin: 0 0 15px 0;
      }
    }

    button {
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: none;
      background: none;
      color: #111111;
      cursor: pointer;
    }
  }
}
.justify--spb {
  justify-content: space-between;
}
.media-list__controls--download,
.media-list__controls--delete,
.current__video--controls button {
  cursor: pointer;
  border: none;
  background: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
}
.media-list__controls--download {
  padding: 14px 16px 14px 21px;
  background: #D31920;
  color: #fff;
}
.current__video--controls button {
  color: #fff;
}
</style>
