export default {
  namespaced: false,
  state: {
    isMuted: true,
    volume: true
  },
  getters: {
    isMuted (state) {
      return state.isMuted
    },
    volume (state) {
      return state.volume
    }
  },
  mutations: {
    setMute (state, isMuted) {
      state.isMuted = isMuted
    },
    setVolume (state, volume) {
      state.volume = volume
    }
  },
  actions: {
    changeVolume ({ state, commit }) {
      commit('setVolume', !state.volume)
    },
    changeMute ({ state, commit, dispatch }) {
      commit('setMute', !state.isMuted)
      dispatch('exposeSessionDetails', { muted: state.isMuted })
    },
    muteByDefault ({ state, commit, dispatch }, value) {
      if (value) {
        commit('setMute', value)
        dispatch('exposeSessionDetails', { muted: value })
      }
    }
  }
}
