<template>
  <div class="head">
    <div class="head__row">
      <div class="head__col">
        <div class="head__outer">
          <div class="head__itm head__itm--logo">
            <Icon class="icon--logo">
              <svg viewBox='0 0 68 32' xmlns='http://www.w3.org/2000/svg'>
                <path d="M33.298 3.24L25.506.229l-.022 1.166c-5.605.565-9.832 3.072-9.832 6.082 0 1.758 1.443 3.344 3.755 4.469.04.02.08.041.12.057.1.048.208.094.312.14 1.336.575 2.585.857 3.393.991-2.555-.75-4.288-2.18-4.288-3.83 0-.01.005-.021.005-.032 0-.01-.005-.019-.005-.026 0-.13.014-.26.042-.388.362-1.886 2.992-3.415 6.427-3.831l-.018.946 7.903-2.731z" fill="#000000"/>
                <path d="M41.923 9.076c0-1.76-1.445-3.344-3.756-4.471-.038-.02-.078-.039-.116-.057-.104-.048-.21-.092-.314-.137a14.903 14.903 0 00-3.393-.99c2.555.747 4.288 2.18 4.288 3.83 0 .012-.003.024-.003.03 0 .01.003.019.003.028 0 .128-.017.258-.043.386-.361 1.886-2.989 3.415-6.427 3.831l.017-.946-7.9 2.731 7.791 3.013.021-1.168c5.606-.565 9.832-3.07 9.832-6.08z" fill="#D31920"/>
                <path d="M5.825 31.651c-2.151 0-4.094-.229-5.825-1.906l2.229-2.158c1.129 1.09 2.6 1.246 3.648 1.246 1.18 0 2.416-.382 2.416-1.374 0-.658-.369-1.118-1.443-1.216l-2.151-.203c-2.47-.229-3.99-1.274-3.99-3.71 0-2.743 2.493-4.217 5.274-4.217 2.125 0 3.91.356 5.22 1.55L9.108 21.72c-.789-.686-1.995-.89-3.176-.89-1.367 0-1.943.61-1.943 1.269 0 .485.21 1.045 1.42 1.145l2.15.204c2.701.253 4.069 1.652 4.069 3.885-.003 2.921-2.577 4.318-5.802 4.318zM27.172 31.497h-2.807l-2.86-8.306-2.861 8.306h-2.808l-4.2-13.234h3.622l2.205 8.178 2.78-8.178h2.493l2.807 8.178 2.205-8.178h3.596l-4.172 13.234zM32.072 31.497V18.263h3.412v13.234h-3.412zM42.541 31.498c-2.779 0-3.962-1.907-3.962-3.785v-7.04h-1.44v-2.515h1.44v-1.687l3.412-1.124v2.811h2.413v2.515h-2.413v6.836c0 .761.366 1.196 1.178 1.196h1.235l-.448 2.793H42.54zM51.144 31.65c-2.732 0-6.116-1.421-6.116-6.781 0-5.363 3.386-6.76 6.116-6.76 1.889 0 3.306.56 4.512 1.781l-2.31 2.233c-.705-.736-1.31-1.042-2.202-1.042-.815 0-1.443.281-1.941.864-.527.636-.763 1.522-.763 2.921 0 1.397.236 2.309.763 2.947.498.582 1.129.861 1.94.861.893 0 1.498-.304 2.204-1.04l2.31 2.213c-1.207 1.22-2.624 1.803-4.513 1.803zM64.588 31.498V23.42c0-1.753-1.152-2.336-2.229-2.336-1.076 0-2.205.61-2.205 2.336v8.078H56.74V13.41h3.414v6.068c.918-.914 2.07-1.371 3.282-1.371 2.992 0 4.564 2.034 4.564 4.827v8.56h-3.412v.003z" fill="#000000"/>
              </svg>
            </Icon>
            <span class="head__vest">vest</span>
          </div>
          <div class="head__itm">
            <span class="head__title">{{ headerType }}</span>
          </div>
        </div>
      </div>
      <div class="head__col">
        <a v-if="isEditUnitPage" class="head__link head__link--delete" @click.stop="confirmDeleteUnit">delete unit</a>
        <a class="head__link" @click="cancel">cancel</a>
        <Btn class-name="btn--red" @clickAction="saveUnit">save</Btn>
      </div>
    </div>
    <Popup v-if="showPopupDeleteUnit">
      <template slot="header">
        <p class="modal__caption">Delete {{ currentEditUnit.name }}</p>
      </template>
      <template slot="body">
        <p class="modal__text">Are you sure you want to delete this unit?</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link modal__link--red" @click.prevent="deleteUnit">Delete unit</a>
          </div>
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupDeleteUnit" v-click-outside="closePopupDeleteUnit">Cancel</a>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'
import Btn from '@/common/btn/Btn'
import Popup from '@/common/popup/Popup'
import ClickOutside from 'vue-click-outside'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HeaderVestUnit',
  data () {
    return {
      showPopupDeleteUnit: false
    }
  },
  props: {
    headerType: String
  },
  components: {
    Icon,
    Btn,
    Popup
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters([
      'isEditUnitPage',
      'currentEditUnit'
    ])
  },
  methods: {
    ...mapActions([
      'deleteUnitFromDIM'
    ]),
    saveUnit () {
      this.$emit('clickAction')
    },
    cancel () {
      this.$router.push({ name: 'DeviceInventory' })
    },
    confirmDeleteUnit () {
      this.showPopupDeleteUnit = true
    },
    closePopupDeleteUnit () {
      this.showPopupDeleteUnit = false
    },
    deleteUnit () {
      this.showPopupDeleteUnit = false
      this.deleteUnitFromDIM(this.currentEditUnit)
      this.$router.push({ name: 'DeviceInventory' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .head {
    margin-bottom: 20px;
    background: #fff;
    padding: 15px 25px;
    &__outer {
      display: flex;
      margin: 0 -20px;
      align-items: center;
    }
    &__itm {
      padding: 0 20px;
    }
    &__itm--logo {
      border-right: 1px solid #111111;
    }
    &__row {
      display: flex;
      margin: 0 -10px;
      align-items: center;
      justify-content: space-between;
    }
    &__link {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #111111;
      text-decoration: none;
      display: inline-block;
      margin-right: 34px;
      cursor: pointer;
    }
    &__link--delete {
      margin-right: 80px;
    }
    &__vest {
      font-size: 10px;
      text-transform: uppercase;
      color: #111111;
      text-align: center;
      letter-spacing: 4px;
      display: block;
    }
    &__title {
      font-family: "CenturyGothic-Bold";
      font-size: 20px;
      color: #111111;
      letter-spacing: 0.5px;
    }
    &__col {
      padding: 0 10px;
    }
    .btn--red {
      min-width: 96px;
    }
  }
</style>
