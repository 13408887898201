<template>
  <div class="player" ref="videoContainer">
    <LoaderIcon v-if="buffering" />
    <video
      ref="video"
      class="player__recording"
      type="video/webm"
      preload
      :src="downloadRecordingUrl" />
    <div class="video-controls" ref="videoControls">
      <div class="video-progress">
        <progress ref="progressBar" value="0" min="0"></progress>
        <input class="seek" ref="seek" value="0" min="0" type="range" step="1">
        <div class="seek-tooltip" ref="seekTooltip">00:00</div>
      </div>

      <div class="bottom-controls">
        <div class="left-controls">
          <button class="left-controls__play" @click="togglePlay">
            <Icon v-if="paused">
              <svg
                width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0.140625V14.1406L11 7.14063L0 0.140625Z" fill="#111111"/>
                </svg>
            </Icon>
            <Icon v-else >
               <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 14H12V0H8V14ZM0 14H4V0H0V14Z" fill="#111111"/>
                </svg>
            </Icon>
          </button>
          <div class="volume-controls">
            <button class="left-controls__mute" ref="volumeButton" @click="toggleMute">
              <Icon v-if="volumeMute">
                <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5H4L9 0V16L4 11H0V5ZM13.59 8L11 5.41L12.41 4L15 6.59L17.59 4L19 5.41L16.41 8L19 10.59L17.59 12L15 9.41L12.41 12L11 10.59L13.59 8Z" fill="#111111"/>
                </svg>
              </Icon>
              <Icon v-else>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 0.230469V2.29047C13.89 3.15047 16 5.83047 16 9.00047C16 12.1705 13.89 14.8405 11 15.7005V17.7705C15 16.8605 18 13.2805 18 9.00047C18 4.72047 15 1.14047 11 0.230469ZM13.5 9.00047C13.5 7.23047 12.5 5.71047 11 4.97047V13.0005C12.5 12.2905 13.5 10.7605 13.5 9.00047ZM0 6.00047V12.0005H4L9 17.0005V1.00047L4 6.00047H0Z" fill="#111111"/>
                </svg>
              </Icon>
            </button>
            <!-- <input class="left-controls__volume" ref="volume" value="1"
              data-mute="0.5" type="range" max="1" min="0" step="0.01"> -->
          </div>

          <div class="time">
            <time ref="timeElapsed">00:00</time>
            <span> / </span>
            <time ref="duration">00:00</time>
          </div>
        </div>

        <div class="right-controls">
          <button class="right-control__fullscreen" @click="toggleFullScreen">
            <Icon v-if="isFullscreen">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 9H14V11H11V14H9V9ZM0 9H5V14H3V11H0V9ZM3 0H5V5H0V3H3V0ZM14 3V5H9V0H11V3H14Z" fill="#111111"/>
              </svg>
            </Icon>
            <Icon v-else>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H5V2H2V5H0V0ZM9 0H14V5H12V2H9V0ZM12 9H14V14H9V12H12V9ZM5 12V14H0V9H2V12H5Z" fill="#111111"/>
              </svg>
            </Icon>
          </button>
          <a class="right-control__download"
            download
            target="_blank"
            @click="download"
            :href="downloadRecordingUrl">
            <Icon>
              <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" fill="#111111"/>
              </svg>
            </Icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { throttle } from 'lodash'
import Icon from '@/common/icon/Icon'
import LoaderIcon from '@/common/loader-icon/LoaderIcon'

export default {
  name: 'MediaPlayer',
  components: {
    Icon,
    LoaderIcon
  },
  data () {
    return {
      video: null,
      videoControls: null,
      paused: true,
      timeElapsed: null,
      duration: null,
      progressBar: null,
      seek: null,
      seekTooltip: null,
      volumeButton: null,
      volumeMute: false,
      volume: null,
      videoContainer: null,
      isFullscreen: false,
      showControlTimer: null,
      buffering: false
    }
  },
  computed: {
    ...mapGetters(['downloadRecordingUrl'])
  },
  mounted () {
    this.video = this.$refs.video
    this.videoControls = this.$refs.videoControls
    this.timeElapsed = this.$refs.timeElapsed
    this.duration = this.$refs.duration
    this.progressBar = this.$refs.progressBar
    this.seek = this.$refs.seek
    this.seekTooltip = this.$refs.seekTooltip
    this.volumeButton = this.$refs.volumeButton
    this.volume = this.$refs.volume
    this.videoContainer = this.$refs.videoContainer

    this.video.addEventListener('loadedmetadata', this.initializeVideo)
    this.video.addEventListener('timeupdate', this.updateTimeElapsed)
    this.video.addEventListener('timeupdate', this.updateProgress)
    this.video.addEventListener('volumechange', this.updateVolumeIcon)
    this.video.addEventListener('click', this.togglePlay)
    this.video.addEventListener('ended', this.handleEndVideo)
    this.video.addEventListener('mouseleave', this.hideControls)
    this.video.addEventListener('mousemove', this.handleMouseMove)
    this.video.addEventListener('waiting', this.handleWaiting)
    this.video.addEventListener('playing', this.handlePlaying)
    this.videoControls.addEventListener('mouseenter', this.showControls)
    this.videoControls.addEventListener('mouseleave', this.hideControls)
    this.videoControls.addEventListener('mousemove', this.handleMouseMove)
    this.seek.addEventListener('mousemove', this.updateSeekTooltip)
    this.seek.addEventListener('input', this.skipAhead)
    this.videoContainer.addEventListener('fullscreenchange', this.updateFullscreenButton)
    this.videoContainer.addEventListener('click', this.handleControls)

    // Slider color logic if needed
    // this.volume.addEventListener('input', this.updateVolume)
    // this.volume.style.setProperty('--value', this.volume.value)
    // this.volume.style.setProperty('--min', this.volume.min === '' ? '0' : this.volume.min)
    // this.volume.style.setProperty('--max', this.volume.max === '' ? '100' : this.volume.max)
    // this.volume.addEventListener('input', () => this.volume.style.setProperty('--value', this.volume.value))
  },
  methods: {
    togglePlay () {
      if (this.video.paused || this.video.ended) {
        this.video.play()
        this.paused = false
      } else {
        this.video.pause()
        this.paused = true
      }
    },
    handleEndVideo () {
      this.video.pause()
      this.paused = true
    },
    handleMouseMove: throttle(function () {
      if (!this.paused) {
        this.showControls()
        this.handleVisibility()
      }
    }, 100),
    handleVisibility () {
      if (this.showControlTimer) return
      this.showControlTimer = setTimeout(() => {
        this.hideControls()
        this.showControlTimer = null
      }, 3000)
    },

    formatTime (timeInSeconds) {
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)

      return {
        hours: result.substr(0, 2),
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2)
      }
    },

    initializeVideo () {
      const videoDuration = Math.round(this.video.duration)
      this.seek.setAttribute('max', videoDuration)
      this.progressBar.setAttribute('max', videoDuration)
      const time = this.formatTime(videoDuration)
      this.duration.innerText = `${time.hours}:${time.minutes}:${time.seconds}`
      this.duration.setAttribute('datetime', `${time.hours}h ${time.minutes}m ${time.seconds}s`)
    },

    // updateTimeElapsed indicates how far through the video
    // the current playback is by updating the timeElapsed element
    updateTimeElapsed () {
      const time = this.formatTime(Math.round(this.video.currentTime))
      this.timeElapsed.innerText = `${time.hours}:${time.minutes}:${time.seconds}`
      this.timeElapsed.setAttribute('datetime', `${time.hours} ${time.minutes}m ${time.seconds}s`)
    },

    // updateProgress indicates how far through the video
    // the current playback is by updating the progress bar
    updateProgress () {
      this.seek.value = Math.floor(this.video.currentTime)
      this.progressBar.value = Math.floor(this.video.currentTime)
    },

    // updateSeekTooltip uses the position of the mouse on the progress bar to
    // roughly work out what point in the video the user will skip to if
    // the progress bar is clicked at that point
    updateSeekTooltip (event) {
      const skipTo = Math.round(
        (event.offsetX / event.target.clientWidth) *
          parseInt(event.target.getAttribute('max'), 10)
      )
      this.seek.setAttribute('data-seek', skipTo)
      const t = this.formatTime(skipTo)
      this.seekTooltip.textContent = `${t.hours}:${t.minutes}:${t.seconds}`
      const rect = this.video.getBoundingClientRect()
      this.seekTooltip.style.left = `${event.pageX - rect.left}px`
    },

    // skipAhead jumps to a different point in the video when the progress bar
    // is clicked
    skipAhead (event) {
      const skipTo = event.target.dataset.seek
        ? event.target.dataset.seek
        : event.target.value
      this.video.currentTime = skipTo
      this.progressBar.value = skipTo
      this.seek.value = skipTo
      this.video.play()
      this.paused = false
    },
    handleWaiting () {
      this.buffering = true
    },
    handlePlaying () {
      this.buffering = false
    },
    updateVolume () {
      if (this.video.muted) {
        this.video.muted = false
      }

      this.video.volume = this.volume.value
    },

    // toggleMute mutes or unmutes the video when executed
    // When the video is unmuted, the volume is returned to the value
    // it was set to before the video was muted
    toggleMute () {
      this.video.muted = !this.video.muted

      // if volume will be implemented
      // if (this.video.muted) {
      //   this.volume.value = 0
      // } else {
      //   this.volume.value = 1// this.volume.dataset.volume
      // }
      this.volumeMute = this.video.muted
      this.volumeMute ? this.volumeButton.classList.add('muted') : this.volumeButton.classList.remove('muted')
    },

    toggleFullScreen () {
      if (document.fullscreenElement) {
        document.exitFullscreen()
        this.videoControls.classList.remove('fullscreen')
      } else if (document.webkitFullscreenElement) {
        // Need this to support Safari
        document.webkitExitFullscreen()
        this.videoControls.classList.remove('fullscreen')
      } else if (this.videoContainer.webkitRequestFullscreen) {
        // Need this to support Safari
        this.videoContainer.webkitRequestFullscreen()
        this.videoControls.classList.add('fullscreen')
      } else {
        this.videoContainer.requestFullscreen()
        this.videoControls.classList.add('fullscreen')
      }
      this.isFullscreen = !this.isFullscreen
    },
    handleControls () {
      if (this.isFullscreen) {
        this.hideControls()
      } else {
        this.showControls()
      }
    },

    hideControls () {
      if (this.video.paused) {
        return
      }
      this.videoControls.classList.add('hide')
    },

    showControls () {
      this.videoControls.classList.remove('hide')
    },
    download () {
      window.location.href = this.downloadRecordingUrl
    },

    reset () {
      this.buffering = false
      this.paused = true
      this.showControls()
    },

    // keyboardShortcuts executes the relevant functions for
    // each supported shortcut key
    keyboardShortcuts (event) {
      const { key } = event
      switch (key) {
        case 'k':
          this.togglePlay()
          if (this.video.paused) {
            this.showControls()
          } else {
            setTimeout(() => {
              this.hideControls()
            }, 2000)
          }
          break
        case 'm':
          this.toggleMute()
          break
        case 'f':
          this.toggleFullScreen()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .player {
    width: 100%;
    height: 100%;
    position: relative;
    .videoControls,
    .bottom-controls,
    .left-controls,
    .right-controls {
      display: flex;
    }
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-20px, -20px);
    }
    .video-controls {
      position: absolute;
      width: 100%;
      bottom: 0;
      transition: opacity .5s ease;
      &.fullscreen {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &.hide {
        opacity: 0;
        pointer-events: none;
      }
    }
    .left-controls {
      align-items: center;
      &__play {
        padding: 5px;
        cursor: pointer;
      }
      &__mute {
        margin-right: 10px;
        height: 18px;
        transform: translateY(3px);
        &::before {
          content: '';
          height: 20px;
          width: 1px;
          display: inline-block;
          margin-right: 20px;
          background: #FFF;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
        }
        &.muted {
          transform: translateY(4px);
          &::before {
            transform: translateY(-1px);
          }
        }
      }
      // @TODO: should be recalculated
      &__volume {
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--value) - var(--min)) / var(--range));
        --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
        margin: 0 10px;
        -webkit-appearance: none;
        appearance: none;
        height: 6px;
        &::-webkit-slider-runnable-track {
          background: linear-gradient(#D31920,#D31920) 0/var(--sx) 100% no-repeat, transparent;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;
          width: 10px; /* Set a specific slider handle width */
          height: 10px; /* Slider handle height */
          border-radius: 50%;
          background: #D31920; /* Green background */
          cursor: pointer; /* Cursor on hover */
          box-shadow: 0 0 2px black;
          margin-top: calc(max((1em - 1px - 1px) * 0.5,0px) - 2em * 0.5);
        }
      }
    }
    .right-control {
      &__fullscreen {
        margin-right: 20px;
        border-right: 1px solid #fff;
        padding-right: 20px;
        box-sizing: content-box;
      }
    }
    .time {
      color: #fff;
    }
    path {
      fill: white;
    }
    button {
      border: none;
      background: none;
      color: white;
      i {
        cursor: pointer;
      }
    }
    &__recording {
      display: block;
      margin: auto;
      width: 100% !important;
      height: 500px;
      object-fit: contain;
      object-position: center;
      background: #000;
    }
    .bottom-controls {
      padding: 7px 20px;
      align-items: center;
      justify-content: space-between;
    }
    .video-controls {
      height: 56px;
      background: rgba(17, 17, 17, 0.6);
    }
    .video-progress {
      position: relative;
      height: 4px;
      margin-bottom: 7px;
    }
    .buffered {
      height: 4px;
      width: 100%;
      position: relative;
      top: -11px;
      left: 0;
    }

    progress {
      appearance: none;
      width: 100%;
      height: 4px;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      background: #111;
      z-index: 2;
      &::-webkit-progress-value {
          background-color: #D31920;
          height: 100%;
          width: 50%;
          box-sizing: border-box;
          -webkit-user-modify: read-only !important;
      }
    }
    .seek {
      -webkit-appearance: none;
      background: transparent;
      position: absolute;
      top: -6px;
      left: 0;
      width: 100%;
      cursor: pointer;
      margin: 0;
      z-index: 3;
      &::-webkit-slider-thumb{
        border-radius: 50%;
        appearance: none;
        background: red;
        width: 12px;
        height: 12px;
        transform: translateY( 2px);
      }
    }

    .seek:hover+.seek-tooltip {
      display: block;
    }

    .seek-tooltip {
      display: none;
      position: absolute;
      top: -50px;
      margin-left: -20px;
      font-size: 12px;
      padding: 3px;
      content: attr(data-title);
      font-weight: bold;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
</style>
