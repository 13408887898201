<template>
  <div class="head">
    <div class="head__row">
      <div class="head__col">
        <div class="head__outer">
          <div class="head__itm head__itm--logo">
            <Icon class="icon--logo">
              <svg viewBox='0 0 68 32' xmlns='http://www.w3.org/2000/svg'>
                <path d="M33.298 3.24L25.506.229l-.022 1.166c-5.605.565-9.832 3.072-9.832 6.082 0 1.758 1.443 3.344 3.755 4.469.04.02.08.041.12.057.1.048.208.094.312.14 1.336.575 2.585.857 3.393.991-2.555-.75-4.288-2.18-4.288-3.83 0-.01.005-.021.005-.032 0-.01-.005-.019-.005-.026 0-.13.014-.26.042-.388.362-1.886 2.992-3.415 6.427-3.831l-.018.946 7.903-2.731z" fill="#fff"/>
                <path d="M41.923 9.076c0-1.76-1.445-3.344-3.756-4.471-.038-.02-.078-.039-.116-.057-.104-.048-.21-.092-.314-.137a14.903 14.903 0 00-3.393-.99c2.555.747 4.288 2.18 4.288 3.83 0 .012-.003.024-.003.03 0 .01.003.019.003.028 0 .128-.017.258-.043.386-.361 1.886-2.989 3.415-6.427 3.831l.017-.946-7.9 2.731 7.791 3.013.021-1.168c5.606-.565 9.832-3.07 9.832-6.08z" fill="#D31920"/>
                <path d="M5.825 31.651c-2.151 0-4.094-.229-5.825-1.906l2.229-2.158c1.129 1.09 2.6 1.246 3.648 1.246 1.18 0 2.416-.382 2.416-1.374 0-.658-.369-1.118-1.443-1.216l-2.151-.203c-2.47-.229-3.99-1.274-3.99-3.71 0-2.743 2.493-4.217 5.274-4.217 2.125 0 3.91.356 5.22 1.55L9.108 21.72c-.789-.686-1.995-.89-3.176-.89-1.367 0-1.943.61-1.943 1.269 0 .485.21 1.045 1.42 1.145l2.15.204c2.701.253 4.069 1.652 4.069 3.885-.003 2.921-2.577 4.318-5.802 4.318zM27.172 31.497h-2.807l-2.86-8.306-2.861 8.306h-2.808l-4.2-13.234h3.622l2.205 8.178 2.78-8.178h2.493l2.807 8.178 2.205-8.178h3.596l-4.172 13.234zM32.072 31.497V18.263h3.412v13.234h-3.412zM42.541 31.498c-2.779 0-3.962-1.907-3.962-3.785v-7.04h-1.44v-2.515h1.44v-1.687l3.412-1.124v2.811h2.413v2.515h-2.413v6.836c0 .761.366 1.196 1.178 1.196h1.235l-.448 2.793H42.54zM51.144 31.65c-2.732 0-6.116-1.421-6.116-6.781 0-5.363 3.386-6.76 6.116-6.76 1.889 0 3.306.56 4.512 1.781l-2.31 2.233c-.705-.736-1.31-1.042-2.202-1.042-.815 0-1.443.281-1.941.864-.527.636-.763 1.522-.763 2.921 0 1.397.236 2.309.763 2.947.498.582 1.129.861 1.94.861.893 0 1.498-.304 2.204-1.04l2.31 2.213c-1.207 1.22-2.624 1.803-4.513 1.803zM64.588 31.498V23.42c0-1.753-1.152-2.336-2.229-2.336-1.076 0-2.205.61-2.205 2.336v8.078H56.74V13.41h3.414v6.068c.918-.914 2.07-1.371 3.282-1.371 2.992 0 4.564 2.034 4.564 4.827v8.56h-3.412v.003z" fill="#fff"/>
              </svg>
            </Icon>
          </div>
          <div class="head__itm">
            <span class="head__vest">{{ headerType }}</span>
          </div>
          <div class="head__itm head__itm--links" v-if="headerType === 'vest'">
            <router-link
              to="/"
              :class="[pagePath === '/' ? 'selected' : '']">units</router-link>
            <router-link
              to="recordings"
              v-if="isWatcher || isAdmin || isCommentor"
              :class="[pagePath === '/recordings' ? 'selected' : '']">recordings</router-link>
            <router-link
              to="deviceInventory"
              v-if="isSwitchAdmin || isOtherAdmin"
              :class="[pagePath === '/deviceInventory' ? 'selected' : '']">device inventory</router-link>
          </div>
        </div>
      </div>
      <div class="head__col">
        <a class="head__user" href="#" @click.prevent="showUserInfo" v-click-outside="closePopupUser">{{ formattedName }}</a>
      </div>
    </div>
    <Popup v-if="showPopupLogout">
      <template slot="header">
        <p class="modal__title">Log out?</p>
      </template>
      <template slot="body">
        <p class="modal__text">Are you sure you want to log out?</p>
        <p class="modal__text">You will also leave all active sessions.</p>
      </template>
      <template slot="buttons">
        <div class="modal__row modal__row--right">
          <div class="modal__col">
            <a href="#" class="modal__link modal__link--red" @click.prevent="logout">Log out</a>
          </div>
          <div class="modal__col">
            <a href="#" class="modal__link" @click.prevent="closePopupLogout" v-click-outside="closePopupLogout">Cancel</a>
          </div>
        </div>
      </template>
    </Popup>
    <Popup v-if="showPopupUser">
      <template slot="header">
        <p class="modal__user">{{ user.name }}</p>
      </template>
      <template slot="body">
        <p class="modal__info">VEST Client</p>
        <p class="modal__text"> {{ releaseVersion }} </p>
      </template>
      <template slot="buttons">
        <ul class="modal__list">
          <li class="modal__li">
            <a href="/user_guide.pdf" class="modal__link" target="_blank">
              <span class="modal__link--flex">
                <Icon class="icon--guide">
                  <svg viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5.5C14.55.5 12.45.9 11 2 9.55.9 7.45.5 5.5.5 3.55.5 1.45.9 0 2v14.65c0 .65.73.45.75.45C2.1 16.45 4.05 16 5.5 16c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.41.21.75-.19.75-.45V2C20.51.88 18.37.5 16.5.5zm3.5 14c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V4c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5v11.5z"/>
                  </svg>
                </Icon>
                User Guide
              </span>
            </a>
          </li>
          <li class="modal__li">
            <a href="#" @click="confirmLogout" class="modal__link">
              <span class="modal__link--flex">
                <Icon class="icon--logout">
                  <svg viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 15v-3H6V8h7V5l5 5-5 5zM11 0a2 2 0 012 2v2h-2V2H2v16h9v-2h2v2a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h9z"/>
                  </svg>
                </Icon>
              Log out
              </span>
            </a>
          </li>
        </ul>
      </template>
    </Popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Icon from '@/common/icon/Icon'
import Popup from '@/common/popup/Popup'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'HeaderView',
  props: {
    headerType: String
  },
  data () {
    return {
      showPopupLogout: false,
      showPopupUser: false
    }
  },
  components: {
    Icon,
    Popup
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters([
      'user',
      'isWatcher',
      'isAdmin',
      'isCommentor',
      'isSwitchAdmin',
      'isOtherAdmin'
    ]),
    pagePath () {
      console.log(this.$route.path)
      return this.$route.path
    },
    formattedName () {
      const firstName = this.user.name.split(' ')[0]
      const secondName = this.user.name.split(' ')[1]

      return firstName && secondName ? `${firstName.split('')[0]}${secondName.split('')[0]}` : this.user.name[0]
    },
    releaseVersion () {
      return window.VERSION?.vestClientUI ? window.VERSION.vestClientUI : ''
    }
  },
  methods: {
    ...mapActions([
      'setLoggedIn',
      'disconnectFromSocket'
    ]),
    showUserInfo () {
      this.showPopupUser = true
    },
    confirmLogout () {
      this.showPopupLogout = true
      this.showPopupUser = false
    },
    closePopupLogout () {
      this.showPopupLogout = false
    },
    closePopupUser () {
      this.showPopupUser = false
    },
    logout (event) {
      if (event) {
        event.preventDefault()
      }

      this.disconnectFromSocket().then(() => {
        console.log('Disconnected from session manager socket')
        this.setLoggedIn(false)
        this.$auth.signout()
        localStorage.removeItem('acceptedRule')
      }).catch(err => {
        console.error('Error Disconnected from session manager socket', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .head {
    margin-bottom: 20px;
    background: #111111;
    padding: 15px 25px;
    &__outer {
      display: flex;
      margin: 0 -20px;
      align-items: center;
    }
    &__itm {
      padding: 0 20px;
      &--links {
        margin-left: 33px;
        a {
          padding: 11px 0;
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 1px;
          margin-right: 30px;

          &.selected {
            border-bottom: 2px #D31920 solid;
          }
        }
      }
    }
    &__itm--logo {
      border-right: 1px solid #fff;
    }
    &__row {
      display: flex;
      margin: 0 -10px;
      align-items: center;
      justify-content: space-between;
    }
    &__vest {
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 5px;
    }
    &__col {
      padding: 0 10px;
    }
    &__user {
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      background: #F5F5F5;
      color: #111;
      border-radius: 50%;
      text-decoration: none;
      width: 40px;
      height: 40px;
      line-height: 40px;
      display: block;
    }
  }
</style>
