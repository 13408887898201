<template>
  <div class="room">
    <div class="room__outer" v-if="connectedStatus && currentPod && currentSession.id && mediaInfoPod.length">
      <div class="room__itm">
        <HeaderPodSessionRoom/>
      </div>
      <div class="room__itm room__itm--camera">
        <CameraPodView/>
      </div>
    </div>
    <Loading v-else/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/common/loading/Loading'
import CameraPodView from '@/components/camera-pod-view/CameraPodView'
import HeaderPodSessionRoom from '@/components/header-pod-session-room/HeaderPodSessionRoom'

export default {
  name: 'PodSessionRoom',
  components: {
    Loading,
    CameraPodView,
    HeaderPodSessionRoom
  },
  data () {
    return {
      timeId: null,
      isSessionStarted: false
    }
  },
  computed: {
    ...mapGetters([
      'currentPod',
      'currentMod',
      'units',
      'mod',
      'connectedStatus',
      'currentSession',
      'currentPodName',
      'currentModName',
      'topics',
      'mediaInfoPod'
    ]),
    sessionStatus () {
      return this.currentPod && this.currentPod.session && this.currentPod.session.status
    }
  },
  methods: {
    ...mapActions([
      'sendCloseSession',
      'clearPulishersList',
      'setCurrentPod',
      'setCurrentMod',
      'setCurrentPodName',
      'setCurrentModName',
      'subscribeJoinSession',
      'subscribeToTopic',
      'unsubscribeFromTopic',
      'setCurrentSession',
      'ModxSession'
    ]),
    ...mapActions('ModxSession', { sendStartSession: 'startSession' }),
    leaveRoom () {
      this.$router.push({ path: '/' })
    },
    closeSession () {
      this.sendCloseSession()
      this.$router.push({ path: '/' })
    },
    timeOutSession () {
      this.timeId = setTimeout(() => {
        // this.openSession()
        if (this.sessionStatus || this.currentPod.status || this.currentPod.session) return 'continue session'
        if (this.currentPod) return 'continue session'
        console.warn(this.sessionStatus, this.currentPod, 'loading time out!')
        this.$router.push({ path: '/' })
      }, 5000)
    },
    async startPodSession () {
      const subscribeJoinSession = this.subscribeJoinSession()
      const inviteList = this.currentPod.devices.filter(device => {
        if (device.type === 'AUDIO_STREAM_DEVICE') {
          return true
        }
        if (device.meta.orientation === 'FRONT' && device.meta.position === 'CENTER') {
          return true
        }
        return false
      }).map(device => ({
        deviceType: device.type,
        unitId: device.containerId,
        deviceId: device.id
      }))
      this.sendStartSession(inviteList)
      const { topic, tick } = await subscribeJoinSession
      this.unsubscribeFromTopic(topic)
      this.setCurrentSession(tick.session)
      this.subscribeToTopic(tick.session.destination)
    },
    joinPodSession () {
      this.setCurrentSession(this.currentPod.session)
      this.subscribeToTopic(this.currentPod.session.destination)
    },
    openSession () {
      console.log('session/status', this.currentPod.status)
      if (this.currentPod.status === 'IN_SESSION') {
        this.joinPodSession()
      } else {
        this.startPodSession()
      }
    }
  },
  mounted () {
    this.setCurrentPodName(this.$route.params.podName)
    this.setCurrentModName(this.$route.params.modName)
    this.timeOutSession()
    this.openSession()

    if (this.connectedStatus) {
      this.subscribeToTopic(this.topics.getForDeviceStatus({ unitId: this.currentPod.id }))

      this.unsubscribeFromTopic(this.topics.camUnit)
      this.unsubscribeFromTopic(this.topics.mobile)
    }
  },
  destroyed () {
    this.unsubscribeFromTopic(this.topics.getForDeviceStatus({ unitId: this.currentPod.id }))
    this.clearPulishersList()
    this.setCurrentPod(null)
  },
  watch: {
    connectedStatus: {
      handler () {
        const currentMod = this.mod.find(mod => mod.name === this.currentModName)
        const currentPod = currentMod.childContainers.find(pod => pod.description === this.currentPodName)
        this.setCurrentMod(currentMod)
        this.setCurrentPod(currentPod)

        this.subscribeToTopic(this.topics.getForDeviceStatus({ unitId: this.currentPod.id }))
      }
    },
    sessionStatus: {
      handler () {
        if (this.sessionStatus && this.sessionStatus === 'IN_SESSION') {
          this.openSession()
        }

        if (this.sessionStatus && this.sessionStatus === 'NO_SESSION') {
          this.$router.push({ path: '/' })
        }
      }
    },
    currentPod: {
      handler () {
        if (!this.currentSession.id && this.currentPod && this.currentPod.session) {
          this.joinPodSession()
        }

        if (this.sessionStatus || this.currentPod.status) {
          this.openSession()
          clearTimeout(this.timeId)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .room {
    height: 100%;
    &__outer {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
    &__itm--camera {
      height: calc(100% - 85px);
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
</style>
