<template>
  <i class="icon-wifi" :wifiLevel="wifiLevel" :status="status" v-tooltip="tooltipMsg" :class="wifiStatusClass">
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path class="high" d="M9.99939 17.5L12.9994 13.5C12.1661 12.875 11.1244 12.5 9.99939 12.5C8.87439 12.5 7.83272 12.875 6.99939 13.5L9.99939 17.5ZM9.99939 2.5C6.62439 2.5 3.50772 3.61667 0.99939 5.5L2.49939 7.5C4.58272 5.93333 7.18272 5 9.99939 5C12.8161 5 15.4161 5.93333 17.4994 7.5L18.9994 5.5C16.4911 3.61667 13.3744 2.5 9.99939 2.5ZM9.99939 7.5C7.74939 7.5 5.67439 8.24167 3.99939 9.5L5.49939 11.5C6.74939 10.5583 8.30772 10 9.99939 10C11.6911 10 13.2494 10.5583 14.4994 11.5L15.9994 9.5C14.3244 8.24167 12.2494 7.5 9.99939 7.5Z"/>
      <path class="medium" d="M10.0001 17.5L13.0001 13.5C12.1668 12.875 11.1251 12.5 10.0001 12.5C8.87512 12.5 7.83346 12.875 7.00012 13.5L10.0001 17.5Z"/>
      <path class="low" d="M9.99988 17.5L12.9999 13.5C12.1665 12.875 11.1249 12.5 9.99988 12.5C8.87488 12.5 7.83321 12.875 6.99988 13.5L9.99988 17.5ZM9.99988 7.5C7.74988 7.5 5.67488 8.24167 3.99988 9.5L5.49988 11.5C6.74988 10.5583 8.30821 10 9.99988 10C11.6915 10 13.2499 10.5583 14.4999 11.5L15.9999 9.5C14.3249 8.24167 12.2499 7.5 9.99988 7.5Z"/>
    </svg>
  </i>
</template>

<script>
import Vue from 'vue'
import { VTooltip } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)

export default {
  name: 'Wifi',
  props: ['wifiLevel', 'status'],
  computed: {
    wifiStatusClass () {
      return this.status === 'CONNECTED' ? this.wifiLevelStatus : null
    },
    tooltipMsg () {
      return this.status === 'CONNECTED' ? 'Wifi available' : 'Wifi unavailable'
    },
    wifiLevelStatus () {
      switch (true) {
        case (this.wifiLevel <= 30):
          return 'icon-wifi--low'
        case (this.wifiLevel <= 60):
          return 'icon-wifi--medium'
        case (this.wifiLevel <= 100):
          return 'icon-wifi--high'
        default:
          return 'DISCONNECTED'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-wifi {
    display: block;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    fill: rgba(17,17,17,.1);
    &.icon-wifi--low {
      .high, .low {
        fill: rgba(17,17,17,.1);
      }
      .medium {
        fill: #FF9922;
      }
    }
    &.icon-wifi--medium {
      .high {
        fill: rgba(17,17,17,.1);
      }
      .low, .medium {
        fill: #FFCC11;
      }
    }
    &.icon-wifi--high {
      .low, .medium, .high {
        fill: #22DD77;
      }
    }
  }
</style>
