<template>
  <div>
    <div v-if="isOn" :class="orientation === 'BACK' ? 'arrow--down' : 'arrow--up'" class="arrow">
      <svg viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 44L0 0H48L24 44Z" :fill="isPrimary ? 'url(#paint0_linear)' : 'url(#paint0_linear2)'" />
        <defs>
          <linearGradient id="paint0_linear" x1="24" y1="44" x2="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stop-color="red" />
            <stop offset="1" stop-color="red" stop-opacity="0" />
          </linearGradient>
          <linearGradient id="paint0_linear2" x1="24" y1="44" x2="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <a @click.stop="clickAction">
      <span :class="iconClass">
        <svg viewBox="-2 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 0C9.85651 0 11.637 0.737498 12.9497 2.05025C14.2625 3.36301 15 5.14349 15 7C15 8.85652 14.2625 10.637 12.9497 11.9497C11.637 13.2625 9.85651 14 8 14C6.14348 14 4.36301 13.2625 3.05025 11.9497C1.7375 10.637 1 8.85652 1 7C1 5.14349 1.7375 3.36301 3.05025 2.05025C4.36301 0.737498 6.14348 0 8 0ZM8 2C6.67392 2 5.40215 2.52678 4.46447 3.46447C3.52678 4.40215 3 5.67392 3 7C3 8.32608 3.52678 9.59785 4.46447 10.5355C5.40215 11.4732 6.67392 12 8 12C8.65661 12 9.30679 11.8707 9.91342 11.6194C10.52 11.3681 11.0712 10.9998 11.5355 10.5355C11.9998 10.0712 12.3681 9.52005 12.6194 8.91342C12.8707 8.30679 13 7.65661 13 7C13 5.67392 12.4732 4.40215 11.5355 3.46447C10.5979 2.52678 9.32608 2 8 2ZM8 4C8.79565 4 9.55871 4.31607 10.1213 4.87868C10.6839 5.44129 11 6.20435 11 7C11 7.79565 10.6839 8.55871 10.1213 9.12132C9.55871 9.68393 8.79565 10 8 10C7.20435 10 6.44129 9.68393 5.87868 9.12132C5.31607 8.55871 5 7.79565 5 7C5 6.20435 5.31607 5.44129 5.87868 4.87868C6.44129 4.31607 7.20435 4 8 4ZM2 20C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18C0 17.62 0.1 17.27 0.29 16.97L2.11 13.81C3.74676 15.2234 5.83742 16.0008 8 16C10.25 16 12.31 15.17 13.89 13.81L15.71 16.97C15.9 17.27 16 17.62 16 18C16 18.5304 15.7893 19.0391 15.4142 19.4142C15.0391 19.7893 14.5304 20 14 20L2 20Z" />
        </svg>
      </span>
    </a>
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex'

export default {
  name: 'CameraBtn',
  props: ['status', 'orientation', 'isOn', 'isPrimary', 'isHovered', 'id', 'containerId', 'position'],
  components: {
  },
  data: function () {
    return {
      delay: 300,
      clicks: 0,
      timer: null
    }
  },
  mounted () {
    if (this.status && this.orientation === 'FRONT' && this.position === 'CENTER') {
      this.setPrimaryIpCameraId(this.id)
    }
  },
  computed: {
    iconClass () {
      return {
        icon: true,
        'icon--camera-offline': !this.status,
        'icon--camera-main': this.status && this.isPrimary,
        'icon--camera-on': this.status && !this.isPrimary && this.isOn,
        'icon--camera-off': this.status && !this.isPrimary && !this.isOn,
        'icon--hovered': this.isHovered
      }
    }
  },
  methods: {
    ...mapActions([
      'sendPTZCommand',
      'setPrimaryIpCameraId'
    ]),
    clickAction: function (event) {
      this.clicks++
      const self = this
      if (this.clicks === 1) {
        this.timer = setTimeout(function () {
          self.clicks = 0
          self.$emit('clickAction', { id: self.id, status: self.status, isOn: !self.isOn, containerId: self.containerId })
        }, this.delay)
      } else {
        clearTimeout(this.timer)
        this.clicks = 0
        this.$emit('doubleClickAction', { id: self.id, status: self.status, isOn: self.isOn, containerId: self.containerId })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .arrow {
    width: 48px;
    height: 44px;
    position: absolute;
    z-index: -1;

    &--up {
      transform: translate(-15%, -50%);
    }

    &--down {
      transform: translate(-15%, 20%) rotate(180deg);
    }
  }

  .icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    padding: 7px;
    &--camera-main {
      background: #D31920;
      fill: white;
    }
    &--camera-on {
      background: white;
      fill: black;
    }
    &--camera-off {
      background: black;
      fill: white;
      border: 2px solid white;
      padding: 5px;
    }
    &--camera-offline {
      background: #CFCFCF;
      border: 2px solid rgba(3, 3, 3, 0.4 );
      fill: rgba(3, 3, 3, 0.4);;
      padding: 5px;
    }
    &--hovered{
      margin: -2px;
      position: relative;
      width: 36px;
      height: 36px;
    }
    &:hover {
      margin: -2px;
      position: relative;
      width: 36px;
      height: 36px;
    }
  }
</style>
