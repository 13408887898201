<template>
  <div class="camera">
    <PodsControls class="camera__pods-controls"/>
    <div class="camera__wrap">
      <div :class="podPreviewClass" class="camera__preview">
        <VideoPodView v-for="device in mediaInfoPod"
          :key="device.uuid"
          :device="device"
          @clickAction="makeVideoPrimary(device)"
          @removeCamDevice="removeCamDevice(device.uuid)">
          <template slot="video">
            <KurentoPlayer
              v-if="device.streams"
              :streamName="device.streams.video.name"
              :currentSession="currentSession"
              :ptzFeed="true"
              :volume="volume"
              :publisherUuid="device.uuid"
              :elementId="device.uuid"
            />
          </template>
        </VideoPodView>
      </div>
      <template v-if="currentIpCamera.telemetry && currentIpCamera.telemetry.ptz && amIHost">
        <PtzClickOverlay v-if="devicePrimary" :currentUnit="currentPod" :device="currentIpCamera" type='IP_CAMERA' :class="ptzControlsClass"/>
        <PtzControls v-if="devicePrimary" :currentUnit="currentPod" :device="currentIpCamera" type='IP_CAMERA' class="camera__ptz-controls" :class="ptzControlsClass"/>
      </template>
      <KurentoPublisher :streamName="publishedStreamName" :currentSession="currentSession" :audioOnly="true"/>
      <KurentoPlayer
        v-for="publisher in publishersWithAudioStream"
        v-bind:key="publisher.streams.audio.name"
        :audioOnly="true"
        :streamName="publisher.streams.audio.name"
        :publisherUuid="publisher.uuid"
        :currentSession="currentSession"
        :volume="volume"
        :elementId="publisher.uuid"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VideoPodView from '@/components/video-pod-view/VideoPodView'
import PtzControls from '@/components/ptz-controls/PtzControls'
import PtzClickOverlay from '@/components/ptz-click-overlay/PtzClickOverlay'
import PodsControls from '@/components/pods-controls/PodsControls'
import { v4 as uuidv4 } from 'uuid'
import { showErrorToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'
import KurentoPlayer from '../kurento-player/KurentoPlayer'
import KurentoPublisher from '../kurento-publisher/KurentoPublisher'

export default {
  name: 'CameraPodView',
  components: {
    KurentoPublisher,
    KurentoPlayer,
    PodsControls,
    PtzControls,
    VideoPodView,
    PtzClickOverlay
  },
  data () {
    return {
      elementId: uuidv4()
    }
  },
  computed: {
    ...mapGetters([
      'startSessionCamUnit',
      'currentSession',
      'user',
      'publishers',
      'volume',
      'publishedStreamName',
      'mediaInfoPod',
      'primaryIpCameraId',
      'currentPod',
      'amIHost'
    ]),
    podPreviewClass () {
      return this.mediaInfoPod.length > 2 ? 'camera__pod--preview' : null
    },
    devicePrimary () {
      return this.mediaInfoPod.find(device => device.uuid === this.primaryIpCameraId)
    },
    ptzControlsClass () {
      return this.mediaInfoPod.length > 2 ? 'camera__ptz-controls-fixed' : null
    },
    currentIpCamera () {
      if (!this.devicePrimary) return {}
      return this.currentPod.devices.find(device => device.id === this.devicePrimary.uuid)
    },
    publishersWithAudioStream: function () {
      return this.publishers.filter(itm => itm.streams.audio?.name)
    },
    isUnavailablePtzControls () {
      return (!this.currentIpCamera.telemetry && this.amIHost) || (this.currentIpCamera.telemetry && !this.currentIpCamera.telemetry.ptz && this.amIHost)
    }
  },
  methods: {
    ...mapActions([
      'createStreamName',
      'deleteSessionData',
      'setPrimaryIpCameraId',
      'removeModxCamFromPod'
    ]),
    ...mapActions('ModxSession', ['sendCommand']),
    makeVideoPrimary (device) {
      this.setPrimaryIpCameraId(device.uuid)
    },
    removeCamDevice (device) {
      const camera = this.currentPod.devices.find(itm => itm.id === device)
      this.sendCommand({
        deviceType: 'IP_CAMERA',
        unitId: camera.containerId,
        deviceId: camera.id,
        commandName: 'KICK'
      })
      this.removeModxCamFromPod(device)
    }
  },
  watch: {
    isUnavailablePtzControls: {
      handler () {
        if (this.isUnavailablePtzControls) {
          showErrorToast({ id: 4, message: ERRORS[4] })
        }
      }
    }
  },
  beforeMount () {
    this.createStreamName()
  },
  destroyed () {
    this.deleteSessionData()
  }
}
</script>

<style lang="scss" scoped>
  .camera {
    overflow: hidden;
    &__wrap {
      align-items: flex-end;
    }
    &__pod--preview {
      max-height: 100%;
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    &__ptz-controls {
      &.camera__ptz-controls-fixed {
        right: 400px;
      }
    }
    &__pods-controls {
      position: absolute;
      top: 25px;
      left: 40px;
      z-index: 100;
    }
  }
  .ptz-click-overlay {
    z-index: 5;
    &.camera__ptz-controls-fixed {
      width: calc(100% - 360px);
      right: auto;
      overflow: hidden;
    }
  }
</style>
