<template>
  <div class="battery">
    <TelemetryIcon :isBattery="true" :battery="battery" :status="status" class="icon--battery">
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path opacity=".4" d="M11.113 2.667H10V1.333H6v1.334H4.887A.887.887 0 004 3.553V13.78c0 .487.4.887.887.887h6.226A.887.887 0 0012 13.78V3.553a.89.89 0 00-.887-.886z" fill="#111"/>
        <rect class="icon--battery-level" x="4" y="0" width="8" height="12px" rx="1"/>
      </svg>
    </TelemetryIcon>
    <span v-if="showBatteryLevel" class="battery__level">{{ battery + `%` }}</span>
  </div>
</template>

<script>
import TelemetryIcon from '@/common/telemetry-icon/TelemetryIcon'

export default {
  name: 'Battery',
  props: ['battery', 'status', 'showBatteryLevel'],
  components: {
    TelemetryIcon
  }
}
</script>

<style lang="scss" scoped>
  .battery {
    display: flex;
    align-items: center;
    justify-content: center;
    &.battery--medium {
      .icon--battery {
        width: 20px;
      }
    }
    &__level {
      font-size: 12px;
    }
  }
</style>
