export default {
  namespaced: false,
  state: {
    primaryIpCameraId: null,
    hoveredIpCameraId: null
  },
  getters: {
    primaryIpCameraId (state) {
      return state.primaryIpCameraId
    },
    hoveredIpCameraId (state) {
      return state.hoveredIpCameraId
    }
  },
  mutations: {
    setPrimaryIpCameraId (state, payload) {
      state.primaryIpCameraId = payload
    },
    setHoveredIpCameraId (state, payload) {
      state.hoveredIpCameraId = payload
    }
  },
  actions: {
    setPrimaryIpCameraId ({ commit }, payload) {
      commit('setPrimaryIpCameraId', payload)
    },
    setHoveredIpCameraId ({ commit }, payload) {
      commit('setHoveredIpCameraId', payload)
    }
  }
}
