<template>
  <div class="joystick-base">
    <svg class="joystick-base-svg" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48C0 21.49 21.49 0 48 0s48 21.49 48 48-21.49 48-48 48S0 74.51 0 48z" fill="url(#paint0_radial)"/>
      <path d="M19.42 43.41L14.83 48l4.59 4.59L18 54l-6-6 6-6 1.42 1.41zM76.58 52.59L81.17 48l-4.59-4.59L78 42l6 6-6 6-1.42-1.41zM43.41 76.58L48 81.17l4.59-4.59L54 78l-6 6-6-6 1.41-1.42zM52.59 19.42L48 14.83l-4.59 4.59L42 18l6-6 6 6-1.41 1.42z" fill="#fff" opacity=".4"/><defs><radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0 48 -48 0 48 48)"><stop stop-color="#111" stop-opacity="0"/><stop offset=".771" stop-color="#111" stop-opacity=".2"/><stop offset="1" stop-color="#111" stop-opacity=".4"/></radialGradient></defs>
    </svg>
    <div class="joystick-shaft"></div>
  </div>
</template>

<script>
import Joystick from './Joystick.js'

export default {
  data () {
    return {
      joystick: null
    }
  },
  methods: {
    setupJoystick () {
      this.joystick = new Joystick('.joystick-base', '.joystick-shaft')
      this.joystick.attachEvents()
      this.joystick.ondeactivate = () => {
        this.$emit('stop')
      }
      this.joystick.ondrag = () => {
        this.$emit(
          'update',
          this.joystick.shaft.current.percentage,
          this.joystick.shaft.current.angle
        )
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.setupJoystick() // do this async once video loads l8r
    }, 500)
  }
}
</script>

<style lang="scss">
  .joystick-container {
    touch-action: none;
  }
  .joystick-base-svg {
    width: 92px;
    height: 92px;
  }
  .joystick-base {
    border-radius: 50%;
    border: 2px solid #fff;
    position: relative;
    height: 96px;
    width: 96px;
    cursor: pointer;
    touch-action: none;
    &:hover,
    &.active {
      .joystick-shaft {
        background: #fff;
      }
    }
  }
  .joystick-shaft {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    height: 32px;
    width: 32px;
    border-radius: 100%;
    border: 2px solid #fff;
    transition: background 0.3s;
    will-change: transform;
    touch-action: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
</style>
