<template>
  <i class="icon"
     v-tooltip="tooltipShow ? tooltipMsg : null"
     :name="name"
     :status="status"
     :battery="battery"
     :isBattery="isBattery"
     :hasBatteryIcon="hasBatteryIcon"
     :class="!isBattery ? deviceStatusClass : batteryStatusClass">
    <slot/>
  </i>
</template>

<script>
import Vue from 'vue'
import { VTooltip } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)

export default {
  name: 'TelemetryIcon',
  props: {
    status: String,
    name: String,
    battery: '',
    tooltipShow: Boolean,
    isBattery: Boolean,
    hasBatteryIcon: Boolean
  },
  computed: {
    tooltipMsg () {
      return this.deviceActiveStatus ? `${this.name} available` : `${this.name} unavailable`
    },
    deviceActiveStatus () {
      return this.status === 'CONNECTED' || this.status === 'ONLINE' || this.status === 'IN_SESSION'
    },
    activeClass () {
      return this.deviceActiveStatus && !this.hasBatteryIcon ? 'icon--active-green' : this.deviceActiveStatus && this.hasBatteryIcon ? 'icon--active-black' : null
    },
    deviceBatteryLevel () {
      switch (true) {
        case (this.battery <= 15):
          return 'charge-critical'
        case (this.battery <= 25):
          return 'charge-low'
        case (this.battery <= 50):
          return 'charge-average'
        case (this.battery <= 60):
          return 'charge-high'
        case (this.battery <= 100):
          return 'charge-full'
        default:
          return 'DISCONNECTED'
      }
    },
    deviceStatusClass () {
      return this.deviceActiveStatus && this.battery ? this.deviceBatteryLevel : this.activeClass
    },
    batteryStatusClass () {
      return this.deviceActiveStatus && this.battery ? this.deviceBatteryLevel : null
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon {
    display: block;
    margin: 0 auto;
    width: 23px;
    fill: rgba(17,17,17,.1);
    svg {
      display: block;
    }
    &.icon--active-green {
      fill: #22DD77
    }
    &.icon--active-black {
      fill: #111
    }
    &.charge-full {
      fill: #22DD77;
      .icon--battery-level {
        height: 12px;
        y: 2.67px;
      }
    }
    &.charge-high {
      fill: #22DD77;
      .icon--battery-level {
        height: 8px;
        y: 6.67px;
      }
    }
    &.charge-average {
      fill: #FFCC11;
      .icon--battery-level {
        height: 5.33px;
        y: 9.33px;
      }
    }
    &.charge-low {
      fill: #FF9922;
      .icon--battery-level {
        height: 2.67px;
        y: 12px;
      }
    }
    &.charge-critical {
      fill: #D31920;
      .icon--battery-level {
        height: 1.33px;
        y: 13.33px;
      }
    }
    &.disconnected {
      fill: rgba(17,17,17,.1)
    }
    &.icon--battery {
      width: 16px;
    }
    &.icon--small {
      width: 20px;
    }
  }
</style>
