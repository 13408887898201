import Vue from 'vue'
import stateMerge from 'vue-object-merge'
import createUnitStructure from '../../helpers/createUnitStructure'
import { showErrorToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'

export default {
  namespaced: false,
  state: {
    units: [],
    mod: [],
    currentPod: {},
    currentMod: {},
    currentUnitName: null,
    currentModName: null,
    currentPodName: null,
    currentPodDevices: [],
    defaultPtzParams: {
      pan: 0,
      tilt: 0,
      zoom: 1,
      autofocus: 'on'
    },
    isNotDefaultPtzParams: false,
    currentUnit: {},
    currentEditUnit: {},
    isEditUnitPage: false
  },
  getters: {
    currentPodDevices (state) {
      return state.currentPodDevices
    },
    units (state) {
      return state.units.filter((u) => {
        if (u.smartHands) {
          if (u.devices[0].status === 'ONLINE' || u.devices[1].status === 'ONLINE') {
            return u
          }
          return false
        }
        return u
      })
    },
    mod (state) {
      return state.mod
    },
    currentPod (state) {
      return state.currentPod
    },
    currentMod (state) {
      return state.currentMod
    },
    currentUnitName (state) {
      return state.currentUnitName
    },
    currentPodName (state) {
      return state.currentPodName
    },
    currentModName (state) {
      return state.currentModName
    },
    defaultPtzParams (state) {
      return state.defaultPtzParams
    },
    isNotDefaultPtzParams (state) {
      return state.isNotDefaultPtzParams
    },
    currentUnit (state) {
      return state.units !== undefined
        ? state.units.find(name => name.name === state.currentUnitName)
        : undefined
    },
    currentCamUnit (state, getters) {
      return getters.currentUnit && getters.currentUnit.devices !== undefined
        ? getters.currentUnit.devices.find(device => device.type === 'CAM_UNIT')
        : undefined
    },
    currentCamMobile (state, getters) {
      return getters.currentUnit && getters.currentUnit.devices !== undefined
        ? getters.currentUnit.devices.find(device => device.type === 'CAM_MOBILE')
        : undefined
    },
    isEditUnitPage (state) {
      return state.isEditUnitPage
    },
    currentEditUnit (state) {
      return state.currentEditUnit
    }
  },
  mutations: {
    setUnits (state, data) {
      const vestUnitList = data.filter(unit => unit.type === 'VEST_UNIT')
      const modList = data.filter(unit => unit.type === 'MOD')
      state.units = vestUnitList
      state.mod = modList
    },
    setCurrentUnitName (state, name) {
      state.currentUnitName = name
    },
    setCurrentPodName (state, name) {
      state.currentPodName = name
    },
    setCurrentModName (state, name) {
      state.currentModName = name
    },
    setCurrentPod (state, currentPod) {
      state.currentPod = currentPod
    },
    setCurrentMod (state, currentMod) {
      state.currentMod = currentMod
    },
    setSessionStatus (state, { id, session }) {
      let currentItm = state.units.find(itm => itm.id === id)
      state.mod = state.mod.map(element => {
        element.childContainers.forEach(pod => {
          if (pod.id === id) {
            const obj = {}
            obj.status = session.status
            stateMerge(pod, obj)
            currentItm = pod
          }
        })
        return element
      })
      if (currentItm) {
        stateMerge(currentItm, { session })
      } else {
        const parsedUnitInfo = session.initiator.split(':')
        const type = parsedUnitInfo[0]
        const containerId = parsedUnitInfo[2]
        const deviceId = parsedUnitInfo[3]

        const unit = createUnitStructure({
          id: containerId,
          location: '',
          name: '',
          unitId: (type === 'CAM_UNIT') ? deviceId : '',
          telemetry: null,
          status: '',
          session: session,
          containerId: containerId,
          mobileId: (type === 'CAM_MOBILE') ? deviceId : '',
          smartHands: true,
          fullInfo: false
        })

        state.units.push(unit)
      }
    },
    setUnitTelemetry (state, payload) {
      if (state.units.length) {
        const currentItm = state.units.find(itm => itm.id === payload.id)
        if (currentItm && currentItm.devices) {
          const currentDevice = currentItm.devices.find(device => device.type === 'CAM_UNIT')
          if (currentDevice) {
            if (currentItm.fullInfo !== undefined && !currentItm.fullInfo) {
              stateMerge(currentItm, {
                name: payload.telemetry.telemetry.smartHands.unit.name,
                meta: {
                  location: payload.telemetry.telemetry.smartHands.unit.location
                },
                fullInfo: true,
                devices: [
                  {
                    id: payload.telemetry.telemetry.smartHands.unit.unitId,
                    type: 'CAM_UNIT',
                    containerId: payload.telemetry.telemetry.smartHands.unit.containerId
                  },
                  {
                    id: payload.telemetry.telemetry.smartHands.unit.mobileId,
                    type: 'CAM_MOBILE',
                    containerId: payload.telemetry.telemetry.smartHands.unit.containerId
                  }
                ]
              })
            }
            stateMerge(currentDevice, payload.telemetry)
          }
        } else if (payload.telemetry.telemetry?.smartHands?.active) {
          const unit = createUnitStructure({
            id: payload.id,
            location: payload.telemetry.telemetry.smartHands.unit.location,
            name: payload.telemetry.telemetry.smartHands.unit.name,
            unitId: payload.telemetry.telemetry.smartHands.unit.unitId,
            telemetry: payload.telemetry.telemetry,
            status: payload.telemetry.status,
            session: null,
            containerId: payload.telemetry.telemetry.smartHands.unit.containerId,
            mobileId: payload.telemetry.telemetry.smartHands.unit.mobileId,
            smartHands: true,
            fullInfo: true
          })

          if (unit.status !== 'OFFLINE') {
            state.units.push(unit)
          }
        }
      } else if (payload.telemetry.telemetry?.smartHands.active) {
        const unit = createUnitStructure({
          id: payload.id,
          location: payload.telemetry.telemetry.smartHands.unit.location,
          name: payload.telemetry.telemetry.smartHands.unit.name,
          unitId: payload.telemetry.telemetry.smartHands.unit.unitId,
          telemetry: payload.telemetry.telemetry,
          status: payload.telemetry.status,
          session: null,
          containerId: payload.telemetry.telemetry.smartHands.unit.containerId,
          mobileId: payload.telemetry.telemetry.smartHands.unit.mobileId,
          smartHands: true,
          fullInfo: true
        })

        if (unit.status !== 'OFFLINE') {
          state.units.push(unit)
        }
      }

      if (payload.telemetry?.telemetry?.ptz) {
        const ptz = payload.telemetry.telemetry.ptz

        if (ptz.pan === state.defaultPtzParams.pan &&
          ptz.tilt === state.defaultPtzParams.tilt &&
          ptz.zoom === state.defaultPtzParams.zoom &&
          ptz.autofocus === state.defaultPtzParams.autofocus) {
          state.isNotDefaultPtzParams = false
        } else {
          state.isNotDefaultPtzParams = true
        }
      }
    },
    setUnitTelemetryMobile (state, payload) {
      const currentItm = state.units.find(itm => itm.id === payload.id)
      if (currentItm && currentItm.devices) {
        const currentDevice = currentItm.devices.find(device => device.type === 'CAM_MOBILE')
        stateMerge(currentDevice, payload.telemetry)
      } else if (payload.telemetry.telemetry?.smartHands?.active) {
        const unit = createUnitStructure({
          id: payload.id,
          location: payload.telemetry.telemetry.smartHands.unit.location,
          name: payload.telemetry.telemetry.smartHands.unit.name,
          unitId: payload.telemetry.telemetry.smartHands.unit.unitId,
          telemetry: payload.telemetry.telemetry,
          status: 'OFFLINE',
          session: null,
          containerId: payload.telemetry.telemetry.smartHands.unit.containerId,
          mobileId: payload.telemetry.telemetry.smartHands.unit.mobileId,
          smartHands: true,
          fullInfo: true
        })

        state.units.push(unit)
      }
    },
    setModxCamTelemetry (state, payload) {
      state.mod = state.mod.map(element => {
        element.childContainers.forEach(pod => {
          if (pod.id === payload.id) {
            pod.devices.forEach(device => {
              if (device.id === payload.deviceId) {
                stateMerge(device, payload.telemetry)
              }
            })
          }
        })
        return element
      })
    },
    setModxNucTelemetry (state, payload) {
      state.mod = state.mod.map(element => {
        element.childContainers.forEach(pod => {
          if (pod.id === payload.id) {
            pod.devices.forEach(device => {
              if (device.id === payload.deviceId) {
                stateMerge(device, payload.telemetry)
              }
            })
          }
        })
        return element
      })
    },
    setPodTelemetry (state, payload) {
      const pods = state.mod.map(element => {
        element.childContainers.forEach(pod => {
          if (pod.id === payload.id) {
            const obj = {}
            obj.statusDevicesOfPod = payload.telemetry.status
            stateMerge(pod, obj)
          }
        })
        return element
      })
      state.mod = pods
    },
    updateCameraStatus (state, props) {
      const { deviceId, status } = props
      const device = state.currentPodDevices.find(device => device.id === deviceId)
      if (device) {
        device.status = status
        state.currentPodDevices = [...state.currentPodDevices]
      }
    },
    setCurrentPodDevices (state, devices) {
      state.currentPodDevices = [...devices]
    },
    handlePodCameraOn (state, isOnMap) {
      state.currentPodDevices = state.currentPodDevices.map(device => {
        device.isOn = Boolean(isOnMap[device.id])
        return device
      })
    },
    deleteCurrentSession (state, id) {
      const currentUnit = state.units.find(unit => unit.id === id)
      if (currentUnit && currentUnit.session) {
        stateMerge(currentUnit.session, {
          status: null,
          id: null,
          initiator: null,
          host: null,
          destination: null,
          startTime: null
        })
      }
    },
    setEditUnitPage (state, payload) {
      state.isEditUnitPage = payload
    },
    setCurrentEditUnit (state, unit) {
      state.currentEditUnit = unit
    }
  },
  actions: {
    getUnits ({ commit, rootState, dispatch }, organization) {
      const inventoryUrl = `${window.VEST_CONFIG.deviceInventory}${organization}/tree`
      Vue.prototype.$axios
        .get(inventoryUrl)
        .then(response => {
          commit('setUnits', response.data)
          dispatch('connectToSocket')
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
          if (error.toString() === 'Error: Request failed with status code 404') {
            showErrorToast({ id: 2, message: ERRORS[2] })
          } else {
            showErrorToast({ id: 11, message: ERRORS[11] })
          }
          return Promise.reject(error)
        })
    },
    setCurrentUnitName ({ commit }, name) {
      commit('setCurrentUnitName', name)
    },
    setCurrentModName ({ commit }, name) {
      commit('setCurrentModName', name)
    },
    setCurrentPodName ({ commit }, name) {
      commit('setCurrentPodName', name)
    },
    setSessionStatus ({ commit }, { id, session }) {
      commit('setSessionStatus', { id, session })
    },
    setCurrentMod ({ commit }, currentMod) {
      commit('setCurrentMod', currentMod)
    },
    setCurrentPod ({ commit }, currentPod) {
      if (currentPod) {
        commit('setCurrentPodDevices', currentPod.devices.filter(el => el.type === 'IP_CAMERA'))
      }
      commit('setCurrentPod', currentPod)
    },
    createUnit ({ dispatch, rootState }, { container, arrDevices }) {
      const org = rootState.Organizations.currentOrganization.guid
      const containerUrl = `${window.VEST_CONFIG.deviceInventory}${org}/container`
      const deviceUrl = `${window.VEST_CONFIG.deviceInventory}${org}/device`

      Vue.prototype.$axios
        .post(containerUrl, container)
        .then(() => {
          return Vue.prototype.$axios.post(deviceUrl, arrDevices)
        })
        .then(() => {
          dispatch('disconnectFromSocket')
          dispatch('getUnits', org)
        })
        .catch(error => {
          console.warn(`Unit saving error: ${error}`)
        })
    },
    updateUnitContainer ({ dispatch, rootState }, { containerId, container }) {
      const org = rootState.Organizations.currentOrganization.guid
      const updateContainerUrl = `${window.VEST_CONFIG.deviceInventory}${org}/container/${containerId}`
      Vue.prototype.$axios
        .patch(updateContainerUrl, container[0])
        .then(() => {
          dispatch('disconnectFromSocket')
          dispatch('getUnits', org)
        })
        .catch(error => {
          console.warn(`Unit saving error: ${error}`)
        })
    },
    updateDeviceInUnit ({ dispatch, rootState }, { deviceID, device }) {
      const org = rootState.Organizations.currentOrganization.guid
      const updateDeviceUrl = `${window.VEST_CONFIG.deviceInventory}${org}/device/${deviceID}`
      Vue.prototype.$axios
        .patch(updateDeviceUrl, device)
        .catch(error => {
          console.warn(`Unit saving error: ${error}`)
        })
    },
    deleteUnitFromDIM ({ dispatch, rootState }, unit) {
      const deleteUnitUrl = `${window.VEST_CONFIG.deviceInventory}${rootState.Organizations.currentOrganization.guid}/container/${unit.id}`
      Vue.prototype.$axios
        .delete(deleteUnitUrl)
        .then(() => {
          dispatch('disconnectFromSocket')
          dispatch('getUnits', rootState.Organizations.currentOrganization.guid)
        }).catch(err => {
          console.log(err)
        })
    },
    deleteDeviceFromUnit ({ dispatch, rootState }, deviceId) {
      const deleteDeviceUrl = `${window.VEST_CONFIG.deviceInventory}${rootState.Organizations.currentOrganization.guid}/device/${deviceId}`
      Vue.prototype.$axios
        .delete(deleteDeviceUrl)
        .catch(err => {
          console.log(err)
        })
    },
    createDeviceInUnit ({ dispatch, rootState }, arrDevices) {
      const createDeviceUrl = `${window.VEST_CONFIG.deviceInventory}${rootState.Organizations.currentOrganization.guid}/device`
      Vue.prototype.$axios
        .post(createDeviceUrl, arrDevices)
        .catch(err => {
          console.log(err)
        })
    },
    setEditUnitPage ({ commit }, payload) {
      commit('setEditUnitPage', payload)
    },
    setCurrentEditUnit ({ commit }, unit) {
      commit('setCurrentEditUnit', unit)
    }
  }
}
