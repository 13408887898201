import { v4 as uuidv4 } from 'uuid'

const createUnitFields = () => {
  const fields = [
    {
      id: uuidv4(),
      field: 'name',
      value: null,
      label: 'name',
      placeholder: 'Name',
      isRequired: true,
      pattern: /\S+/,
      isDisabled: false,
      type: 'text',
      styleTextBold: true,
      isDisabledClass: false,
      valid: ''
    },
    {
      id: uuidv4(),
      field: 'location',
      value: null,
      label: 'location',
      placeholder: 'Location',
      isRequired: true,
      pattern: /\S+/,
      isDisabled: false,
      type: 'text',
      styleTextBold: false,
      isDisabledClass: false,
      valid: ''
    },
    {
      id: uuidv4(),
      field: 'organization',
      value: null,
      label: 'organization',
      placeholder: null,
      isRequired: true,
      pattern: /\S+/,
      isDisabled: true,
      type: 'text',
      styleTextBold: false,
      isDisabledClass: false,
      valid: ''
    },
    {
      id: uuidv4(),
      field: 'description',
      value: null,
      label: 'description',
      placeholder: 'Description',
      isRequired: false,
      pattern: null,
      isDisabled: false,
      type: 'text',
      styleTextBold: false,
      isDisabledClass: false,
      textarea: true,
      valid: ''
    }
  ]
  return fields
}

const createSmartCartFields = () => {
  const fields = [
    {
      id: uuidv4(),
      field: 'id',
      value: null,
      label: 'ID',
      placeholder: 'Paste “ID” from Mender',
      isRequired: true,
      pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
      isDisabled: false,
      type: 'text',
      styleTextBold: false,
      isDisabledClass: false,
      isIcon: true,
      bgField: true,
      valid: ''
    },
    {
      id: uuidv4(),
      field: 'name',
      value: null,
      label: 'Name:',
      placeholder: null,
      isRequired: true,
      pattern: /\S+/,
      isDisabled: true,
      type: 'text',
      styleTextBold: true,
      isDisabledClass: true,
      valid: ''
    },
    {
      id: uuidv4(),
      field: 'description',
      value: null,
      label: 'description',
      placeholder: 'Description',
      isRequired: false,
      pattern: null,
      isDisabled: false,
      type: 'text',
      styleTextBold: false,
      isDisabledClass: false,
      textarea: true,
      valid: ''
    }
  ]
  return fields
}

const createMobileFields = () => {
  const fields = [
    {
      id: uuidv4(),
      field: 'id',
      value: uuidv4(),
      label: 'ID:',
      placeholder: null,
      isRequired: true,
      pattern: /^[A-Za-z0-9-]+$/,
      isDisabled: true,
      type: 'text',
      styleTextBold: true,
      isDisabledClass: true,
      isIcon: true,
      valid: ''
    },
    {
      id: uuidv4(),
      field: 'name',
      value: null,
      label: 'Name:',
      placeholder: null,
      isRequired: true,
      pattern: /\S+/,
      isDisabled: true,
      type: 'text',
      styleTextBold: true,
      isDisabledClass: true,
      valid: ''
    },
    {
      id: uuidv4(),
      field: 'description',
      value: null,
      label: 'description',
      placeholder: 'Description',
      isRequired: false,
      pattern: null,
      isDisabled: false,
      type: 'text',
      styleTextBold: false,
      isDisabledClass: false,
      textarea: true,
      valid: ''
    }
  ]
  return fields
}

export { createUnitFields, createSmartCartFields, createMobileFields }
