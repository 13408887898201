import { v4 as uuidv4 } from 'uuid'
let sessionId = null

export default {
  generateUUID () {
    sessionId = uuidv4()
  },
  getUUID () {
    return sessionId
  }
}
