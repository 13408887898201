export function secToHm (seconds) {
  const hours = Math.floor(seconds / 60 / 60)
  const minutes = Math.floor(seconds / 60) - (hours * 60)
  const secNum = parseInt(seconds, 10)
  const sec = secNum % 60
  const hoursStr = !hours ? '' : `${hours} h `
  const minutesStr = !minutes ? '' : `${minutes} min `
  const secStr = !sec ? '' : `${sec} sec`
  return `${hoursStr}${minutesStr}${secStr}`
}
function formatAMPM (date) {
  const dateObj = new Date(date)
  let hours = dateObj.getHours()
  let minutes = dateObj.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  return `${hours}:${minutes} ${ampm}`
}
export function formatDate (date) {
  const dateObj = new Date(date)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

export function formatDateTime (date) {
  return `${formatDate(date)} ${formatAMPM(date)}`
}
