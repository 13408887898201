const createContainer = ({ id, type, name, description, unifyingContainer, location }) => {
  const arr = []
  const container = {}
  container.id = id
  container.type = type
  container.name = name
  container.description = description
  container.unifyingContainer = unifyingContainer
  container.meta = {
    location: location
  }

  arr.push(container)

  return arr
}

const createDevice = ({ id, containerId, type, name, description, location }) => {
  const device = {}
  device.id = id
  device.containerId = containerId
  device.type = type
  device.name = name
  device.description = description
  device.meta = {
    location: location
  }

  return device
}

export { createContainer, createDevice }
