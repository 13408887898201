<template>
  <button class="btn" :class="className" @click.stop="clickAction" :disabled="disabled">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    disabled: Boolean,
    className: String,
    action: Function // needed to control actions in Ipad safari
  },
  methods: {
    clickAction () {
      if (this.action) this.action()
      else this.$emit('clickAction')
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn {
    cursor: pointer;
    display: inline-block;
    min-width: 160px;
    margin: 0 auto;
    padding: 11px 16px;
    border: 2px solid rgba(17, 17, 17, 0.2);
    font-size: 14px;
    border-radius: 0;
    font-weight: 600;
    background: transparent;
    outline: 0;
    text-transform: uppercase;
    text-align: center;
    color: #D31920;
    letter-spacing: 1px;
    transition: 0.2s;
    &--full-width {
      display: block;
      width: 100%;
    }
    &--small {
      padding: 10px 16px;
      min-width: 96px;
      font-size: 12px;
    }
    &:active {
      border-color: #C51A21;
      background: #C51A21;
      color: #fff;
    }
    &:hover {
      &:not([disabled]) {
        border-color: #D31920;
      }
    }
    &:disabled {
      color: rgba(17, 17, 17, 0.4);
    }
    &.btn--icon {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        margin: 0 10px 0 0;
      }
    }
    &.btn--red {
      color: #ffffff;
      background: #D31920;
      border: none;
      &:active {
        background: #C51A21;
      }
      &:hover {
        &:not([disabled]) {
          background: #DB3037;
        }
      }
      &:disabled {
        background: #F5F5F5;
        color: rgba(17, 17, 17, 0.4);
      }
    }
    &.btn--white {
      color: #D31920;
      background: white;
      border: 2px solid rgba(17, 17, 17, 0.4);
      &:active {
        background: #D31920;
        color: white;
      }
      &:hover {
        &:not([disabled]) {
          border: 2px solid rgba(17, 17, 17, 0.7);
        }
      }
      &:disabled {
        background: #F5F5F5;
        color: rgba(17, 17, 17, 0.4);
        border: 2px solid rgba(17, 17, 17, 0.4);
      }
    }
  }
</style>
