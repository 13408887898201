import axios from 'axios'
// import { v4 as uuidv4 } from 'uuid'
import { IAMClientAxiosMiddleware } from '@switch-app-dev/js-lib_oauth-auth'
import uuid from '@/helpers/uuid'

// const sessionId = uuidv4()
const switchAxios = axios.create()

function axiosInjector (app, Vue) {
  switchAxios.interceptors.request.use(
    config => {
      return IAMClientAxiosMiddleware(config, app.$auth).then(config => {
        if (!config.noCustomHeaders) {
          config.headers['X-SWITCH-SESSION-ID'] = uuid.getUUID()
        }
        return config
      })
    },
    error => {
      console.error(error)
      return Promise.reject(error)
    }
  )
  Vue.prototype.$axios = switchAxios
}

export { axiosInjector }
