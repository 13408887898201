import Vue from 'vue'
import stateMerge from 'vue-object-merge'
import SockJS from 'sockjs-client'
import { v4 as uuidv4 } from 'uuid'
import topics from '../../helpers/topics'
import router from '../../routes/index'
import { showErrorToast } from '@/helpers/toastification'
import ERRORS from '@/dictionaries/errors'

const Stomp = require('stompjs')
let socket = null
let stompClient = null

export default {
  namespaced: false,
  state: {
    publishers: [],
    topics: {},
    publishedStreamName: '',
    UID: null,
    suid: uuidv4(),
    connectedStatus: false,
    startSessionCamUnit: false,
    startSessionCamMobile: false,
    mediaInfo: {
      unit: {
        streams: {
          video: {
            name: null
          }
        }
      },
      mobile: {
        streams: {
          video: {
            name: null
          }
        }
      }
    },
    mediaInfoPod: [],
    currentSession: {
      id: null,
      destination: null
    },
    participants: [],
    hasMicrophone: false,
    wifiConnection: true
  },
  getters: {
    topics (state) {
      return state.topics
    },
    publishers (state) {
      return state.publishers
    },
    suid (state) {
      return state.suid
    },
    UID (state) {
      return state.UID
    },
    connectedStatus (state) {
      return state.connectedStatus
    },
    startSessionCamUnit (state) {
      return state.startSessionCamUnit
    },
    startSessionCamMobile (state) {
      return state.startSessionCamMobile
    },
    mediaInfo (state) {
      return state.mediaInfo
    },
    mediaInfoPod (state) {
      return state.mediaInfoPod
    },
    currentSession (state) {
      return state.currentSession
    },
    sessionParticipants (state) {
      return state.participants
    },
    publishedStreamName (state) {
      return state.publishedStreamName
    },
    amIHost (state) {
      const hostId = state.currentSession.host?.split(':')[state.currentSession.host.split(':').length - 2]
      return state.UID === hostId
    },
    hasMicrophone (state) {
      return state.hasMicrophone
    },
    wifiConnection (state) {
      return state.wifiConnection
    }
  },
  mutations: {
    setTopic (state, topic) {
      stateMerge(state.topics, topic)
    },
    setPublishers (state, publishers) {
      state.publishers = publishers
    },
    setPublishedStreamName (state, name) {
      state.publishedStreamName = name
    },
    setUID (state, id) {
      state.UID = id
    },
    setConnectedStatus (state, status) {
      state.connectedStatus = status
    },
    setUnitVideoName (state, name) {
      state.mediaInfo.unit.streams.video.name = name
    },
    setMobileVideoName (state, name) {
      state.mediaInfo.mobile.streams.video.name = name
    },
    setSubscriberUnit (state, payload) {
      stateMerge(state.mediaInfo.unit, payload)
    },
    setPodPublishers (state, payload) {
      state.mediaInfoPod = payload
    },
    removeModxCamFromPod (state, payload) {
      const removeDevice = state.mediaInfoPod.map(device => device.uuid).indexOf(payload)
      if (removeDevice >= 0) {
        state.mediaInfoPod.splice(removeDevice, 1)
      }
    },
    setSubscriberMobile (state, payload) {
      stateMerge(state.mediaInfo.mobile, payload)
    },
    setStartSessionCamUnit (state, payload) {
      state.startSessionCamUnit = payload
    },
    setStartSessionCamMobile (state, payload) {
      state.startSessionCamMobile = payload
    },
    setCurrentSession (state, payload) {
      stateMerge(state.currentSession, payload)
    },
    setSessionParticipants (state, payload) {
      state.participants = payload
    },
    setActiveParticipant (state, payload) {
      const currentItm = state.participants.find(itm => itm.suid === payload.uuid)
      if (currentItm && currentItm.talking !== payload.talking) {
        stateMerge(currentItm, payload)
      }
    },
    setMicrophoneAvailability (state, microphoneStatus) {
      state.hasMicrophone = microphoneStatus
    },
    setWifiConnection (state, payload) {
      state.wifiConnection = payload
    }
  },
  actions: {
    setUID ({ commit }, uuid) {
      commit('setUID', uuid)
    },
    createStreamName ({ commit }) {
      const unitAudio = `AUDIO_${uuidv4()}`

      console.info('published audio stream name', unitAudio)
      commit('setPublishedStreamName', unitAudio)
    },
    deleteSessionData ({ state, commit, dispatch }) {
      dispatch('unsubscribeFromTopic', state.currentSession.destination)

      commit('setCurrentSession', {
        status: null,
        id: null,
        initiator: null,
        host: null,
        destination: null,
        startTime: null
      })
      commit('setMute', false)
      commit('setVolume', true)
      commit('setPublishers', [])
      commit('setSubscriberUnit', {
        streams: {
          video: {
            name: null
          }
        }
      })
      commit('setSubscriberMobile', {
        streams: {
          video: {
            name: null
          }
        }
      })
      commit('setPodPublishers', [])
    },
    async connectToSocket ({ commit, state, dispatch, rootState }) {
      const TOKEN = await Vue.prototype.$auth.getToken().then(data => data)
      const UID = state.UID
      const ORG_ID = rootState.User.user.user_metadata.organization_id
      const USER_NAME = rootState.User.user.name
      const SOCKET_URL = window.VEST_CONFIG.sessionManager
      const TYPE = 'VEST_CLIENT'
      const TOPICS = topics(ORG_ID, UID, state.suid)
      commit('setTopic', TOPICS)

      socket = new SockJS(SOCKET_URL)
      stompClient = Stomp.over(socket, ['v10.stomp', 'v11.stomp', 'v12.stomp'])

      if (process.env.NODE_ENV === 'production') {
        stompClient.debug = () => {}
      }

      const headers = {
        type: TYPE,
        uid: UID,
        org: ORG_ID,
        name: USER_NAME,
        suid: state.suid,
        Authorization: `Bearer ${TOKEN}`
      }

      stompClient.connect(
        headers,
        frame => {
          console.log(frame)
          if (frame.command === 'CONNECTED') {
            commit('setConnectedStatus', true)
            const currentRoute = router.currentRoute.name
            if (currentRoute === 'HomePage' || currentRoute === 'SessionRoom') {
              dispatch('subscribeToTopic', TOPICS.camUnit)
              dispatch('subscribeToTopic', TOPICS.mobile)
            }
            if (currentRoute !== 'DeviceInventory' || currentRoute !== 'NewUnitPage') {
              dispatch('subscribeToTopic', TOPICS.podController)
              dispatch('subscribeToTopic', TOPICS.sessionStatus)
              dispatch('subscribeToTopic', state.topics.clientCommandQueue({ userId: state.UID, suid: state.suid, command: 'MUTE' }))
            }
          }
        },
        error => {
          commit('setConnectedStatus', false)
          if (error.toString() === `Whoops! Lost connection to ${SOCKET_URL}`) {
            showErrorToast({ id: 10, message: ERRORS[10] })
          } else if (error.headers) {
            showErrorToast({ id: 9, message: ERRORS[9] })
          } else {
            showErrorToast({ id: 11, message: ERRORS[11] })
          }
          console.warn(`WS connection error: ${error}`)
          dispatch('disconnectFromSocket')
            .then(console.log)
            .catch(console.error)
            .then(() => dispatch('connectToSocket'))
        }
      )
    },
    async subscribeToTopic ({ commit, state, dispatch, rootState }, topic) {
      const TOKEN = await Vue.prototype.$auth.getToken().then(data => data)
      stompClient.subscribe(topic, tick => {
        console.log('tick', topic, tick)
        switch (topic) {
          case state.topics.camUnit: {
            const id = tick.headers.destination.split('/')[4]
            const deviceId = tick.headers.destination.split('/')[6]
            const telemetry = JSON.parse(tick.body)
            commit('setUnitTelemetry', { id: id, telemetry: telemetry, deviceId: deviceId })
            if (!telemetry) {
              commit('setConnectedStatus', false)
            }
            break
          }
          case state.topics.sessionStatus: {
            const id = tick.headers.destination.split('/')[4]
            const session = JSON.parse(tick.body)
            commit('setSessionStatus', { id, session })
            if (session.id === state.currentSession.id) {
              commit('setCurrentSession', session)
            }
            if (session.status === 'NO_SESSION') {
              commit('deleteCurrentSession', id)
            }
            break
          }
          case state.topics.podController: {
            const id = tick.headers.destination.split('/')[4]
            const telemetryPod = JSON.parse(tick.body)
            console.log('telemetryPod', telemetryPod)
            commit('setPodTelemetry', { id: id, telemetry: telemetryPod })
            break
          }
          case state.topics.mobile: {
            const id = tick.headers.destination.split('/')[4]
            const deviceId = tick.headers.destination.split('/')[6]
            const telemetryMobile = JSON.parse(tick.body)
            console.log('telemetryMobile', telemetryMobile)
            commit('setUnitTelemetryMobile', { id: id, telemetry: telemetryMobile, deviceId: deviceId })
            break
          }
          case state.currentSession.destination: {
            const parsedBody = JSON.parse(tick.body)

            commit('setCurrentSession', {
              host: parsedBody.host,
              startTime: parsedBody.startTime
            })

            if (parsedBody.publishers) {
              const arrayOfPublishers = Object.keys(parsedBody.publishers)

              const devicesClientItems = arrayOfPublishers.filter((item) => {
                const parsedItem = item.split(':')[0]
                if ((parsedItem === 'VEST_CLIENT' || parsedItem === 'AUDIO_STREAM_DEVICE') &&
                    parsedBody.publishers[item] &&
                    Object.prototype.hasOwnProperty.call(parsedBody.publishers[item], 'streams') &&
                    // Object.prototype.hasOwnProperty.call(parsedBody.publishers[item].streams, 'audio') &&
                    state.publishedStreamName !== parsedBody.publishers[item].streams.audio?.name
                ) {
                  return true
                }
              })
              const publishers = devicesClientItems
                .map(item => {
                  const uuid = item.split(':')[item.split(':').length - 1]
                  parsedBody.publishers[item].uuid = uuid
                  return parsedBody.publishers[item]
                })

              commit('setPublishers', publishers)

              const podCameras = arrayOfPublishers.filter((item) => {
                const parsedItem = item.split(':')[0]
                if (parsedItem === 'IP_CAMERA' && parsedBody.publishers[item].streams) {
                  return true
                }
              }).map(item => {
                const uuid = item.split(':')[item.split(':').length - 1]
                parsedBody.publishers[item].uuid = uuid
                return parsedBody.publishers[item]
              })
              commit('setPodPublishers', podCameras)

              const camUnit = arrayOfPublishers.find((item) => {
                const parsedItem = item.split(':')[0]
                if (parsedItem === 'CAM_UNIT') {
                  const uuid = item.split(':')[item.split(':').length - 1]
                  parsedBody.publishers[item].uuid = uuid
                  return item
                }
              })
              if (camUnit) {
                commit('setSubscriberUnit', parsedBody.publishers[camUnit])
              }
              if (parsedBody.publishers[camUnit] && !Object.prototype.hasOwnProperty.call(parsedBody.publishers[camUnit], 'streams')) {
                commit('setUnitVideoName', null)
              }

              const camMobile = arrayOfPublishers.find((item) => {
                const parsedItem = item.split(':')[0]
                if (parsedItem === 'CAM_MOBILE') {
                  const uuid = item.split(':')[item.split(':').length - 1]
                  parsedBody.publishers[item].uuid = uuid
                  return item
                }
              })
              if (camMobile) {
                commit('setSubscriberMobile', parsedBody.publishers[camMobile])
              }
              if (parsedBody.publishers[camMobile] && !Object.prototype.hasOwnProperty.call(parsedBody.publishers[camMobile], 'streams')) {
                commit('setMobileVideoName', null)
              }

              const participantItems = arrayOfPublishers
                .filter(item => parsedBody.publishers[item].publisherName && item.split(':')[0] !== 'IP_CAMERA')
                .map((item) => {
                  const parsedItems = item.split(':')
                  const itemType = parsedItems[0]
                  const itemUUID = parsedItems[2]
                  const suid = parsedItems[3]
                  // const itemUUID = parsedBody.publishers[item].uuid ? parsedBody.publishers[item].uuid : state.UID // @TODO checkit
                  const publisherName = parsedBody.publishers[item].publisherName
                  const host = parsedBody.host?.split(':')[parsedBody.host.split(':').length - 1]
                  let muted

                  if (itemType === 'CAM_UNIT' || itemType === 'CAM_MOBILE') {
                    muted = parsedBody.publishers[item].streams?.video?.muted
                  } else {
                    muted = parsedBody.publishers[item].streams?.audio?.muted
                  }

                  const participantItem = {
                    type: itemType,
                    name: publisherName,
                    uuid: itemUUID,
                    suid: suid,
                    talking: false,
                    host: host === itemUUID,
                    muted: muted
                  }
                  return participantItem
                })

              commit('setSessionParticipants', participantItems)
            }
            break
          }
          case state.topics.clientCommandQueue({ userId: state.UID, suid: state.suid, command: 'MUTE' }): {
            dispatch('changeMute')
            break
          }
          case state.topics.getForDeviceStatus({ unitId: rootState.Units.currentPod.id }): {
            const type = tick.headers.destination.split('/')[5]
            if (type === 'IP_CAMERA') {
              const id = tick.headers.destination.split('/')[4]
              const deviceId = tick.headers.destination.split('/')[6]
              const telemetryModxCam = JSON.parse(tick.body)
              console.log('telemetryModxCam', telemetryModxCam)
              commit('updateCameraStatus', { deviceId, status: telemetryModxCam.status })
              commit('setModxCamTelemetry', { id: id, telemetry: telemetryModxCam, deviceId: deviceId })
            }
            if (type === 'AUDIO_STREAM_DEVICE') {
              const id = tick.headers.destination.split('/')[4]
              const deviceId = tick.headers.destination.split('/')[6]
              const telemetryModxNuc = JSON.parse(tick.body)
              console.log('telemetryModxNuc', telemetryModxNuc)
              commit('setModxNucTelemetry', { id: id, telemetry: telemetryModxNuc, deviceId: deviceId })
            }
            break
          }
        }
      },
      {
        Authorization: `Bearer ${TOKEN}`,
        id: topic
      })
    },
    async subscribe ({ commit, state, rootState }, topic) {
      const TOKEN = await Vue.prototype.$auth.getToken().then(data => data)
      return new Promise((resolve, reject) => {
        stompClient.subscribe(topic, tick => {
          console.info('subscribe tick', topic, tick)
          resolve({ tick, topic })
        },
        {
          Authorization: `Bearer ${TOKEN}`,
          id: topic
        })
      })
    },
    disconnectFromSocket () {
      if (stompClient.ws.readyState !== 1) return Promise.reject(new Error('INFO: Trying close unopened Webscoket'))
      return new Promise((resolve, reject) => {
        stompClient.disconnect((err) => {
          if (err) reject(err)
          resolve('Success disconnect')
        })
      })
    },
    unsubscribeFromTopic ({ state }, topic) {
      stompClient.unsubscribe(topic)
    },
    async sendMessage ({ commit, state }, { topic, message }) {
      const TOKEN = await Vue.prototype.$auth.getToken().then(data => data)
      const FORMATTED_MESSEGE = JSON.stringify(message)
      stompClient.send(
        topic,
        { Authorization: `Bearer ${TOKEN}` },
        FORMATTED_MESSEGE
      )
    },
    sendMute ({ dispatch, state, getters }, { userId, suid, mute, recipient, currentContainerId }) {
      let topic

      if (recipient === 'CAM_UNIT') {
        topic = state.topics.unitCommandQueue({
          currentContainerId: getters.currentCamUnit.containerId,
          currentUnitId: suid,
          command: 'MUTE'
        })
      } else if (recipient === 'VEST_CLIENT') {
        topic = state.topics.clientCommandQueue({ userId, suid: suid, command: 'MUTE' })
      } else if (recipient === 'CAM_MOBILE') {
        topic = state.topics.mobileCommandQueue({ currentContainerId: getters.currentCamMobile.containerId, currentSubUnitId: userId, command: 'MUTE' })
      }

      dispatch('sendMessage', {
        topic: topic,
        message: {
          sessionId: state.currentSession.id,
          mute: mute
        }
      })
    },
    exposeSessionDetails ({ rootState, state, commit, dispatch }, { noMicrophone, muted }) {
      let streams = {}
      if (!noMicrophone) {
        commit('setMicrophoneAvailability', true)
        streams = {
          audio: {
            name: state.publishedStreamName,
            muted
          }
        }
      } else {
        commit('setMicrophoneAvailability', false)
      }

      dispatch('sendMessage', {
        topic: state.topics.expose,
        message: {
          sessionId: state.currentSession.id,
          publisher: {
            publisherName: rootState.User.user.name,
            url: window.VEST_CONFIG.kurento,
            streams
          }
        }
      })
    },
    setSubscriberUnit ({ commit }, payload) {
      commit('setSubscriberUnit', payload)
    },
    setSubscriberMobile ({ commit }, payload) {
      commit('setSubscriberMobile', payload)
    },
    setCurrentSession ({ commit }, payload) {
      commit('setCurrentSession', payload)
    },
    removeModxCamFromPod ({ commit }, payload) {
      commit('removeModxCamFromPod', payload)
    },
    setStartSessionCamUnit ({ commit }, payload) {
      commit('setStartSessionCamUnit', payload)
    },
    setStartSessionCamMobile ({ commit }, payload) {
      commit('setStartSessionCamMobile', payload)
    },
    sendStartSession ({ dispatch, state, rootState }, { currentUnitId, currentCamUnitId, sessionType }) {
      dispatch('sendMessage', {
        topic: state.topics.startSession,
        message: {
          type: sessionType,
          invite: [`CAM_UNIT:${rootState.User.user.user_metadata.organization_id}:${currentUnitId}:${currentCamUnitId}`]
        }
      })
    },
    async subscribeJoinSession ({ dispatch, state, rootState }) {
      const subscription = await this.dispatch('subscribe', state.topics.joinSession)
      subscription.tick = JSON.parse(subscription.tick.body)
      console.info('subscribeJoinSession', subscription)
      dispatch('setCurrentSession', subscription.tick.session)
      return subscription
    },
    sendCloseSession ({ dispatch, state, rootState, commit }) {
      dispatch('sendMessage', {
        topic: state.topics.closeSession,
        message: {
          id: state.currentSession.id
        }
      })
    },
    sendLeaveSession ({ dispatch, state, rootState }) {
      dispatch('sendMessage', {
        topic: state.topics.leaveSession,
        message: {
          id: state.currentSession.id
        }
      })
    },
    sendPTZCommand (
      {
        dispatch,
        state,
        rootState
      }, {
        type,
        currentContainerId,
        currentUnitId,
        destination,
        command,
        pan,
        tilt,
        direction,
        zoom,
        nightmode,
        focus,
        autofocus,
        clickX,
        clickY,
        clientWidth,
        clientHeight,
        areazoom
      }) {
      let topic

      if (type === 'CAM_UNIT') {
        topic = state.topics.unitCommandQueue({ currentContainerId, currentUnitId, command: 'PTZ' })
      } else if (type === 'IP_CAMERA') {
        topic = state.topics.modxCamCommandQueue({ currentContainerId, currentUnitId, command: 'PTZ' })
      }
      dispatch('sendMessage', {
        topic: topic,
        message: {
          destination,
          command,
          pan,
          tilt,
          direction,
          zoom,
          nightmode,
          focus,
          autofocus,
          clickX,
          clickY,
          clientWidth,
          clientHeight,
          areazoom
        }
      })
    },
    sendMobilePTZCommand ({ dispatch, state, rootState }, { currentContainerId, currentSubUnitId, destination, command, pitch, yaw }) {
      dispatch('sendMessage', {
        topic: state.topics.mobileCommandQueue({ currentContainerId, currentSubUnitId, command: 'PTZ' }),
        message: {
          destination,
          command,
          pitch,
          yaw
        }
      })
    },
    clearPulishersList ({ commit }) {
      commit('setSessionParticipants', [])
    },
    setActiveParticipant ({ commit }, payload) {
      commit('setActiveParticipant', payload)
    },
    setWifiConnection ({ commit }, payload) {
      commit('setWifiConnection', payload)
    }
  }
}
