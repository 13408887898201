<template>
    <Popup v-if="!acceptedRule">
      <template slot="header">
        <h4 class="modal-rules__header">Terms of use</h4>
      </template>
      <template slot="body" >
        <div class="rules">
          <span class="red">IMPORTANT</span>: By using VEST, I, for myself, and on behalf of my employer and others attending the session with me, hereby expressly acknowledge, covenant and agree as follows:
          <ol>
            <li><b>I CONSENT, AND WILL OBTAIN ALL APPLICABLE THIRD PARTY CONSENT, TO AUDIO AND VIDEO RECORDING BY SWITCH FOR SECURITY, TRAINING OR COMPLIANCE PURPOSES AND WAIVE ANY FURTHER NOTICE OF SUCH MONITORING OR RECORDING. </b></li>
            <li>I will not capture or record any session via any means (e.g., screen shot, photo, audio or video recording).</li>
            <li>During any session and in related communications with Switch, I will be exposed to or provided with confidential and proprietary information of Switch and, potentially, certain of its customers (collectively, “Confidential Information”). Confidential Information includes, without limitation, VEST sessions and recordings, data center designs, technical information, customer identities and all other business information. I will not disclose any Confidential Information to any third party or use it for any third party’s benefit. These confidentiality obligations are intended to provide the greatest protections possible for the Confidential Information.</li>
            <li>I will comply with all applicable laws, rules and regulations, including Switch’s safety and security policies and procedures (e.g., Switch’s current Acceptable Use Policy, available at <a href="https://www.switch.com/aup">www.switch.com/aup</a>).</li>
            <li>I acknowledge that agreement to these terms is a material inducement for Switch to allow access to or use of VEST.</li>
          </ol>
          </div>
      </template>
      <template slot="buttons">
          <div class="modal-rules__btn">
            <Btn class="btn btn--red" @clickAction="acceptRule">Accept</Btn>
          </div>
      </template>
    </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Popup from '@/common/popup/Popup'
import Btn from '@/common/btn/Btn'

export default {
  name: 'Modal',
  components: {
    Popup,
    Btn
  },
  computed: {
    ...mapGetters([
      'acceptedRule'
    ])
  },
  methods: {
    ...mapActions([
      'setAcceptedRule'
    ]),
    acceptRule () {
      this.setAcceptedRule(true)
      const date = new Date().getDate()
      localStorage.setItem('acceptedRule', date)
    }
  },
  created () {
    const acceptedRule = localStorage.getItem('acceptedRule')
    const date = new Date().getDate().toString()
    if (acceptedRule === date) {
      this.setAcceptedRule(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-rules {
    &__header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 32px;
    }
    &__btn {
      margin-bottom: -25px;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      .btn {
        width: 240px;
      }
    }
}
.rules {
  max-width: 720px;
  height: 372px;
  margin-bottom: -40px;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  b {
    font-weight: bold;
  }
  ol {
    list-style-type: decimal;
    padding: 10px 20px;
    li {
      padding-left: 30px;
      margin-bottom: 16px;
    }
  }
  .red {
    color: #d31920;
  }
}
</style>
