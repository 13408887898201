<template>
  <div class="home-page">
    <template v-if="loggedIn">
      <HeaderView headerType="vest"/>
      <RecordingsTable />
    </template>
    <Loading v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import RecordingsTable from '@/components/recordings-table/RecordingsTable'
import HeaderView from '@/components/header-view/HeaderView'
import Loading from '@/common/loading/Loading'

export default {
  name: 'HomePage',
  components: {
    Loading,
    RecordingsTable,
    HeaderView
  },
  created () {
    this.getRecordings()
  },
  computed: {
    ...mapGetters([
      'loggedIn'
      // 'recordingsLength'
    ]),
    ...mapState({
      loading: state => state.Recordings.loading
    })
  },
  destroyed () {
    this.$store.commit('clearRecordingList')
    this.$store.commit('clearAllFilters')
  },
  methods: {
    ...mapActions(['getRecordings', 'loadMore'])
  }
}
</script>

<style lang="scss" scoped>
  .notification {
    font-size: 26px;
    color: #b71c1c;
  }
  .home-page {
    height: 100%;
  }
  .load__more {
    display: flex;
    padding: 48px 0;
    justify-content: center;
  }
</style>
