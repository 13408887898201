<template>
  <div class="organization">
    <span class="organization__ttl">organization</span>
    <multiselect
      ref="select"
      v-model="selected"
      :selected="selected"
      :options="organizations"
      :disabled="!isSwitchAdmin"
      track-by="guid"
      label="name"
      placeholder="Organization"
      :max-height="430"
      :searchable="true"
      :show-labels="false"
      :close-on-select="true"
      :allow-empty="false"
      :clear-on-select="true"
      @select="onChange"
      @search-change="searchOrganization"
    >
      <template slot="beforeList">
        <div :class="{'organization__recent': !isSearching}"/>
      </template>
      <template slot="noResult">{{searchingPlaceHolder}}</template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Organizations',
  components: {
    Multiselect
  },
  data () {
    return {
      selected: this.selectedOption
    }
  },
  props: ['organizations', 'selectedOption'],
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'isSearching',
      'searchingPlaceHolder',
      'isSwitchAdmin'
    ])
  },
  methods: {
    ...mapActions([
      'setselectedOrganization',
      'searchOrganization',
      'changeOrganization'
    ]),
    onChange (value) {
      this.setselectedOrganization(value.guid)
      this.selected = this.selectedOrganization
      this.changeOrganization(value.guid)
    }
  },
  watch: {
    selectedOption: {
      handler (val) {
        if (val) {
          this.selected = this.selectedOption
        }
      },
      immediate: true
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.organization {
  width: 265px;
  background: #F5F5F5;
  &__ttl {
    color: rgba(17, 17, 17, 0.6);
    font-size: 12px;
    padding: 7px 0 3px 16px;
    background: #F5F5F5;
    display: block;
    line-height: 16px;
  }
  .multiselect {
    min-height: auto;
    &.multiselect--active {
      .multiselect__tags {
        background: #F5F5F5;
        color: #111111;
      }
      .multiselect__select {
        &:before {
          border-color: #111 transparent transparent;
        }
      }
    }
    &.multiselect--disabled {
      .multiselect__current,
      .multiselect__select {
        background: #F5F5F5;
      }
    }
    .multiselect__tags {
      border-radius: 0;
      padding: 0 40px 10px 16px;
      background: #F5F5F5;
      border: none;
      min-height: auto;
      color: #111111;
      transition: background-color 0.15s;
    }
    .multiselect__input,
    .multiselect__single {
      background: transparent;
      margin-bottom: 0;
      font-size: 16px;
      padding-left: 0;
      cursor: pointer;
    }
    .multiselect__input::placeholder {
      opacity: 20%;
    }
    .multiselect__option {
      padding: 10px 16px;
      color: #111;
      font-weight: normal;
      font-size: 14px;
    }
    .multiselect__option--selected {
      background: #fff;
      position: relative;
      color: #D31920;
    }
    .multiselect__option {
      span {
        white-space: break-spaces;
        line-height: 24px;
      }
      &--highlight {
      background: #ffffff;
      color: #D31920;
      }
    }
    .multiselect__content {
      position: relative;
      border-top: 2px solid rgba(17, 17, 17, 1);
      padding-top: 10px;
    }
    .multiselect__content-wrapper {
      box-shadow: 0 7px 15px 2px rgba(0, 0, 0, 0.14);
      border-radius: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none;
      border: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .multiselect__select {
      padding: 0;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        top: auto;
        border-width: 6px 6px 0;
        border-color: #111111 transparent transparent;
      }
    }
  }
  &__controls {
    width: 359px;
    position: sticky;
    bottom: 0;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(17, 17, 17, 0.1);
    background: #fff;
  }
  &__recent {
    opacity: 60%;
    padding: 10px 16px 5px;
    color: rgba(17, 17, 17, 1);
    font-size: 13px;
    &::before {
      content: "Recent";
    }
  }
  &__clear {
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 101;
    cursor: pointer;
  }
  .btn {
    min-width: auto;
    width: 150px;
    padding: 15px 20px;
  }
}
</style>
