<template>
  <div class="help-section">
    <h4>Need a help?</h4>
    <p>Check steps you need to conduct</p>
    <button @click="$emit('helpSectionClicked')">Learn more</button>
    <p>
      <span>Also check: <a href="https://mender.vest.switchdock.com/ui/#/login">Mender</a> & <a href="https://headwind.vest.switchdock.com/#/login">Headwind</a></span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelpSection'
}
</script>

<style lang=scss scoped>
  .help-section {
    width: 352px;
    height: 192px;
    padding: 24px;
    margin-top: 44px;
    background: #e7e7e7;
    color: #111111;

    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 8px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
    a {
      text-decoration-line: underline;
      color: #000;
    }
    button {
      padding: 10px 28px;
      margin: 24px 0;
      color: #fff;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      background: #111111;
      border: none;
      cursor: pointer;
    }
  }
</style>
