<template>
  <div class="head">
    <div class="head__row">
      <div class="head__col">
        <div class="head__outer">
          <div class="head__itm head__itm--logo">
            <Icon class="icon--logo">
              <svg width="68" height="32" viewBox="0 0 68 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.2976 3.24109L25.5056 0.228516L25.4844 1.39423C19.8789 1.9588 15.6523 4.46623 15.6523 7.47652C15.6523 9.23423 17.095 10.8205 19.4066 11.9451C19.4468 11.9657 19.4869 11.9862 19.5271 12.0022C19.6262 12.0502 19.7349 12.0959 19.8387 12.1417C21.1752 12.7177 22.4243 12.9988 23.2318 13.1337C20.677 12.3839 18.9439 10.9531 18.9439 9.3028C18.9439 9.29366 18.9486 9.28223 18.9486 9.2708C18.9486 9.26166 18.9439 9.25252 18.9439 9.24566C18.9439 9.11537 18.958 8.98509 18.9864 8.85709C19.3476 6.97137 21.978 5.44223 25.4135 5.02623L25.3946 5.97252L33.2976 3.24109Z" fill="black"/>
                <path d="M41.9226 9.07575C41.9226 7.31575 40.4775 5.73176 38.1659 4.6049C38.1281 4.58433 38.088 4.56604 38.0502 4.54776C37.9463 4.49976 37.8401 4.45633 37.7362 4.41061C36.3997 3.8369 35.1506 3.55347 34.3431 3.4209C36.8979 4.16833 38.6311 5.60147 38.6311 7.25175C38.6311 7.26318 38.6287 7.27461 38.6287 7.28147C38.6287 7.29061 38.6311 7.29975 38.6311 7.3089C38.6311 7.4369 38.6145 7.56718 38.5885 7.69518C38.2273 9.5809 35.5993 11.11 32.1614 11.526L32.1779 10.5798L24.2773 13.3112L32.0693 16.3238L32.0905 15.1558C37.696 14.5912 41.9226 12.086 41.9226 9.07575Z" fill="#D31920"/>
                <path d="M5.82507 31.6516C3.67402 31.6516 1.73076 31.423 0 29.7453L2.22897 27.5876C3.35762 28.6779 4.82865 28.8333 5.87702 28.8333C7.05761 28.8333 8.29252 28.4516 8.29252 27.4596C8.29252 26.8013 7.92417 26.3419 6.84983 26.2436L4.69878 26.0401C2.22897 25.8116 0.708359 24.767 0.708359 22.3304C0.708359 19.5876 3.20178 18.1133 5.98327 18.1133C8.10835 18.1133 9.89341 18.4699 11.2039 19.663L9.10713 21.7201C8.31849 21.0344 7.11192 20.831 5.93132 20.831C4.56419 20.831 3.98806 21.4413 3.98806 22.0996C3.98806 22.5841 4.19821 23.1441 5.40714 23.2447L7.55819 23.4481C10.2594 23.7019 11.6265 25.1007 11.6265 27.3339C11.6242 30.255 9.05046 31.6516 5.82507 31.6516Z" fill="black"/>
                <path d="M27.1714 31.497H24.364L21.5046 23.1907L18.6428 31.497H15.8353L11.6348 18.2627H15.2568L17.4622 26.441L20.2413 18.2627H22.7347L25.5422 26.441L27.7476 18.2627H31.3437L27.1714 31.497Z" fill="black"/>
                <path d="M32.0723 31.497V18.2627H35.4841V31.497H32.0723Z" fill="black"/>
                <path d="M42.5409 31.4985C39.7619 31.4985 38.579 29.5922 38.579 27.7134V20.6734H37.1387V18.1591H38.579V16.4722L41.9908 15.3477V18.1591H44.4038V20.6734H41.9908V27.5099C41.9908 28.2711 42.3567 28.7054 43.169 28.7054H44.4038L43.9552 31.4985H42.5409Z" fill="black"/>
                <path d="M51.1428 31.6509C48.4109 31.6509 45.0273 30.2292 45.0273 24.8692C45.0273 19.5069 48.4133 18.1104 51.1428 18.1104C53.0318 18.1104 54.4485 18.6703 55.6551 19.8909L53.3458 22.1241C52.6398 21.3881 52.0354 21.0818 51.1428 21.0818C50.3282 21.0818 49.7001 21.3629 49.2019 21.9458C48.6754 22.5812 48.4393 23.4681 48.4393 24.8669C48.4393 26.2635 48.6754 27.1755 49.2019 27.8132C49.7001 28.3961 50.3306 28.6749 51.1428 28.6749C52.0354 28.6749 52.6398 28.3709 53.3458 27.6349L55.6551 29.8475C54.4485 31.0681 53.0318 31.6509 51.1428 31.6509Z" fill="black"/>
                <path d="M64.5889 31.498V23.4203C64.5889 21.6671 63.4366 21.0843 62.3599 21.0843C61.2832 21.0843 60.1545 21.6946 60.1545 23.4203V31.498H56.7402V13.4111H60.1545V19.4797C61.073 18.5654 62.2253 18.1083 63.4366 18.1083C66.4282 18.1083 68.0008 20.1426 68.0008 22.9357V31.4957H64.5889V31.498Z" fill="black"/>
              </svg>

            </Icon>
            <span class="head__vest">vest</span>
          </div>
          <div class="head__itm itm__unit">
            <div class="itm__unit--name">{{name}}</div>
            <div class="itm__unit--location">{{location}}</div>
            <div class="itm__unit--startedAt" v-if="startedAt">{{formatDate(startedAt)}}</div>
            <div class="itm__unit--duration" v-if="duration">{{secToHm(duration)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/common/icon/Icon'
import { formatDate, secToHm } from '@/helpers/date'

export default {
  name: 'HeaderRecordingDetails',
  components: {
    Icon
  },
  props: {
    location: String,
    name: String,
    startedAt: Date,
    duration: Number
  },
  methods: {
    formatDate,
    secToHm
  }
}
</script>

<style lang="scss" scoped>
  .head {
    margin-bottom: 20px;
    padding: 15px 25px;
    &__outer {
      display: flex;
      margin: 0 -20px;
      align-items: center;
    }
    &__itm {
      padding: 0 20px;
      &.itm__unit {
        border-left: 1px solid #000;
      }
      .itm__unit {
        &--name {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        &--startedAt,
        &--duration,
        &--location {
          display: inline-block;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
        }
        &--location {
          font-size: 14px;
          padding-right: 10px;
        }
        &--startedAt,
        &--duration {
          padding: 0 10px;
          border-left: 1px solid #999;
        }
      }

      &--links {
        margin-left: 33px;
        a {
          padding: 11px 0;
          color: #000;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 1px;
        }
        :first-child {
          margin-right: 30px;
        }
        :last-child {
          border-bottom: 2px #D31920 solid;
        }
      }
    }

    &__itm--logo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__row {
      display: flex;
      margin: 0 -10px;
      align-items: center;
      justify-content: space-between;
    }
    &__vest {
      font-size: 10px;
      text-transform: uppercase;
      color: #000;
      letter-spacing: 5px;
    }
    &__col {
      padding: 0 10px;
    }
    &__user {
      text-align: right;
      font-size: 14px;
      color: #000;
    }
  }
</style>
