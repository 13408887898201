<template>
  <div class="ptz-controls">
    <div class="ptz-controls__outer">
      <div class="ptz-controls__itm">
        <ResetBtn v-if="isNotDefaultPtzParams" :currentUnit="currentUnit" :device="device" :type="type"/>
      </div>
      <div class="ptz-controls__itm">
        <ZoomBtn :currentUnit="currentUnit" :device="device" :type="type"/>
      </div>
      <div class="ptz-controls__itm">
        <FocusBtn :currentUnit="currentUnit" :device="device" :type="type"/>
      </div>
      <div class="ptz-controls__itm">
        <NightModeBtn :currentUnit="currentUnit" :device="device" :type="type"/>
      </div>
    </div>
  </div>
</template>

<script>
import NightModeBtn from './NightModeBtn'
import FocusBtn from './FocusBtn'
import ZoomBtn from './ZoomBtn'
import ResetBtn from './ResetBtn'
import { mapGetters } from 'vuex'

export default {
  components: {
    NightModeBtn,
    FocusBtn,
    ZoomBtn,
    ResetBtn
  },
  props: ['currentUnit', 'device', 'type'],
  computed: {
    ...mapGetters([
      'isNotDefaultPtzParams'
    ])
  }
}
</script>

<style lang="scss">
  .ptz-controls {
    &__outer {
      display: flex;
      align-items: center;
      margin: 0 -10px;
    }
    &__itm {
      padding: 0 10px;
    }
  }
  .control {
    min-width: 45px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 5px;
      height: 25px;
    }
    &__reset {
      border: 2px solid #fff;
      display: block;
      padding: 7px 15px;
      text-decoration: none;
      cursor: pointer;
      -webkit-text-stroke: 2px rgba(17, 17, 17, 0.1);
      .icon--reset {
        fill: #fff;
      }
    }
    &__text {
      font-weight: 600;
      font-size: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #FFFFFF;
      -webkit-text-stroke: 2px rgba(17, 17, 17, 0.1);
    }
    &__list--bordered {
      border-top: 1px solid rgba(17, 17, 17, 0.1);
    }
    &__list-itm {
      text-align: center;
    }
    &__btn {
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 10px 0;
      height: 40px;
      text-decoration: none;
      font-weight: 600;
      font-size: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #111111;
      transition: color 0.2s;
      cursor: pointer;
      &:hover {
        color: #D31920;
        .icon {
          fill: #D31920;
        }
      }
      &:active {
        background: #F5F5F5;
      }
      &.active {
        color: #ff0008;
      }
      &--inactive {
        color:rgba(1, 1, 1, 0.4);
        pointer-events: none;
        cursor: default;

        .icon {
          fill: rgba(1, 1, 1, 0.4);
        }
      }
      .icon {
        transition: fill 0.2s;
      }
    }
  }
</style>
