<template>
  <div class="field" :class="[labelIsVisible, errorClass, boldField, disabledClass, iconClass, bgFieldClass]">
    <textarea v-if="textarea" class="field__input"
      :value="value"
      :placeholder="placeholder"
      :disabled="isDisabled"
      @input="$emit('input', $event.target.value)">
    </textarea>
    <input v-else ref="field" class="field__input"
     :value="value"
     :placeholder="placeholder"
     :type="type"
     :disabled="isDisabled"
     @input="$emit('input', $event.target.value)"
     @change="$emit('change')"
     @blur="$emit('blur')"
    />
    <slot v-if="isIcon" name="fieldIcon"/>
    <label class="field__label">{{ label }}</label>
    <span v-if="!valid" class="field__error">This field is required</span>
    <span v-if="!isRequired && !isDisabled" class="field__optional">optional</span>
  </div>
</template>

<script>

export default {
  name: 'Field',
  props: {
    value: String,
    label: String,
    placeholder: String,
    isRequired: Boolean,
    pattern: RegExp,
    isDisabled: Boolean,
    type: String,
    styleTextBold: Boolean,
    isDisabledClass: Boolean,
    isIcon: Boolean,
    bgField: Boolean,
    textarea: Boolean,
    valid: Boolean
  },
  computed: {
    labelIsVisible () {
      return this.value || this.isDisabled ? 'field--label' : null
    },
    // isValid () {
    //   return this.isRequired && !this.isDisabled && this.value ? this.pattern.test(this.value) : true
    // },
    errorClass () {
      return !this.valid ? 'field--error' : null
    },
    disabledClass () {
      return this.isDisabledClass ? 'field--disabled' : null
    },
    iconClass () {
      return this.isIcon ? 'field--icon' : null
    },
    bgFieldClass () {
      return this.bgField && !this.value?.length ? 'field--bg' : null
    },
    boldField () {
      return this.styleTextBold ? 'field--bold' : null
    }
  },
  methods: {
    input (e) {
      this.$emit('input', e.target.value)
    },
    change (e) {
      this.$emit('change', e.target.value)
    },
    blur (e) {
      this.$emit('blur', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .field {
    padding: 10px 16px;
    border-bottom: 2px solid rgba(17, 17, 17, 0.2);
    margin-bottom: 25px;
    position: relative;
    transition: border-bottom-color 0.2s;
    &.field--error {
      border-bottom-color: #D31920;
    }
    &.field--icon {
      padding: 10px 45px 10px 16px;
    }
    &.field--bold {
      .field__input {
        font-family: "CenturyGothic-Bold";
        color: #111111;
      }
    }
    &.field--label {
      .field__label {
        opacity: 1;
        top: -13px;
      }
    }
    &.field--disabled {
      border-bottom-color: transparent;
      padding-left: 0;
      .field__label {
        left: 0;
        color: rgba(17, 17, 17, 1);
      }
    }
    &.field--bg {
      background: rgba(17, 17, 17, 0.05);
    }
    &__btn {
      cursor: pointer;
      padding: 0;
      border: 0;
      background: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
    textarea {
      height: 40px;
      resize: none;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
    }
    &__input {
      background: transparent;
      padding: 0;
      display: block;
      border: none;
      font-size: 16px;
      color: #111111;
      width: 100%;
      outline: none;
      &::placeholder {
        color: rgba(17, 17, 17, 0.6);
        transition: color 0.5s;
      }
      &:focus {
        outline-color: transparent;
        + .field__label {
          opacity: 1;
          top: -13px;
        }
        &::placeholder {
          color: transparent;
        }
      }
    }
    &__label {
      transition: opacity 0.2s, top 0.3s;
      opacity: 0;
      position: absolute;
      left: 16px;
      top: 0;
      font-size: 12px;
      color: rgba(17, 17, 17, 0.6);
    }
    &__error {
      position: absolute;
      top: 100%;
      left: 16px;
      width: 100%;
      font-size: 12px;
      color: #D31920;
    }
    &__optional {
      position: absolute;
      top: 100%;
      left: 16px;
      width: 100%;
      font-size: 12px;
      color: rgba(17, 17, 17, 0.6)
    }
  }
</style>
