<template>
  <div ref="overlay" class="ptz-click-overlay">
    <canvas
      id='canvas'
      ref='select'
      :class="rectAnimated ? 'blink' : ''"
      @mousedown='startSelect'
      @mousemove='drawRect'
      @mouseup='stopSelect'
    >
    </canvas>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PtzClickOverlay',
  props: ['currentUnit', 'device', 'type'],
  data () {
    return {
      ctx: null,
      selectionMode: false,
      startPosition: {
        x: null,
        y: null
      },
      rect: {
        width: null,
        height: null
      },
      rectAnimated: false
    }
  },
  computed: {
    classes: function () {
      return {
        blink: this.rectAnimated
      }
    }
  },
  methods: {
    ...mapActions([
      'sendPTZCommand'
    ]),
    startSelect (e) {
      e.preventDefault()
      const MOUSE_LEFT_BUTTON_ID = 1
      if (e.buttons !== MOUSE_LEFT_BUTTON_ID) return

      const popover = document.querySelector('.popover')
      const dropdown = document.querySelector('.dropdown-content.active')
      const participants = document.querySelector('.participants-full')
      if (popover?.ariaHidden === 'false' || dropdown || participants.style.display !== 'none') {
        return false
      }
      const rect = e.target.getBoundingClientRect()
      this.selectionMode = true
      this.startPosition.x = e.clientX - rect.left
      this.startPosition.y = e.clientY - rect.top
    },
    drawRect (e) {
      e.preventDefault()
      const MOUSE_LEFT_BUTTON_ID = 1
      if (e.buttons !== MOUSE_LEFT_BUTTON_ID) return

      if (this.selectionMode) {
        const imageWidth = this.$refs.overlay.clientWidth
        const imageHeight = this.$refs.overlay.clientHeight
        this.rect.width = e.clientX - this.startPosition.x
        this.rect.height = e.clientY - this.startPosition.y - 85

        this.ctx.globalAlpha = 1
        this.ctx.fillStyle = 'rgba(255, 255, 255, 1)'
        this.ctx.beginPath()
        this.ctx.rect(
          this.startPosition.x,
          this.startPosition.y,
          this.rect.width,
          this.rect.height
        )

        this.ctx.closePath()
        this.ctx.fillRect(0, 0, imageWidth, imageHeight)
        this.ctx.clearRect(0, 0, imageWidth, imageHeight)
        this.ctx.strokeStyle = '#fff'
        this.ctx.stroke()
      }
    },
    stopSelect (e) {
      e.preventDefault()
      const popover = document.querySelector('.popover')
      const dropdown = document.querySelector('.dropdown-content.active')
      const participants = document.querySelector('.participants-full')
      if (popover?.ariaHidden === 'false' || dropdown || participants.style.display !== 'none') {
        return false
      }
      const imageWidth = this.$refs.overlay.clientWidth
      const imageHeight = this.$refs.overlay.clientHeight
      this.ctx.fillStyle = '#fff'

      const katX = Math.abs(this.startPosition.x - e.clientX)
      const katY = Math.abs(this.startPosition.y - e.clientY)
      const radius = Math.sqrt(katX * katX + katY * katY)
      const boxCenterX = parseInt(this.startPosition.x + this.rect.width / 2, 10)
      const boxCenterY = parseInt(this.startPosition.y + this.rect.height / 2, 10)
      const zoomfactor = parseInt(50 * (Math.sqrt(imageWidth * imageWidth + imageHeight * imageHeight) / radius), 10)

      if (this.rect.width < 11) {
        this.sendPTZCommand({
          currentContainerId: this.currentUnit.id,
          currentUnitId: this.device.id,
          destination: 'unit',
          command: 'CLICK_TO_MOVE',
          clickX: this.startPosition.x,
          clickY: this.startPosition.y,
          clientWidth: imageWidth,
          clientHeight: imageHeight,
          type: this.type
        })
      } else {
        this.sendPTZCommand({
          currentContainerId: this.currentUnit.id,
          currentUnitId: this.device.id,
          destination: 'unit',
          command: 'RECT_TO_ZOOM',
          areazoom: `${boxCenterX},${boxCenterY},${zoomfactor}`,
          clientWidth: imageWidth,
          clientHeight: imageHeight,
          type: this.type
        })
      }

      this.rectAnimated = true

      setTimeout(() => {
        this.rectAnimated = false
        this.ctx.clearRect(0, 0, imageWidth, imageHeight)
      }, 1000)

      this.selectionMode = false
      this.startPosition.x = null
      this.startPosition.y = null
      this.rect.width = null
      this.rect.height = null
    }
  },
  mounted () {
    this.$refs.select.height = this.$refs.overlay.clientHeight
    this.$refs.select.width = this.$refs.overlay.clientWidth
    this.ctx = this.$refs.select.getContext('2d')
    // this.ctx.fillRect(0,0,500,500)
  }
}
</script>

<style lang="scss">
  .ptz-click-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: crosshair;

    .blink {
      animation: blink-animation .5s steps(5, start) infinite;
      -webkit-animation: blink-animation .5s steps(5, start) infinite;
    }
    @keyframes blink-animation {
      to {
        visibility: hidden;
      }
    }
    @-webkit-keyframes blink-animation {
      to {
        visibility: hidden;
      }
    }
  }
</style>
