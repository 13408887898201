import Vue from 'vue'
import VueRouter from 'vue-router'
import VTooltip from 'v-tooltip'
import App from './App.vue'
import store from './store'
import router from './routes/index'
import querystring from 'querystring'
import { IAMClientFactory } from '@switch-app-dev/js-lib_oauth-auth'
import Toast from 'vue-toastification'
import SmartTable from 'vuejs-smart-table'

export const eventBus = new Vue()

Vue.use(VueRouter)
Vue.use(VTooltip)
Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 4,
  newestOnTop: true
})
Vue.use(SmartTable)

Vue.config.productionTip = false
Vue.prototype.$querystring = querystring

// const params = new URLSearchParams(location.search)
IAMClientFactory({
  clientId: 'vest-client-ui',
  // realm: params.get('sso') || 'switch-external',
  hostnames: {
    auth: window.VEST_CONFIG.userAuthorizationUri,
    api: window.VEST_CONFIG.switchAPI
  }
}).then((iamClient) => {
  Vue.prototype.$auth = iamClient

  iamClient.appInfo = {
    auth: window.VEST_CONFIG.userAuthorizationUri,
    api: window.VEST_CONFIG.switchAPI
  }

  new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
})
