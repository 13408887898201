<template>
  <div class="text">
    Now you can close that page
  </div>
</template>

<script>
export default {
  name: 'ClosePage'
}
</script>
<style>
.text {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: "CenturyGothic-Bold";
  font-size: 20px;
  letter-spacing: 0.5px;
  color: #111111;
  margin-bottom: 3px;
}
</style>
